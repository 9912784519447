<template lang="">
	<div v-if="show && data.length>0">
		<div
			class="d-flex flex-vertical-center overflow-auto align-items-start"
			:class="{'flex-column': direction == Direction.VERTICAL}"
		>
			<div
				v-for="e in list"
				:key="e.id"
				class="d-flex flex-center"
			>
				<MatCheckbox
					:disabled="e.isDisabled"
					:value="e.id"
					v-model="e.selected"
					@change="select($event,e)"
				/>
				<span>
					
					<span v-if="title=='Contrats'">{{ $t('contract.'+e?.label) }}</span>
					<span v-if="title=='Expérience'">{{ e.label }}</span>
					<span v-if="title=='Formation'">{{ $t('education.'+e?.label) }} </span>
					<span v-else><span v-if="title!='Contrats' && title!='Expérience' && title!='Formation'">{{ e?.label }}</span></span>
					<span
						v-if="e.m"
						v-html="' (' + e.m + ')'"
					/>
					<span v-if="e.count"> ({{ e.count }})</span>


				</span>
			</div>
		</div>
	</div>
</template>
<script>
export const Direction = Object.freeze({
	HORIZONTAL: 0,
	VERTICAL: 1
})
import { Contract } from "@/utils/constants";
export default {
	data() {
		return { Direction, list: [], selected: [], show: true, Contract, isDisabled: false }
	},
	props: {
		data: {
			type: Array,
			default: () => []
		},
		direction: {
			type: Number,
			default: () => Direction.HORIZONTAL
		},
		modelValue: {
			type: Array,
			default: () => []
		},
		title: {
			type: String,
			default: () => ""
		},
		filter: {
			type: String,
			default: () => ""
		},
		icon: {
			type: String,
			default: () => ""
		},
		advanced: {
			type: Boolean,
			default: () => false
		},
	},
	emits: ["update:modelValue", "add", "remove", "hideHeader"],
	watch: {
		data: {
			handler() {
				if (this.data && !this.$utils.equals(this.list, this.data) && this.data.length > 0) {
					if (this.title == "Formation") this.list = this.data
					else this.list = this.data[0]
					if (this.title == "Contrats" || this.title == "Tags" ) this.list.forEach(el => {
						el.label = el.id
					})
					if (this.title == "Formation") this.list.forEach(el => {
						el.label = el.id.replace('.', ',')
					})
					
					this.updateSelection()
				}
			},
			immediate: true
		},
		modelValue: {
			handler() {
				if (!this.$utils.equals(this.modelValue, this.selected)) {
					this.selected = this.modelValue || []
					this.updateSelection()
				}
			},
			//	immediate: true
		},
		filter() {
			// if (!this.filter) {
			// 	this.showAll()
			// } else {
			// 	if (this.title.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0) return this.showAll()
			// if(this.data.length>0)	this.list = this.data[0].filter(d => d.label.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0)
			// 	if (!this.list.length) { this.show = false; this.$emit("hideHeader", true) }
			// }
		}
	},

	methods: {
		showAll() {
			this.list = Object.assign([], this.data[0])
			this.list.forEach(el => {
				el.label = el.id
			})
			this.$emit("hideHeader", false)
			this.show = true;
		},
		updateSelection() {
			this.list.forEach(e => e.selected = false)
			if (this.selected.length>0) {
				for (let s of this.selected) {
					let e = this.list.find(l => l.id == s);
					if (e ) e.selected = true;
				}
			}
			else {
				this.list.forEach(e => e.selected = false)
			}

			if (this.title == "Expérience") {
				this.updateExp()
			}
		},
		select(evt, e) {
			let value = evt.currentTarget.checked;
			e.selected = value
			this.selected = this.list.filter(l => l.selected).map(l => l.id);
			if (this.title == "Expérience") {
				this.updateExp()
			}
			this.$emit("update:modelValue", this.selected)
			if (this.title == "Contrats") {
				if (value) {
					this.$emit("add", { id: e.id, label: Contract[e.label] })
				} else {
					this.$emit("remove", { id: e.id, label: Contract[e.label] })
				}
			}
			else {
				if (value) {
					this.$emit("add", { id: e.id, label: e.label })
				} else {
					this.$emit("remove", { id: e.id, label: e.label })
				}
			}

		},
		updateExp() {
			this.list.forEach(e => e.isDisabled = true)
			let size = this.list.length
			this.selected.forEach((el) => {
				let indexSelected = this.list.findIndex(l => l.id == el);

				if (indexSelected != -1) this.list[indexSelected].isDisabled = false
				if (indexSelected != 0 && indexSelected != -1) this.list[indexSelected - 1].isDisabled = false
				if (indexSelected + 1 < size && indexSelected != -1) this.list[indexSelected + 1].isDisabled = false
			})
			this.list.forEach((el) => {
				if (el.selected) el.isDisabled = false
			});
			if (this.selected.length == 0) this.list.forEach(e => e.isDisabled = false)
		}
	}
}
</script>
<style lang="scss" scoped></style>