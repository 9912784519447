<template lang="">
	<router-link
		v-if="offer && $route.name==ROUTES_NAME.OFFERS"
		:to="{name:ROUTES_NAME.OFFER_DETAILS, params: {offerId:offer.short_id, name: toURL(offerTitle)}}"
		@click="saveShortid"
	>
		<OfferRowDetails :offer="offer" />
	</router-link>
	<!-- Suivi candidature-->
	<template v-if="!loading">
		<div
			v-if="$route.name==ROUTES_NAME.USERSUIVI && application "
			class="mb-4"
		>
			<div
				v-for="o in application"
				:key="o._id"
			>
				<div
					v-if="o.status"
					class="mt-2 rowHeader"
				>
					<div
						class=""
					>
						<p class="fw-normal h6 m-3">
							{{ $t(`etatProg`) }}
						</p>
						<span class="w-100 d-flex flex-center mb-3">
							<MatSlider
								class="w-80"
								:max="2"
								:min="0"
								:status="o.status"
								:disabled="true"
							/>
						</span>
					</div>
				</div>
				<OfferRowDetails
					:offer="o"
				/>
			</div>
		</div>
		<span
			v-if="$route.name==ROUTES_NAME.USERSUIVI && !application && loading"
		>{{ $t(`pasCandid`) }}</span>
	</template>
</template>
<script>
import { ROUTES_NAME } from "@/router"
import { shortLocations } from "@/utils/utils";
import OfferRowDetails from '@/components/application/offer-row-details.vue'

export default {
	name: "OfferRowComponent",
	props: {
		offer: {
			type: Object,
			default: null,
			loading: true
		},
	},
	components: {
		OfferRowDetails
	},
	data() {
		return {
			ROUTES_NAME,
			showShare: false,
			application: null,

		}
	},
	async mounted() {
		if (this.$route.params.config == "candidature"){ this.application = await this.$service.user.getUserCandidature()}
		this.loading=false
	},
	computed: {
		offerTitle() {
			return this.offer.job_title?.split(" ").join("-")
		},
		
		location() {
			return shortLocations(this.offer.geo);
		},
		date() {
			const duration = this.$moment.duration(
				this.$moment().diff(this.$moment(this.offer.pub_date))
			);
			const days = Math.round(duration.asDays());
			const hours = Math.round(duration.asHours());
			const minutes = Math.round(duration.asMinutes());
			if (minutes <= 59 || hours <= 12) {
				return this.$t('aujourdhui')
			}
			return days == 1 ? this.$t('hier') : new Date(this.offer.pub_date).toLocaleDateString("fr")
		},
	},
	methods: { 
		saveShortid() {
			this.$toMove.shortId = this.offer.short_id
			this.$toMove.search = { "params": this.$route.params, "query": this.$route.query }
		},
		toggleFavorite(e) {
			e.preventDefault();
		},
		remove(e) {
			e.preventDefault()
		},
		viewShare(e) {
			e.preventDefault()
			this.showShare = !this.showShare
		},
		share(e, rs) {
			e.preventDefault()
			const url = this.url.origin + '/offers/' + this.offer.short_id + '/' + this.toURL(this.offer.job_title)
			this.$utils.share(rs, url)
		},
	},
};
</script>
<style lang="scss" scoped>
.offer-img {
	// height: 100%;
	width: 200px;
	clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
	background: black;
	background-size: cover;
}

.company-logo {
	height: 75px;
	width: 75px;
	//background: white;
	margin-left: -60px;
	z-index: 1;
}

.disabled-offer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	background: rgba(255, 255, 255, .5);
}

a {
	transition: transform .5s;
	display: block;

	&:hover {

		//color: initial !important;
		section {
			//box-shadow: 0px 6px 15px rgb(0 0 0 / 80%);
		}
	}
}

.pourvue {
	transform: rotate(45deg);
	left: -97px;
	position: absolute;
	bottom: -40px;
	background-color: black;
	z-index: 2;
}

.bg-noimg {
	background: linear-gradient(150deg, var(--primary-light) 20.16%, rgba(var(--gradient-rgb), 0.2) 79%);

}

.bg_img {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.mdc-fab--mini {
	width: 30px;
	height: 30px;
}

.xs {
	width: 20px !important;
	height: 20px !important;
	font-size: 18px !important;
}
.rowHeader{
	border-radius: 20px 20px 0px 0px; border: 1px solid var(--action-disabled, rgba(0, 0, 0, 0.26));
}
</style>
