<template lang="">
	<ParentForm
		:title=" $t('mCandidtext')"
	>
		<div class="d-flex justify-content-center  buttons py-4 mb-5">
			<div
				class="px-2 py-4 m-2 rounded-shadow"
				@click="data.autoResumeClick"
			>
				<span class="material-icons-outlined flex-center text-primary h4 border border-2 rounded-circle">
					download
				</span>
				<p class="h6 text-center txt-secondary ">
					{{ $t('importCv') }}
				</p>
			</div>
			<div
				class="px-2 py-4 m-2 rounded-shadow"
				@click="data.manualResumeClick"
			>
				<span class="material-icons-outlined flex-center text-primary h4 border border-2 rounded-circle">
					edit
				</span>
				<p class=" h6 text-center txt-secondary">
					{{ $t('importMan') }}
				</p>
			</div>
		</div>
	</ParentForm>
</template>
<script>
import {ROUTES_NAME} from "@/router"
export default {
	data() {
		return {
			ROUTES_NAME
		}
	},
	props:{
		data:{
			type: Object,
			default: () => {}
		}
	}
}
</script>
<style lang="scss" scoped>
  .buttons > *{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 184px;
    height: 270px;
    cursor: pointer;
    &:hover{
      //margin-top: -3px !important;
      transform: scale(1.05);
      transition-duration: 0.4s;
      border-style:  solid  ;
      border-color: var(--primary-color) !important;
      border-width: 1px;
      .h4{
      border-color: var(--primary-color)  !important;
      border-width: 1px !important;
    }
    h6{
      color: var(--primary-color)  !important;
    }
    }
  }
  .h4{
    height: 70px;
    width: 70px;
    margin-top: 50px;
   
  }

</style>