<template lang="">
	<ParentGraph
		:title="showtext && !$siteconf.cooptation ? $t('mail-phone.text'): showtext && $siteconf.cooptation ? $t('coopInfo') :''"
		:show="errorImage"
	>
		<div
			class="position-relative candTop flex-center"
			v-if="!$siteconf.hideImages?.application"
		>
			<!-- <img
        
				src="/s/images/IT-guy-5.png"
				class="position-absolute mt-5"
			> -->
			<img
				alt="Contact"
				:src="imageSrc"
				ref="Image"
				class="position-relative ms-5"
			>
		</div>
	</ParentGraph>
</template>
<script>

import { imageErrorHandling } from "@/../public/js/imageErrorHandling"
export default {
	mixins: [imageErrorHandling],

	data() {
		return {
			errorImage: false,
			imageSrc: "/c/images/contact",
			fallbackImageSrc: "/s/images/IT-guy-1.png"
		};
	},
	mounted() {
		// Check the image dimensions on mount
		this.preloadImage(this.imageSrc, this.fallbackImageSrc);
	},
	props: {
		showtext: {
			type: Boolean,
			default: true,
		},
	},
};
</script>
<style lang="scss" scoped>
img {
	height: 60%;
	max-height: 380px;
	max-width: 380px;
}
</style>