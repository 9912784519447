import { createSSRApp, createApp, reactive } from "vue";
import App from "@/App";
import "./assets/scss/app.scss";
import routerOptions from "./router";
import { authRoutes, loginRoutes, ROUTES_NAME } from "@/router";
import services from "@/plugins/services";
import directives from "@/plugins/directives"
import components from "@/plugins/components"
import createHead from "@/plugins/head"
import i18n from './i18n'
import Client from "@/services/http"
import { toURL, shortText, pageTitle } from "@/utils/utils";
import { createRouter } from "vue-router";
import store from "./store";
import 'altcha';

class DomainNotFound extends Error { }

/** @param {{api: string, location: URL, ip?: string }} [context] */
export default function buildApp(context) {
	// TODO: fix hydration issues and only use createSSRApp
	const app = typeof window == "undefined" ? createSSRApp(App) : createApp(App);
	const agp = app.config.globalProperties;
	app.config.errorHandler = (err, vm, info) => console.log(err, vm, info);

	const ro = routerOptions();
	for (const route of ro.routes)
		if (route.beforeEnter) {
			const bare = route.beforeEnter;
			route.beforeEnter = async (to, from, next) => {
				try { await bare.call(app, to, from, next) }
				catch (e) {
					console.log(e);
					next();
				}
			}
		}
	const router = createRouter(ro);
	app.use(router);

	const head = createHead();
	app.use(head);

	agp.toURL = toURL;
	if (typeof window == "undefined")
		agp.url = context && context.location;
	else Object.defineProperty(agp, 'url', {
		get() { return window.location }
	});
	const http = agp.$http = context
		? new Client({ server: context.api, host: context.location.host, headers: context.headers })
		: new Client();
	app.use(services);
	app.use(directives);
	app.use(components);
	app.use(i18n);
	app.use(store);

	if (!agp.$siteconf && typeof (window) != 'undefined' && window.siteconf)
		agp.$siteconf = window.siteconf;
	agp.$config = reactive({ configLoaded: !!agp.$siteconf })
	router.beforeEach(async loc => {
		if(authRoutes.includes(loc.name) && !store.state.token) {
			return router.push({
				name: "LOGIN",
				params: { config: 'signin' }
			});
		}
		if (loc.name !== 'offers' && loc.name !== "offerDetails") {
			agp.$pref.buttonView=null
		}
		// save UTM parameters
		if(typeof (window) != 'undefined' && !window.utm) {
			const params = {};
			// permissive URL parsing
			location.href.split(/[?&]+/).map(kv => {
				const p = kv.indexOf('=');
				return p < 0 ? [kv] : [kv.substring(0, p), kv.substring(p+1)]
			}).forEach(kv => {
				if(kv[1] && !params[kv[0]]) params[kv[0]] = decodeURIComponent(kv[1].replace(/\+/g, ' '));
			})
			const utm = {};
			for(const k in params)
				if(k.startsWith('utm_') && k.length > 4)
					utm[k.substring(4)] = params[k];
			if(!utm.source && params.source)
				utm.source = params.source;
			if(!utm.source && (params.gad_source || params.gclid))
				utm.source = 'google-ads';
			if(!utm.source && params.fbclid)
				utm.source = 'facebook-ads';
			if(Object.keys(utm).length) window.utm = utm;
		}
		// load global domain / job board conf
		// but this should already be prefetched from ssr
		if (!app.$siteconf) try {
			agp.$siteconf = await http.get('site');
			agp.$config.configLoaded = true;
		} catch (e) {
			if ((e instanceof Error) && e.message.includes('404'))
				throw new DomainNotFound();
			throw e;
		}
		// FIXME: this is too early: app.$root.$i18n.locale = app.$siteconf.lang || 'fr';
		const siteconf = agp.$siteconf;
		//siteconf.accounts = true
		const url = typeof window != 'undefined' ? location : agp.url;
		const origin = siteconf?.domain ? 'https://' + siteconf.domain : url.origin;
		if (url.origin != origin) {
			const location = origin + url.pathname + url.search + url.hash;
			/* 301/permanent is for SEO, but in case of configuration changes,
			 * it can create redirect loops, which is why we try to prevent caching.
			 * domain conf changes are still harmfull to SEO but we can't do much
			 * about that */
			agp.$useHead(null, 301, {
				location,
				'Cache-Control': 'no-cache, no-store, must-revalidate',
				Pragma: 'no-cache',
				Expires: '0'
			});
		}
		if (!siteconf.contact_choice) siteconf.contact_choice = 'both';
		if (!siteconf.layout) siteconf.layout = "rows";
		if (!siteconf.lightCv) siteconf.lightCv = false;
		//siteconf.lightCv = true;
		const meta = [
			{ name: "description", content: shortText(siteconf.description, 160) },
			{ name: "og:locale", content: siteconf.lang == "en" ? "en_US" : "fr_FR" },
			{ name: "og:url", content: origin + url.pathname + url.search },
			{ name: "og:image", content: origin + '/c/' + (siteconf.logo ? 'logo.png' : 'banner.jpg') }
		];
		if (siteconf.noIndex)
			meta.push({ name: 'robots', content: 'noindex,nofollow' });
		agp.$useHead({ title: pageTitle(agp), meta });

		if (loginRoutes.includes(loc.name) && !siteconf.accounts) {
			return router.push({
				name: ROUTES_NAME.HOME,
			});
		}
	});
	return { app, router, head };
}
