<template lang="">
	<button
		ref="fab"
		class="mdc-fab"
		:class="{
			'mdc-fab--mini': mini,
			'mdc-fab--extended': label,
			'outlined': outlined
		}"
		:aria-label="label"
	>
		<div class="mdc-fab__ripple" />
		<span
			class="mdc-fab__icon material-icons"
		><template v-if="icon">{{ icon }}</template><slot v-else /></span>
		<span
			v-if="label"
			class="mdc-fab__label"
		>{{ label }}</span>
	</button>
</template>
<script>
export default {
	props:{
		label: {
			type: String,
			default: null
		},
		icon:{
			type:String,
			default: null
		},
		mini: Boolean,
		outlined: Boolean
	},
	mounted(){
		new window.mdc.ripple.MDCRipple(this.$refs.fab)
	}
}
</script>
<style lang="scss" scoped>
    .outlined{
        background: none
    }
</style>