<template lang="">
	<ParentGraph
		:title=" $t('mCondid')"
		:show="errorImage"
	>
		<div
			class="position-relative  flex-center candTop"
			v-if="!$siteconf.hideImages?.application"
		>
			<img
				v-if="errorImage"
				alt="background"
				src="/s/images/Calque_4.png"
				class="position-absolute mt-5"
			>
			<img
				alt="Mode de candidature"
				:src="imageSrc"
				ref="Image"
				class="position-relative ms-5"
			>
		</div>
	</ParentGraph>
</template>
<script>
import { imageErrorHandling} from "@/../public/js/imageErrorHandling"
export default {
	mixins: [imageErrorHandling],

	data() {
		return {
			errorImage: false,
			imageSrc: "/c/images/mode",
			fallbackImageSrc: "/s/images/mode_de_candidature.png"
		};
	},
	mounted() {
		// Check the image dimensions on mount
		this.preloadImage(this.imageSrc, this.fallbackImageSrc);
	}
	};
</script>
<style lang="scss" scoped>
.img {
	position: absolute;
	bottom: 0;
	left: 10%;
}

.img2 {
	position: absolute;
	left: 20%;
	bottom: 80px;
}
</style>