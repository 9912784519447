<template lang="">
	<div
		v-bind="$attrs"
		ref="container"
		class="position-relative h-100 w-100"
	>
		<div
			ref="searchContainer"
			class="bg-white rounded-pill p-1 ps-4 flex-vertical-center search-container position-relative ms-2 ms-lg-0"
			:class="searchContainerClass"
		>
			<span class="material-icons-outlined text-muted me-2 d-flex d-lg-none"> search </span>

			<MatChipset
				v-model="selection"
				class="selected-criteria"
				v-if="inputSelection"
			/>
			<input
				ref="input"
				v-model="search"
				:placeholder="$t(`searchPlaceholder.lg`)"
				@click="openMenu"
				@focus="searchContainerClass = ['input-focus']"
				@keyup="searchEnter"
				class="d-none d-lg-flex"
			>
			<input
				ref="input"
				v-model="search"
				:placeholder="$t(`searchPlaceholder.sm`)"
				@click="openMenu"
				@focus="searchContainerClass = ['input-focus']"
				@keyup="searchEnter"
				class="d-flex d-lg-none sminput"
				v-if="withMenu"
			>
			<MatButton
				class="search pill position-absolute end-0 top-0 mt-1 me-1 d-none d-lg-flex"
				raised 
				cta
				:label="$t(`recherch`)"
			/>
			<MatFab
				ref="clear"
				icon="delete"
				mini
				class="clear-search app-fab d-none d-lg-flex"
				:class="{ show: showClear }"
				@click="clearSearch"
			/>
		</div>
		<template v-if="withMenu">
			<MatMenu
				class="search-menu "
				:anchor-to="$refs.searchContainer"
				ref="menu"
				@closed="menuClosed"
				@opened="menuOpened"
				v-if="!flatMenu"
			>
				<SearchCriteria
					:noOffre="autoCompleteList.length>0 || statsdata.total > 0"
					@remove-search="removeSearch"
					ref="menuContent"
					:stats="statsdata"
					:autoCompleteList="autoCompleteList"
					:search="search"
					v-model="selection"
					:filter="search"
					:showSelection="!inputSelection"
				/>
			</MatMenu>
			<div
				v-else
				class="bg-white flat-menu"
			>
				<SearchCriteria
					:noOffre="autoCompleteList.length>0 || statsdata.total > 0"
					@remove-search="removeSearch"
					ref="menuContent"
					:stats="statsdata"
					:autoCompleteList="autoCompleteList"
					:search="search"
					v-model="selection"
					:filter="search"
					:showSelection="!inputSelection"
				/>
			</div>
		</template>
	</div>
</template>
<script>
import MatChipset from "@/components/material/mat-chipset.vue";
import SearchCriteria from "@/components/application/search-criteria.2.vue"
import utils from "@/utils/utils";
import { SearchIconMapping } from "@/utils/utils"


const placeholder = 'Nom de l’offre ou mots clé';

export default {
	components: {
		MatChipset,
		SearchCriteria
	},
	props: {
		criterias: { type: Array, default: () => [] },
		modelValue: { type: Object, default: () => { } },
		stats: { type: Object, default: () => { } },
		flatMenu: { type: Boolean, default: () => false },
		withMenu: { type: Boolean, default: () => true },
		inputSelection: { type: Boolean, default: () => true }
	},
	emits: ["menuOpened", "menuClosed", "update:modelValue", "data", "menuClosed"],
	data() {
		return {
			selection: [],
			searchContainerClass: [],
			selected: null,
			showClear: false,
			search: "",
			placeholder,
			autoCompleteList: [],
			statsdata: {},
			searchTimeout: null
		};
	},
	watch: {
		stats() {
			this.statsdata = this.stats
		},
		selected() {
			if (this.selected) {
				let newSelection = this.selected.map(s => ({ id: s.id, label: s.label, type: s.type, icon: s.icon }))
				if (!this.$utils.equals(newSelection, this.selection)) {
					this.selection = newSelection
				}
				this.searchText()
				this.notifyParent()
				if (this.selected?.length) {
					this.searchContainerClass = ['input-focus']
				}
				this.verifyClear();
				if (this.autoCompleteList && this.search) {
					let elCount = 0
					let el
					for (let key in this.autoCompleteList) {
						elCount += this.autoCompleteList[key].length
						if (this.autoCompleteList[key].length > 0) {
							el = this.autoCompleteList[key][0]
						}
					}
					if (elCount == 1 && el.selected == true) {
						this.search = ""
					}
				}


			}

		},
		selection: {
			handler() {
				this.updateSelection()
			},
			deep: true
		},
		modelValue: {
			handler() {
				if (!this.$utils.equals(this.modelValue, this.selected)) {
					this.selected = this.modelValue
				}
			},
			immediate: true
		},
		search() {
			this.search = this.search?.replace(/[$<>{};:]/g,'');
			this.searchText()
		}
	},
	mounted() {
		if (this.withMenu && !this.flatMenu) {
			document.body.addEventListener("mousedown", this.closeMenu);
		}
		setTimeout(this.updateSelection, 1000)

	},
	beforeUnmount() {
		document.body.removeEventListener("mousedown", this.closeMenu);
	},
	methods: {
		updateSelection() {
			let newSelected = this.selection.map(s => {
				let icon = SearchIconMapping[s.type] ||
				(this.$siteconf?.extraIndexes &&
					this.$siteconf.extraIndexes[s.type]?.icon) ||
				SearchIconMapping.jobrnt;
				return {
					icon: icon,
					label: s.label,
					id: s.id,
					type: s.type
				};
			});
			if (!this.$utils.equals(newSelected, this.selected)) {
				this.selected = newSelected
				this.$emit("update:modelValue", this.selected)
				this.verifyClear()
			}
		},
		async searchEnter(e) {
			if (this.searchTimeout) clearTimeout(this.searchTimeout)
			if (e.key == "Enter") {
				let elCount = 0
				let el
				let elKey
				for (let key in this.autoCompleteList) {
					elCount += this.autoCompleteList[key].length
					if (this.autoCompleteList[key].length > 0) {
						el = this.autoCompleteList[key][0]
						elKey = key
					}
				}
				if (elCount == 1) {
					this.selection.push({ label: el.l, id: el.id, type: elKey })
				} else {
					this.selection.push({ label: this.search, id: this.search, type: "kw" })
				}
				this.updateSelection()
				this.search = ""
				if (window.innerWidth < 550) {
					this.$emit("menuClosed");
				}
				else this.$refs.menu.hide();

			} else {
				setTimeout(async () => {
					this.autoCompleteList = []
					if (this.search.length > 2 || this.search.length == 0) {
						if (this.search != "") this.autoCompleteList = await this.$service.offer.autoComplete(this.search);
						else { this.$emit("data"); this.autoCompleteList = [] }
					}
					//else { this.$emit("data"); this.autoCompleteList = [] }
				}, 300)
			}

		},

		searchText() {
			this.verifyClear();
		},
		openMenu() {
			this.$refs.menu.open();
			if (window.innerWidth < 550) {
				this.$refs.menu.$el.style.width = "100%"
			}
			else this.$refs.menu.$el.style.width = this.$refs.container.clientWidth - 60 + "px";

		},
		select() {

		},
		notifyParent() {
			this.$emit("update:modelValue", this.selected)
		},
		closeMenu(e) {
			if (!utils.isDescendant(this.$refs.menu.$el, e.target)) {
				this.$refs.menu.hide();
			}
		},
		verifyClear() {
			this.showClear = this.search || this.selected?.length;
		},
		clearSearch() {
			this.$emit("data")
			this.search = "";
			this.selected = [];
			this.searchContainerClass = [];
			this.$refs.menu.hide();
			this.autoCompleteList = []
			//this.$emit("update:modelValue")
			this.notifyParent()
			this.verifyClear()
			//
		},
		menuOpened() {
			this.$emit("menuOpened");
			this.$refs.menuContent.updateUI()
		},
		menuClosed() {
			this.$emit("menuClosed");
		},
		removeSearch() {
			this.search = ""
		}
	},
};
</script>
<style lang="scss">
.search-menu {
	@media (max-width: 1200px) {
		margin-left: 0;
		box-shadow: none !important;
	}

	margin-left: 30px;

}
</style>
<style lang="scss" scoped>
input {
	border: none;
	// min-width: 420px;
	flex: 1;

	// transition: min-width .5s;
	&:focus {
		outline: none;
	}
}

.flat-menu {
	height: calc(100% - 110px);
	overflow-y: auto;
}

.search {
	transition: transform 0.5s;
	transform-origin: center right;
}

.search-container {
	height: 47px;
	transition: width 0.5s;

	&.input-focus {
		.search {
			transform: scale(0);
		}
	}

	border: var(--search-input-border);
}

.selected-criteria {
	flex-wrap: initial !important;
	max-height: 47px;
	overflow-y: hidden;

	//overflow-y: scroll;
	@media (max-width: 991px) {
		//flex-wrap: wrap !important;
		//overflow-y: scroll;
		//max-width: fit-content !important;
	}

	&::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}

	&::-webkit-scrollbar-track {
		background: gainsboro;
		border-radius: 20px !important;
	}

	&::-webkit-scrollbar-thumb {
		background: var(--primary-color);
		border-radius: 20px !important;
	}

	scrollbar-color: var(--primary-color) rgba(0, 0, 0, 0);
	// &.browser-firefox {
	// 	&::-webkit-scrollbar {
	// 	width: 0px;
	// 	height: 3px;
	// }

	//}

	//padding-right: 12px;
	//padding-left: 20px;

	.mdc-chip {
		background-color: red;
	}
}

.element-to-select {
	white-space: nowrap;
}

.clear-search {
	min-width: 40px;
	transform: scale(0);
	transition: 0.2s;

	&.show {
		transform: scale(1);
	}
}

.sminput {
	padding-right: -15%;
}
</style>
