export const EducationLevel = Object.freeze({
	"-2": "Aucun diplôme",
	"-1": "CAP, BEP, Bac pro",
	"0": "Baccalauréat",
	"2": "Bac+2, BTS",
	"3": "Bac+3, Bachelor, Licence, BUT",
	"4": "Bac+4, Master 1",
	"5": "Bac+5, Master, Ingénieur",
	"8": "Bac+8, Doctorat",
	"9": "Médecin",
	"2.2": "Bac+2, BTS",
	"3.3": "Bac+3, Bachelor, Licence, BUT",
	"4.4": "Bac+4, Master 1",
	"4.5": "Bac+4, Master 1",
	"5.5": "Bac+5, Master, Ingénieur",
	"8.8": "Bac+8, Doctorat",
	"9.9": "Médecin"

});

export const Contract = Object.freeze({
	"permanent": "CDI",
	"fixed-term": "CDD",
	"apprenticeship": "Alternance",
	"internship": "Stage",
	"civic": "Service civique",
	"fr-vie": "VIE",
	"freelance": "Indépendant",
	"training": "Formation",
	"coaching": "Coaching",
	"public-permanent": "Permanent",
	"public-non-permanent": "Non permanent"
});

export const Remote = Object.freeze({
	"regular": "Alternance télétravail / sur site",
	"occasional": "Télétravail toléré ponctuellement",
	"forbidden": "Aucun télétravail possible",
	"full": "Télétravail total"
});