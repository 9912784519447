// TODO: enable typescript in project and link common.ts/utils/removeAccents.ts

function rm_acc_lower_range(c, range) {
	switch(range.length) {
		case 0: return null;
		case 1: return range[0];
		default: {
			var mid = Math.ceil(range.length / 2);
			var mc = c.localeCompare(range[mid]);
			if(!mc) return range[mid];
			return rm_acc_lower_range(c, mc < 0 ? range.slice(0, mid) : range.slice(mid));
		}
	}
}
function rm_acc_lower(c) {
	return rm_acc_lower_range(c, 'abcdefghijklmnopqrstuvwxyz'.split(''));
}

/** Remove accents */
export default function removeAccents(str) {
	return str.split('').map(function(c) {
		const l = c.toLowerCase();
		if(l.localeCompare('a') < 0 || l.localeCompare('z') > 0) return c;
		const r = rm_acc_lower(l);
		return c == l ? r : (r ? r.toUpperCase() : c);
	}).join('');
}