<template lang="">
	<ParentGraph
		:title="$t('pupload')"
		:show="errorImage"
	>
		<div
			class="position-relative  flex-center"
			style="top:30px"
			v-if="!$siteconf.hideImages?.application"
		>
			<div
				class="p-5 bg-white details me-3 "
				v-if="errorImage"
			>
				<div class="d-flex">
					<span class="material-icons-outlined me-2">
						done
					</span>{{ $t('uploadText.text_1') }}
				</div>
				<div class="d-flex">
					<span class="material-icons-outlined me-2">
						done
					</span>{{ $t('uploadText.text_2') }}
				</div>
				<div class="d-flex">
					<span class="material-icons-outlined me-2">
						done
					</span>{{ $t('uploadText.text_3') }}
				</div>
			</div>
			<img
				ref="Image"
				:src="imageSrc"
				class="position-absolute"
				:class="[errorImage ? 'graphwithText':'graph']"
				alt="upload"
			>
		</div>
	</ParentGraph>
</template>
<script>
import { imageErrorHandling } from "@/../public/js/imageErrorHandling"
export default {
	mixins: [imageErrorHandling],

	data() {
		return {
			errorImage: false,
			imageSrc: "/c/images/upload",
			fallbackImageSrc: "/s/images/Illustrations.png"
		};
	},
	mounted() {
		// Check the image dimensions on mount
		this.preloadImage(this.imageSrc, this.fallbackImageSrc);
	}
};
</script>
<style lang="scss" scoped>
.details{
 // width: 370px;
  border-radius: 100px;
  position: absolute;
  top: 100px;
  left: 70px;
}
.graphwithText{
 top:230px
}
.graph{
	top:10px
}
.image-with-smooth-border {
  position: relative;
  overflow: hidden;
  width: 300px; /* Adjust the width and height as needed */
  height: 300px;
}

.image-with-smooth-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: radial-gradient(circle at center, transparent 0%, rgba(255, 255, 255, 0.7) 70%); /* Adjust opacity as needed */
  filter: blur(20px); /* Increase blur radius for a more pronounced effect */
}
</style>