<template>
	<div>
		<span>
			<div class="bg-white container wh-100">
				<div class="row pe-0 pe-lg-3">
					<div class="px-5 pt-3 col-lg-3 col-12 background">
						<section class="p-3 spaced noborder mt-4 mt-lg-0">
							<div class=" flex-vertical-center">
								<router-link
									:to="{ name: ROUTES_NAME.userDetail, params: { config: 'personalInfo' } }"
									class=" d-flex flex-vertical-center text-decoration-none"
									:class="{ 'leftDecoLine': shouldApplyLeftDecoLine }"
								>
									<span class="material-icons-outlined fs-5 me-2">
										account_circle
									</span> <span>Mon Profil </span></router-link>

							</div>
							<router-link
								:to="{ name: ROUTES_NAME.userDetail, params: { config: 'changePassword' } }"
								class=" d-flex flex-row  flex-vertical-center leftDecoLine"
							>
								<span class="material-icons-outlined fs-5 me-3">
									settings
								</span> <span>{{ $t(`param`) }} </span>

							</router-link>



						</section>
					</div>
					<div class="col-lg-9 col-12 position-relative p-0 pb-5">
						<ForgetPasswordForm
							class="mt-5"
							v-if="$route.params.config == 'changePassword'"
						/>
						<template v-if="$route.params.config != 'changePassword'">
							<!-- Avancement Profil  -->
							<div
								style="border-radius: 20px;border: 1px solid var(--action-disabled, rgba(0, 0, 0, 0.26));"
								class="d-none"
							>
								<h6 class="m-3">Avancement de profil </h6>
								<div class="m-3 d-flex  flex-vertical-center">
									<circle-progress
										:progress="progress + '%'"
										class="upload-progress me-2"
										showValue="true"
									/>
									<span>Compléter votre profil pour augmenter votre chance d’être contacté par les
										recruteurs
									</span>
								</div>
								<div class="m-3  d-flex  flex-vertical-center">
									<span class="material-icons rotate ">
										pan_tool_alt
									</span><span class="ms-2 subtitle1 txt-primary"> Pour bien améliorer votre profil
										veuillez
										télévharger votre cv </span>
								</div>
							</div>

							<!-- User card -->
							<userCard class="d-none" />

							<!-- Menu  -->
							<div
								v-if="shouldApplyLeftDecoLine"
								class="d-flex align-items-end my-3 "
								style="box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);height: 50px;top: 91px;width:100%"
							>
								<router-link
									:to="{ name: $route.name, params: { config: 'personalInfo' } }"
									class="flex-fill noTextDeco"
								>
									<TabButton
										class="tabs m-auto"
										name="t1"
										:class="{ active: view == 'personalInfo' }"
									>
										<span class="tabTitle">Informations personnelles</span>
									</TabButton>
								</router-link>
								<router-link
									v-if="form && form.length > 0"
									:to="{ name: $route.name, params: { config: 'questionnaire' } }"
									class="flex-fill noTextDeco"
								>
									<TabButton
										class="tabs m-auto"
										name="t1"
										:class="{ active: view == 'questionnaire' }"
									>
										<span class="tabTitle">Questionnaire</span>
									</TabButton>
								</router-link>
								<!--
								<router-link
									:to="{ name: $route.name, params: { config: 'cvs' } }"
									class="flex-fill noTextDeco"
								>
									<TabButton
										class="tabs m-auto"
										name="t1"
										:class="{ active: view == 'cvs' }"
									>
										<span class="tabTitle">Mes Cv</span>
									</TabButton>
								</router-link>
								<router-link
									:to="{ name: $route.name, params: { config: 'experiences' } }"
									class="flex-fill noTextDeco"
								>
									<TabButton
										class="tabs m-auto"
										name="t1"
										:class="{ active: view == 'experiences' }"
									>
										<span class="tabTitle">Expériences</span>
									</TabButton>
								</router-link>
								<router-link
									:to="{ name: $route.name, params: { config: 'formation' } }"
									class="flex-fill noTextDeco "
								>
									<TabButton
										class="tabs m-auto"
										name="t1"
										:class="{ active: view == 'formation' }"
									>
										<span class="tabTitle">Formation</span>
									</TabButton>
								</router-link>
								<router-link
									:to="{ name: $route.name, params: { config: 'skills' } }"
									class="flex-fill noTextDeco"
								>
									<TabButton
										class="tabs m-auto"
										name="t1"
										:class="{ active: view == 'skills' }"
									>
										<span class="tabTitle">Compétences</span>
									</TabButton>
								</router-link> -->

							</div>

							<!-- User details  -->
							<userDetail
								:userData="user"
								:questionnaire="form"
							/>
						</template>

					</div>
				</div>
			</div>
		</span>
	</div>
</template>
<script>
import { ROUTES_NAME } from "@/router"
import userDetail from "@/pages/user/user-detail.vue";
import userCard from "@/pages/user/user-card.vue";
import ForgetPasswordForm from "@/components/application/forms/forget-password-form.vue"

import '@/components/circle-progress'

export default {
	components: {
		userDetail, userCard, ForgetPasswordForm
	},
	computed: {
		computedConfig() {
			return this.$route.params.config === 'changePassword' ? 'personalInfo' : this.$route.params.config;
		},
		// userDetailLink() {
		// 	return {
		// 		name: ROUTES_NAME.userDetail,
		// 		params: {
		// 			config: this.computedConfig
		// 		}
		// 	};
		// },
		shouldApplyLeftDecoLine() {
			const validConfigs = ['personalInfo', 'cvs', 'experiences', 'formation', 'skills', 'questionnaire'];
			return validConfigs.includes(this.$route.params.config);
		}
	},
	data() {
		return {
			ROUTES_NAME,
			view: "personalInfo",
			progress: 0,
			user: null,
			form: null
		}
	},
	watch: {
		$route() {
			this.view = this.$route.params.config
		},
	},
	async mounted() {
		this.view = this.$route.params.config ? this.$route.params.config : "personalInfo";
		this.progress = 70
		try {
			this.user = await this.$service.user.getUser();
			this.form = this.user.questionnaire?.forms ? this.user.questionnaire.forms : null
			if (this.form) {
				this.form[0].fields.forEach((field, index) => {
					field.key = index;
				});
			}
			if ((!this.form || this.form.length == 0) && this.$route.params.config == "questionnaire") {
				this.$router.push({ name: this.$route.name, params: { config: 'personalInfo' } })
			}
			else {
				if (this.user.questionnaire && this.user.questionnaire.answers && this.user.questionnaire.answers.length > 0) 
					this.$userData(null, this.user.questionnaire.answers[0].id, this.user.questionnaire.answers[0].answers);
				else if (this.user.questionnaire && (!this.user.questionnaire.answers || this.user.questionnaire.answers.length == 0)) {
					this.$userData(null, this.user.questionnaire.forms[0].id, []);
				}
			}
			this.$utils.updateUserStorage(this.user)

		} catch (error) {
			console.log(error)
			this.user = null
			this.form = []
		}
	},
}
</script>
<style scoped lang="scss">
.tabTitle {
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0.4000000059604645px;
}

.active {
	color: var(--primary-color)
}

.rotate {
	transform: rotate(90deg);
	color: var(--primary-color);
}

.upload-progress {
	width: 38px;
	height: 38px;
	--color: var(--primary-75);
	--thickness: 3px;
}

.xs {
	width: 20px !important;
	height: 20px !important;
	font-size: 18px !important;
}

.fabxs {
	width: 28px;
	height: 28px;
}

.logo {
	width: 100px;
	height: 100px;
	min-width: 80px;
}

.wh-100 {
	min-height: 100vh;
}
</style>