<template lang="">
	<ParentForm
		v-bind="$attrs"
		v-if="skills"
		:title="$t('comp.formText')"
		headerClass="pb-2 border-bottom"
		@update="editform($event)"
		:disabled="isDisabled"
	>
		<div class="pb-4 border-bottom">
			<div class="subtitle1 py-2">
				{{ $t('comp.text1') }}
			</div>
			<div class="mb-2">
				<template
					v-for="(tech, index) in skills.selectedTechs"
					:key="tech"
				>
					<div class="flex-vertical-center">
						<MatChipset
							@remove="remove(index, skills.selectedTechs)"
							:model-value="[{label:tech.data, attributes:{class:'selected-varient'}}]"
						/>
						<StarRating
							v-if="tech.rating != null"
							v-model:rating="tech.rating"
							:star-size="20"
							:show-rating="false"
						/>
						<MatButton
							v-else
							:label="$t('comp.add')"
							@click="showRating(tech)"
						/>
					</div>
				</template>
			</div>
			<MatInput
				v-model="techVal"
				:label="$t('comp.text11')"
				@keyup="add($event, skills.selectedTechs, 'tech')"
			/>
			<MatIconButton
				icon="add_circle_outline"
				class="text-primary position-absolute icon-in-input mt-1 me-1"
				@click="add('click',skills.selectedTechs, 'tech')"
				noFocus
			/>
		</div>
		<div class="pb-4 border-bottom">
			<div class="subtitle1 py-2">
				{{ $t('comp.text2') }}
			</div>
			<div class="mb-2">
				<template
					v-for="(lang, index) in skills.selectedLangs"
					:key="lang"
				>
					<div class="flex-vertical-center">
						<MatChipset
							@remove="remove(index, skills.selectedLangs)"
							:model-value="[{label:lang.data, attributes:{class:'selected-varient'}}]"
						/>
						<StarRating
							v-if="lang.rating != null"
							v-model:rating="lang.rating"
							:star-size="20"
							:show-rating="false"
						/>
						<MatButton
							v-else
							:label="$t('comp.add')"
							@click="showRating(lang)"
						/>
					</div>
				</template>
			</div>
			<MatInput
				v-model="langVal"
				:label="$t('comp.text2')"
				@keyup="add($event, skills.selectedLangs, 'lang')"
			/>
			<MatIconButton
				icon="add_circle_outline"
				class="text-primary position-absolute icon-in-input mt-1 me-1"
				@click="add('click',skills.selectedLangs, 'lang')"
				noFocus
			/>
		</div>
		<div class="pb-4 border-bottom">
			<div class="subtitle1 py-2">
				{{ $t('comp.text3') }}
			</div>
			<div class="mb-2">
				<template
					v-for="(skill, index) in skills.selectedSkills"
					:key="skill"
				>
					<div class="flex-vertical-center">
						<MatChipset
							@remove="remove(index, skills.selectedSkills)"
							:model-value="[{label:skill.data, attributes:{class:'selected-varient'}}]"
						/>
						<StarRating
							v-if="skill.rating != null"
							v-model:rating="skill.rating"
							:star-size="20"
							:show-rating="false"
						/>
						<MatButton
							v-else
							:label="$t('comp.add')"
							@click="showRating(skill)"
						/>
					</div>
				</template>
			</div>
			<MatInput
				v-model="softVal"
				:label="$t('comp.text3')"
				@keyup="add($event, skills.selectedSkills , 'soft')"
			/>
			<MatIconButton
				icon="add_circle_outline"
				class="text-primary position-absolute icon-in-input mt-1 me-1"
				@click="add('click',skills.selectedSkills, 'soft')"
				noFocus
			/>
		</div>
		<div
			class="d-flex flex-row justify-content-center"
			:class="{'d-none': $route.params.step=='validation'|| $route.name=='userDetail' }"
		>
			<MatButton
				outlined
				:label="$t('return')"
				@click="onPrevClick"
				class="me-4"
			/>
			<MatButton
				raised
				:label="form ? $t('suiv') : $t('soumettreCandi')"
				@click="onNextClick"
			/>
		</div>
	</ParentForm>
</template>
<script>
import { defineAsyncComponent } from '@vue/runtime-core'
import { ROUTES_NAME } from "@/router"


export default {
	emits: ['updateUser'],
	data() {
		return {
			skills: null,
			techVal: "",
			langVal: "",
			softVal: "",
			isDisabled: true,
			ROUTES_NAME
		}
	},
	props: {
		data: {
			type: Object,
			default: () => null
		},
		title: {
			type: String,
			default: "Mettez en avant vos compétences"
		},
		form: {
			type: Boolean,
			default: () => true
		},
		onNextClick: {
			type: Function,
			default: () => () => alert("next")
		},
		onPrevClick: {
			type: Function,
			default: () => () => alert("prev")
		},
	},
	components: {
		StarRating: defineAsyncComponent(() => import('vue-star-rating'))
	},
	watch: {
		data() {
			this.skills = this.data;
		},
		skills: {
			handler() {
				if (this.$route.name == ROUTES_NAME.POST) this.$userData(null, 'skills', this.skills)
			},
			deep: true,
		},
		$route() {
			if (this.$route.name == ROUTES_NAME.POST) {
				this.isDisabled=false
			}
		}
	},
	computed: {
		verif() {
			let vide = true
			if (this.skills?.selectedSkills.length > 0 || this.skills?.selectedTechs.length > 0 || this.skills?.selectedLangs.length > 0) vide = false
			return vide
		}
	},
	async mounted() {
		document.body.scrollTop = 0;
		this.skills = this.$userData.load('skills', this.data);
	
		// this.$userData(null, 'skills',{
		// 		selectedTechs: [],
		// 		selectedLangs: [],
		// 		selectedSkills: []
		// 	})
		// this.skills = this.$userData.load('skills', this.data);
		// console.log(this.skills);
	},
	methods: {
		add(e, list, type) {
			if (e == "click" || e.key === "Enter") {
				if (type == "tech" && this.techVal) {
					//console.log('add', this.techVal, list);
					let index = list.findIndex(l => l.data == this.techVal)
					if (index == -1) list.push({ rating: null, data: this.techVal })
					this.techVal = ""
				}
				if (type == "lang" && this.langVal) {
					let index = list.findIndex(l => l.data == this.langVal)
					if (index == -1) list.push({ rating: null, data: this.langVal })
					this.langVal = ""
				}
				if (type == "soft" && this.softVal) {
					let index = list.findIndex(l => l.data == this.softVal)
					if (index == -1) list.push({ rating: null, data: this.softVal })
					this.softVal = ""
				}

			}
		},
		addVarient(e, list) {
			if (e && !list.find(l => l.data == e)) list.push({ rating: null, data: e })
			this.$refs.techAutocomplete?.clear();
			this.$refs.langAutocomplete?.clear();
			this.$refs.skillAutocomplete?.clear();
		},
		remove(index, list) {
			list.splice(index, 1)
		},
		showRating(varient) {
			varient.rating = 4;
		},
		editform(type) {
			//edit: show edit form 
			if (type == 'edit') this.isDisabled = !this.isDisabled
			else if (type == 'cancelUpdate') {
				this.skills = this.$userData.load('skills', this.data);
				this.isDisabled = true
			}
			//update: update localStorage et base 
			else if (type == 'update') {
				this.$userData(null, 'skills', this.skills)

				let obj = {}

				if (this.skills.selectedLangs && this.skills.selectedLangs.length) {
					obj.languages = [];
					this.skills.selectedLangs.forEach(el => {
						el.rating ? obj.languages.push({ "language": el.data, "level": el.rating }) : obj.languages.push({ "language": el.data })

					});
				}
				if (this.skills.selectedSkills && this.skills.selectedSkills.length) {
					obj.skills = {}
					obj.skills.soft = []
					this.skills.selectedSkills.forEach(el => {
						el.rating ? obj.skills.soft.push({ "name": el.data, "level": el.rating }) : obj.skills.soft.push({ "name": el.data })

					});
				}
				if (this.skills.selectedTechs && this.skills.selectedTechs.length) {
					if (!obj.skills) obj.skills = {};
					obj.skills.technical = [];
					this.skills.selectedTechs.forEach(el => {
						el.rating ? obj.skills.technical.push({ "name": el.data, "level": el.rating }) : obj.skills.technical.push({ "name": el.data })

					});
				}
				this.$emit("updateUser", obj);
				this.isDisabled = true
			}
		},
	}
}
</script>
<style lang="scss">
.selected-varient {
	background: transparent !important;

	* {
		color: var(--text-primary) !important;
	}

	.mdc-chip__ripple {
		border: 1px solid var(--disabled-color);
	}
}

.icon-in-input {
	right: 0;
}
</style>
