import axios from "axios"

export default class Client {
	/**
	 * @param {{server: string, host: string, headers?: Record<string,string>}} [serverContext] */
	constructor(serverContext) {
		const headers = {};
		if(serverContext) {
			for(const k in serverContext.headers)
				headers[k] = serverContext.headers[k];
			if(serverContext.host)
				headers.Host = serverContext.host;
		}
		if(Object.keys(headers).length) this.headers = headers;
		this.pathPrefix = '/c/'; // should start with a slash
		if(serverContext && serverContext.server)
			this.server = serverContext.server;
	}
	normalizeUrl(url) {
		if(url.startsWith('http://') || url.startsWith('https://'))
			return url;
		if(!url.startsWith('/'))
			url = this.pathPrefix + url;
		// for here, url starts with /
		if(this.server)
			return this.server + url;
		if(this.host)
			return `https://${this.host}${url}`;
		return url;
	}
	defaultHeaders(headers) {
		if(this.headers) {
			return headers ? { ...headers, ...this.headers } : this.headers
		} else return headers;
	}
	defaultOptions(options) {
		if(options) {
			const headers = this.defaultHeaders(options.headers);
			const out = { ...options };
			if(headers) out.headers = headers;
			return out;
		} else {
			const headers = this.defaultHeaders();
			if(headers) return { headers }
		}
	}
	get(url, options) {
		return axios.get(this.normalizeUrl(url), this.defaultOptions(options));
	}
	post(url, data, options) {
		return axios.post(this.normalizeUrl(url), data, this.defaultOptions(options));
	}
}

// making treatments after getting response
axios.interceptors.response.use((response) => {
	if (response.config.fullResponse) {
		return response
	}
	return response.data;
}, (err) => {
	if (err?.response?.status === 500 ) {
		// TODO: show popup/flash instead of redirection
	//	location.href=location.origin+'/server_error'
	}
	return Promise.reject(err)

})
