<template lang="">
	<ParentGraph
		title=""
	>
		<div 
			class="graph-content"
			v-if="!$siteconf.hideImages?.application"
		>
			<h4 class="mb-5">
				Connexion
			</h4>
			<div class="rounded-shadow bg-white advantages p-4">
				<h6>{{ $t('loginText.1') }}</h6>
				<div class="spaced-2 ">
					<div class="subtitle2 d-flex flex-vertical-center">
						<div class="w-10 flex-shrink-0">
							<SvgIcon
								icon="free"
								size="16"
							/>
						</div>
						{{ $t('loginText.8') }}
					</div>
					<div class="subtitle2 d-flex flex-vertical-center">
						<div class="w-10 flex-shrink-0">
							<SvgIcon
								icon="speed"
								size="16"
							/>
						</div>
						{{ $t('loginText.2') }}
					</div>
					<div class="subtitle2 d-flex  flex-vertical-center">
						<div class="w-10 flex-shrink-0">
							<SvgIcon
								icon="phone"
								size="16"
							/>
						</div>
						{{ $t('loginText.3') }}
					</div>
					<div class="subtitle2 d-flex  flex-vertical-center">
						<div class="w-10 flex-shrink-0">
							<SvgIcon
								icon="rules"
								size="16"
							/>
						</div>
						{{ $t('loginText.4') }}
					</div>
					<div class="subtitle2 d-flex  flex-vertical-center">
						<div class="w-10 flex-shrink-0">
							<SvgIcon
								icon="hiring"
								size="16"
							/>
						</div>
						{{ $t('loginText.5') }}
					</div>
					<div class="subtitle2 d-flex  flex-vertical-center">
						<div class="w-10 flex-shrink-0">
							<SvgIcon
								icon="mail"
								size="16"
							/>
						</div>
						{{ $t('loginText.6') }}
					</div>
					<div class="subtitle2 d-flex  flex-vertical-center">
						<div class="w-10 flex-shrink-0">
							<span class="material-icons-outlined text-primary">
								bookmark_border
							</span>
						</div>
						{{ $t('loginText.7') }}
					</div>
				</div>
			</div>
		</div>
	</ParentGraph>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
  
  .graph-content{
    position: absolute;
    margin-top: 10vh;
    right: 80px;
    width: 350px;

  }
  .w-10,.flex-shrink-0{
    text-align: center;
  }
</style>