import crypt from "@/utils/crypt"

export default class appService {
	constructor(http) { this.http = http }
	captcha(){
		return this.http.get('/c/challenge');
	}
	signup(params) {

		const requestBody = {
			email: params.email,
			password: crypt.hash(params.password),
			rgpd_consent: params.rgpd_consent,
			captcha: params.captcha
		};
	
		// Check if params.phone exists before adding it to the request body
		if (params.phone) {
			requestBody.phone = params.phone;
		}
		return this.http.post(`/c/signup`,requestBody);
	}
	signin(params) {
		return this.http.post(`/c/signin`,{
			email: params.email,
			password: crypt.hash(params.password),
			captcha: params.captcha
		});
	}
	logout(){
		return this.http.get(`/c/logout`)
	}
	reset(email, captcha){
		return this.http.post(`/c/reset`, {
			email,
			captcha
		})
	}
	changeOldPassword(old,newpass, captcha){
		return this.http.post(`/c/reset-password`, {
			"old": crypt.hash(old),
			"new": crypt.hash(newpass),
			captcha
		});
	}
	changeNewPassword(token,newpass, captcha){
		return this.http.post(`/c/reset-password`, {
			"new": crypt.hash(newpass),
			token,
			captcha
		});
	}
	changePassword(token){
		return this.http.get(`/c/reset-password/`+token)
	}
	verifyAccount(token){
		return this.http.get(`/c/verify/`+token)
	}
	retryAccount(){
		return this.http.get(`/c/retry_verification`)
	}
}