<template lang="">
	<ParentGraph
		:title="!$siteconf.cooptation ? $t('infoGen.graphText'): $t('coopInfo') "
		:show="errorImage"
	>
		<div
			class="position-relative  flex-center candTop"
			v-if="!$siteconf.hideImages?.application"
		>
			<img
				v-if="errorImage"
				alt="white background"
				src="/s/images/Calque_2.svg"
				class="position-absolute mt-5"
			>
			<img
				alt="information"
				:src="imageSrc"
				ref="Image"
				class="position-relative ms-5"
			>
		</div>
	</ParentGraph>
</template>
<script>
import { imageErrorHandling} from "@/../public/js/imageErrorHandling"
export default {
	mixins: [imageErrorHandling],

	data() {
		return {
			errorImage: false,
			imageSrc: "/c/images/infos",
			fallbackImageSrc: "/s/images/Unsunscribe.png"
		};
	},
	mounted() {
		// Check the image dimensions on mount
		this.preloadImage(this.imageSrc, this.fallbackImageSrc);
	}
	};
</script>
<style lang="scss" scoped>

</style>