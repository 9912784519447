<template
	v-if="$route.params?.offerId !='0'"
>
	<div class="py-2 flex-vertical-center container-fluid">
		<div class="spaced-2 d-none d-lg-block">
			<router-link
				v-if="$route.params?.offerId !='0'"
				class="underline-none "
				:to="{ name: ROUTES_NAME.OFFER_DETAILS, params: $route.params }"
			>
				<h1 class="pt-2">
					{{ headerTitle }}
				</h1>
			</router-link>
			<h1
				v-else
				class="pt-2 defautCur "
			>
				{{ headerTitle }}
			</h1>
			<div
				class="flex-vertical-center txt-secondary h-spaced-4"
				v-if="$route.params.offerId != 0"
			>
				<div
					class="body2 flex-vertical-center"
					v-if="date"
				>
					<span class="material-icons-outlined fs-5 me-2">
						schedule
					</span>
					{{ $t('debut') }} : {{ date }}
				</div>
				<div
					class="body1 flex-vertical-center"
					v-if="offer.contract"
				>
					<span class="material-icons-outlined fs-5 me-2">
						description
					</span>
					{{ getContract }}
				</div>
				<div
					class="body1 flex-vertical-center"
					v-if="offer.location"
				>
					<span class="material-icons fs-5 me-2">
						place
					</span>
					{{ offer.location }}
				</div>
				<div
					class="body1 flex-vertical-center"
					v-if="education"
				>
					<span class="material-icons fs-5 me-2">
						school
					</span>
					{{ $t('education.' + offer?.edu_lvl) }}
				</div>
				<div
					class="body1 flex-vertical-center"
					v-if="offer.exp_lvl"
				>
					<span class="material-icons-outlined fs-5 me-2">
						work
					</span>
					>{{ offer.exp_lvl }} {{ $t('ans') }}
				</div>
				<div
					class="body1 flex-vertical-center"
					v-if="offer.salary"
				>
					<span class="material-icons-outlined fs-5 me-2">
						payments
					</span>
					{{ salary }}
				</div>
			</div>
		</div>
		
		<div class="spaced-2 d-block d-lg-none pt-2 ">
			<router-link
				v-if="$route.params?.offerId !='0'"
				class="underline-none "
				:to="{ name: ROUTES_NAME.OFFER_DETAILS, params: $route.params }"
			>
				<h6 class="pt-2">
					{{ headerTitle }}
				</h6>
			</router-link>
			<h6
				v-else
				class="pt-2 defautCur"
			>
				{{ headerTitle }}
			</h6>
			<div class="flex-vertical-center ">
				<span class="body2 ">
					{{ offer.company }} <span
						class="mx-1"
						v-if="offer.company && offer.location"
					>|</span> {{
						offer.location }} <span
						class="mx-1"
						v-if="offer.location && offer.contract"
					>|</span>{{
						getContract }}<span
						class="mx-1"
						v-if="offer.contract && offer.salary"
					>|</span>{{ salary
					}}
				</span>
			</div>
		</div>
		<div class="flex-max" />
		<router-link
			v-if="$route.params.offerId == 0"
			class="underline-none me-3 end-0"
			:to="{ name: ROUTES_NAME.HOME }"
		>
			<MatFab
				@click="$emit('reset', 'return')"
				mini
				icon="close"
			/>
		</router-link>
		<router-link
			v-else
			class="underline-none me-3 end-0"
			:to="{ name: ROUTES_NAME.OFFER_DETAILS, params: $route.params }"
		>
			<MatFab
				@click="$emit('reset', 'return')"
				mini
				icon="close"
			/>
		</router-link>
	</div>
</template>
<script>
import { ROUTES_NAME } from "@/router";
import { EducationLevel } from "@/utils/constants";

export default {
	emits: ['reset'],
	props: {
		offer: {
			type: Object,
			default: () => {}
		}
	},
	data(){
		return {
			ROUTES_NAME
		}
	},
	computed: {
		education() {
			return EducationLevel[this.offer.edu_lvl];
		},
		date() {
			if (this.offer.date) {
				let date = this.offer.date.substring(0, 10);
				return date
			}
			else return ""
		},
		headerTitle() {
			return (this.$route.params.offerId == 0 && "Candidature spontanée" || "") || this.offer.job_title 
		},
		getContract() {
			const contracts = this.offer?.contract ? this.offer.contract.split(', ') : [];
			if (contracts.length) {
				return contracts.map(c => this.$t('contract.' + c)).join(', ')
			}
			return '';
		},
		salary() {
			let salary = this.offer.salary
			let salaryText = ""
			if (salary) {
				if (!salary.min && !salary.max) return ""
				if (salary.min && salary.max) salaryText += salary.min + "-" + salary.max
				else if (salary.min) salaryText += salary.min
				else if (salary.max) salaryText += salary.max

				salaryText += salary.currency === 'EUR' || !salary.currency ? '€' : '$';

				if (salary.period) salaryText += "/" + this.$t(this.offer.salary.period)

				return salaryText
			}
			else return salaryText
		}
	}
}
</script>