<template lang="">
	<div
		ref="collapse"
		class="collapse p-2"
	>
		<slot />
	</div>
</template>
<script>
// import Collapse from "bootstrap/js/dist/collapse"
export default {
	props:{
		trigger:{
			type: [Function , Object],
			default: ()=>null
		},
		open:{
			type: Boolean,
			default: true
		}
	},
	methods: {
		show(){
			this.collapse.show();
		},
		hide(){
			this.collapse.hide();
		},
		toggle(){
			this.collapse.toggle();
		}
	},
	async mounted() {
		const Collapse = (await import("bootstrap/js/dist/collapse")).default
		if(this.open ==true) this.collapse = new Collapse(this.$refs.collapse,{toggle: true})
		else this.collapse = new Collapse(this.$refs.collapse,{ toggle: false })
		const trigger = typeof(this.trigger) == "function" ? this.trigger() : this.trigger;
		trigger?.iconButtonRipple.root.addEventListener("click", this.toggle)
		this.hide();
	},
	beforeUnmount(){
		const trigger = typeof(this.trigger) == "function" ? this.trigger() : this.trigger;
		trigger?.iconButtonRipple.root.removeEventListener("click", this.toggle)
	}
}
</script>
<style lang="">
  
</style>