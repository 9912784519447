import { createWebHistory, createMemoryHistory } from "vue-router";
import offers from "@/pages/offers.vue";
import offerDetails from "@/pages/offer-details.vue";
import Login from "@/pages/login"
import candidateForm from "@/pages/candidate-form.vue";
import home from "@/pages/home.vue"
import qs from "qs"
import ERRORS from "@/../public/js/errors"
import ErrorComponent from "@/pages/error.vue"
import PrivacyComponent from "@/pages/privacy.vue"
import { toURL } from "./utils/utils";
import ForgetPassword from "@/pages/forget-password"
import userDetail from "@/pages/user/user-component.vue";
import UserSuivi from "@/pages/user/user-suivi.vue";
import questionnaire from "@/pages/form-page.vue"
export const ROUTES_NAME = Object.freeze({
	HOME: "home",
	OFFERS: "offers",
	OFFER_DETAILS: "offerDetails",
	EXPLORE: "explore",
	FOLLOW: "follow",
	POST: "post",
	LOGIN: "LOGIN",
	ForgetPassword: "forgetpassword",
	SIGNUP_INFO: "signup-info",
	PRIVACY: "privacy",
	ERROR: {
		...Object.keys(ERRORS).map(key => ({ error: key, name: ERRORS[key].name })).reduce((a, b) => ({ ...a, [b.error]: b.name }), {})
	},
	VERIFY_ACCOUNT: "verify_account",
	userDetail: "userDetail",
	USERSUIVI: "UserSuivi"
});

export const authRoutes = [
	ROUTES_NAME.USERSUIVI,
	ROUTES_NAME.userDetail,
];
function query(vueUrl) {
	const extra = vueUrl.fullPath.split('?')[1];
	return extra ? '?' + extra : '';
}


export const loginRoutes = [
	ROUTES_NAME.LOGIN,
];

const isServer = typeof window === "undefined";

export default function routerOptions() {
	const history = isServer ? createMemoryHistory() : createWebHistory();
	const routes = [
		{
			path: "",
			redirect: '/home'
		},
		{
			path: "/jobs",
			redirect: '/offers'
		},
		{
			path: "/jobs/home",
			redirect: '/offers'
		},
		{
			path: "/jobs/offer/:longId?",
			name: 'legacyRedirect_offer', // apparently names are needed for the redirect
			beforeEnter: async function (to, _, next) {
				const srv = this.config.globalProperties.$service;
				try {
					const info = await srv.offer.info(to.params.longId);
					next(`/offers/${info.shortId}/${toURL(info.title)}${query(to)}`);
				} catch (e) {
					next('/not_found')
				}
			}
		},
		{
			path: "/jobs/apply/:longId",
			name: 'legacyRedirect_apply',
			beforeEnter: async function (to, _, next) {
				const srv = this.config.globalProperties.$service;
				try {
					const info = await srv.offer.info(to.params.longId);
					next(`/offers/${info.shortId}/${toURL(info.title)}/post/mode/adresse${query(to)}`);
				} catch (e) {
					next('/not_found')
				}
			}
		},
		{
			path: "/home",
			name: ROUTES_NAME.HOME,
			component: home,
		},
		{
			path: "/offers",
			name: ROUTES_NAME.OFFERS,
			component: offers,
			beforeEnter: (to, _, next) => {
				const keyword = to.hash && decodeURIComponent(to.hash.substring(1)).trim();
				if (!keyword) return next();
				const q = new URLSearchParams(to.fullPath.split('#')[0].split('?')[1]);
				q.set('search[0][label]', keyword);
				q.set('search[0][id]', keyword);
				q.set('search[0][type]', 'kw');
				next(`/offers?${q}`);
			}
		},
		{
			path: "/offers/:offerId/:name/post/:config?/:step?",
			name: ROUTES_NAME.POST,
			component: offerDetails,
		},
		{
			path: "/offers/:shortId",
			name: 'offer_no_name',
			beforeEnter: async function (to, _, next) {
				const srv = this.config.globalProperties.$service;
				try {
					const info = await srv.offer.findOne(to.params.shortId);
					next(`/offers/${to.params.shortId}/${toURL(info.job_title)}${query(to)}`);
				} catch (e) {
					next('/not_found')
				}
			}
		},
		{
			path: "/offers/:offerId/:name",
			name: ROUTES_NAME.OFFER_DETAILS,
			component: offerDetails,
		},
		{
			path: "/explore",
			name: ROUTES_NAME.EXPLORE,
			component: offers,
		},
		{
			path: "/follow",
			name: ROUTES_NAME.FOLLOW,
			component: offers,
		},
		{
			path: "/signup/info/:step?",
			name: ROUTES_NAME.SIGNUP_INFO,
			component: candidateForm
		},
		{
			path: "/signup/info/:step?",
			name: ROUTES_NAME.SIGNUP_INFO,
			component: candidateForm
		},
		{
			path: "/:config(signin|signup|resetpassword)",
			name: ROUTES_NAME.LOGIN,
			component: Login
		},
		{
			path: "/:config(changePassword|reset-password)?/:token?",
			name: ROUTES_NAME.ForgetPassword,
			component: ForgetPassword
		},

		{
			path: "/privacy",
			name: ROUTES_NAME.PRIVACY,
			component: PrivacyComponent,
			// props: true
		},
		// Error pages
		...(Object.keys(ERRORS).map(key => ({
			path: ERRORS[key].location,
			name: key,
			component: ErrorComponent
		}))),
		{
			path: "/:pathMatch(.*)*",
			name: 'NOT_FOUND',
			component: ErrorComponent
		},
		{
			path: "/expired/:config?",
			name: 'EXPIRED',
			component: ErrorComponent
		},
		{
			path: "/invalid-link/:config?",
			name: 'INVALID',
			component: ErrorComponent
		},
		{
			path: "/verify_account/:token?",
			name: ROUTES_NAME.VERIFY_ACCOUNT,
			component: home,
		}, {
			path: "/user/:config(personalInfo|cvs|experiences|formation|skills|checkAccount|changePassword|questionnaire)?",
			name: ROUTES_NAME.userDetail,
			component: userDetail,
		}, {
			path: "/suivi/:config(candidature|favoris)?",
			name: ROUTES_NAME.USERSUIVI,
			component: UserSuivi,
		},
		{
			path: "/questionnaire/v1/:jwt?",
			name: "formByToken",
			component: questionnaire,
		},

	];
	return {
		history,
		routes,
		parseQuery(query) {
			return qs.parse(query)
		},
		stringifyQuery(query) {
			return qs.stringify(query);
			// return result ? ('?' + result) : '';
		},
		//scrollBehavior (to, from, savedPosition) {
		scrollBehavior() {
			// console.log(savedPosition);
			// if (savedPosition) {
			// return   savedPosition
			// } else if (savedPosition === null) {
			return { left: 0, top: 0 }
			//}
		}
	};
}

// const authGard = (to, from, next) => {
//     next()
// }
// router.beforeEach(authGard)
