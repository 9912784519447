

export default {
	createMarkerIcon(number, color){
		const c = typeof(color) === "string" ? color : `rgb(${color.join(",")})`
		return {
			html: `
      <div class="overflow-hidden">
      <svg width="26" height="35" viewBox="0 0 19 25" 
			fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" 
			d="M3.05005 3.03301C6.4918 -1.011 12.0719 -1.011 15.5137 
			3.03301C18.9554 7.07703 18.9554 13.6336 15.5137 17.6777L9.28187 
			25L3.05005 17.6777C-0.391684 13.6336 -0.391684 7.07703 3.05005 3.03301Z" fill="${c}"/>
      </svg>
      <span class="position-absolute start-0 h-100 w-100 flex-center fw-bold text-white" 
			style="top:-3px">
			${number}
			</span>
      </div>`,
			className: "",
			iconSize: [26, 35],
			iconAnchor: [13, 35],
		}
	}
}