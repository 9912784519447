<template lang="">
	<div
		ref="matMenu"
		class="mdc-menu mdc-menu-surface"
		:class="[type === 'select' ? 'mdc-select__menu' : '']"
		v-bind="$attrs"
	>
		<ul
			v-if="type==='select'"
			class="mdc-list"
			role="menu"
			aria-hidden="true"
			aria-orientation="vertical"
			tabindex="-1"
		>
			<li
				v-if="type === 'select' && !withoutBlank"
				class="mdc-list-item mdc-list-item--selected"
				data-value=""
				role="option"
			>
				<span class="mdc-list-item__ripple" />
			</li>
			<slot />
		</ul>
		<slot v-else />
	</div>
</template>
<script>
export default {
	props: {
		type: {
			type: String,
			default: "",
		},
		anchorTo: {type: Object, default: () => {}},
		withoutBlank: Boolean
	},
	watch: {
		anchorTo: {
			handler() {
				if (this.anchorTo)
					this.menu.setAnchorElement(this.anchorTo.$el || this.anchorTo);
			},
			immediate: true
		},
	},
	mounted() {
		var MDCMenu = window.mdc.menu.MDCMenu;
		this.menu = new MDCMenu(this.$refs.matMenu);
		this.menu.setFixedPosition(true);
		this.menu.setAnchorCorner(9);
		this.onMenuClosed = this.onMenuEvent("closed")
		this.onMenuClosing = this.onMenuEvent("closing")
		this.onMenuOpened = this.onMenuEvent("opened")
		this.menu.menuSurface.listen("MDCMenuSurface:opened", this.onMenuOpened)
		this.menu.menuSurface.listen("MDCMenuSurface:closing", this.onMenuClosing)
		this.menu.menuSurface.listen("MDCMenuSurface:closed", this.onMenuClosed)
		if (this.type !== "select") {
			this.menu.setDefaultFocusState(0);
		}
		setTimeout(() => {
			document.querySelector("body").appendChild(this.menu.root);
		});
	},
	unmounted(){
		this.menu.menuSurface.unlisten("MDCMenuSurface:opened", this.onMenuOpened)
		this.menu.menuSurface.unlisten("MDCMenuSurface:closing", this.onMenuClosing)
		this.menu.menuSurface.unlisten("MDCMenuSurface:closed", this.onMenuClosed)
	},
	methods: {
		open() {
			this.menu.open = true;
		},
		hide() {
			this.menu.open = false;
		},
		onMenuEvent(eventName){
			return (evt) => {
				this.$emit(eventName, evt)
			}
		}
	},
};
</script>
<style lang="scss" scoped>
.mdc-menu {
	position: absolute;
	
	@media (max-width: 991px) {
			max-height: 78% !important;
			//top: 90px !important;
			top: 61.375px !important;
			z-index: 100000;
		}
}
</style>
