<template lang="">
	<label
		ref="textarea"
		class="mdc-text-field mdc-text-field--outlined mdc-text-field--textarea"
	>
		<span class="mdc-notched-outline">
			<span class="mdc-notched-outline__leading" />
			<span class="mdc-notched-outline__notch">
				<span
					class="mdc-floating-label"
					:id="$attrs.id"
				>{{ label }}</span>
			</span>
			<span class="mdc-notched-outline__trailing" />
		</span>
		<span class="mdc-text-field__resizer">
			<textarea
				:required="required"
				class="mdc-text-field__input"
				:aria-labelledby="$attrs.id"
				:rows="$attrs.rows"
				:cols="$attrs.cols"
				:maxlength="$attrs.maxlength"
				@input="$emit('update:modelValue', $event.target.value)"
			/>
		</span>
	</label>
</template>
<script>
export default {
	props:{
		required: {
			type: Boolean,
			default: () => false,
		},
		label:{
			type: String,
			default: null
		},
		modelValue: {
			type: [String, Number],
			default: () => "",
		},
	},
	watch: {
		modelValue: {
			handler(newVal) {
				this.textField && (this.textField.value = newVal || "");
			},
		},
	},
	emits: ["update:modelValue"],
	mounted(){
		var MDCTextField = window.mdc.textField.MDCTextField;
		this.textField = new MDCTextField(this.$refs.textarea);
		if (this.modelValue) {
			this.textField.value = this.modelValue
		}
	}
}
</script>
<style lang="">
    
</style>