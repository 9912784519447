<template lang="">
	<div
		ref="matDatepicker"
		class="position-relative w-100"
		v-bind="$attrs"
	>
		<div class="d-flex">
			<MatInput
				readonly
				icon="date_range"
				:model-value="formattedStartDate"
				:label="label"
				:dense="dense"
				@click="showCalendar"
				:required="required"
				aria-required="true"
				class="me-2 "
				left
			/>
			<MatInput
				v-if="range"
				readonly
				icon="date_range"
				:model-value="formattedEndDate"
				:dense="dense"
				@click="showCalendar"
				:required="required"
				aria-required="true"
				left
			/>
		</div>
	</div>
	<MatMenu
		ref="menu"
		:anchor-to="anchor"
		class="mdc-datepicker bulma"
	>
		<input ref="bulmaCalendarInput">
	</MatMenu>
</template>

<script>
import MatInput from "./mat-input.vue";
import MatMenu from "./mat-menu.vue";
const bulmaCalendar = () => import("bulma-calendar/src/js");
export default {
	components: { MatInput, MatMenu },
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		range: {
			type: Boolean,
			default: false,
		},
		options: {
			type: Object,
			default() {
				return {
					lang: "fr",
					showFooter: false,
					icons: {
						next: `<span class="material-icons">navigate_next</span>`,
						previous: `<span class="material-icons">navigate_before</span>`,
					},
				};
			},
		},
		type: {
			type: String,
			default: "datetime",
		},
		modelValue: {
			type: [String, Date, Array, null],
			default: null
		},
		label: {
			type: [Boolean, String],
			default: () => false,
		},
		dense: Boolean,
		required: {
			type: Boolean,
			default: () => false,
		},
		disabledDates: {
			type: Function,
			default: () => () => this.initCalendar
		},
		monthPicker: {
			type: Boolean,
			default: () => false,
		},
	},
	computed: {
		formattedStartDate() {
			let format = 'DD/MM/YYYY';
			if (this.monthPicker) format = 'MM/YYYY';
			if (this.date[0]) return this.$filter.format(this.date[0], format);
			return format;
		},
		formattedEndDate() {
			let format = 'DD/MM/YYYY';
			if (this.monthPicker) format = 'MM/YYYY';
			if (this.date[1]) return this.$filter.format(this.date[1], format);
			return format;
		}
	},
	emits: ['update:modelValue'],
	data() {
		return {
			date: [null, null],
			anchor: null,
		};
	},
	watch: {
		date() {
			//this.date[0].setHours(12)
			this.$emit("update:modelValue", Array.isArray(this.modelValue) ? this.date : this.date[0]);
		},
		modelValue: {
			handler() {
				if (!this.$utils.equals(this.date, this.modelValue))
					Array.isArray(this.modelValue) ? this.date = this.modelValue : this.date[0] = this.modelValue;
			},
			immediate: true,
		}
	},
	async mounted() {
		this.calendar = (await bulmaCalendar()).default
		this.initCalendar();
	},
	methods: {
		showCalendar() {

			this.$refs.menu.open();

			if (this.monthPicker) { this.openMonthPicker() }


		},
		initCalendar() {
			this.anchor = this.$refs.matDatepicker;
			if (this.range) {
				if (this.modelValue instanceof Array) {
					this.date = this.modelValue;
				}
				// if (!this.date[0]) this.date[0] = new Date();
				// if (!this.date[1]) this.date[1] = new Date();
			} else {
				if (this.modelValue) {
					this.date[0] = Array.isArray(this.modelValue)
						? this.modelValue[0]
						: this.modelValue;
				}
			}
			this.date[0] && (this.date[0] = this.$moment(this.date[0]).toDate())
			this.date[1] && (this.date[1] = this.$moment(this.date[1]).toDate())
			// Attach Calendar
			const calendar = this.calendar.attach(
				this.$refs.bulmaCalendarInput,
				this.calendarOptions()
			);
			this.calendar = calendar[0];
			//if (this.monthPicker) {this.openMonthPicker()}
			this.calendar.on("select", (e) => {
				if (this.range) {
					this.date = [e.data.startDate, e.data.endDate];
				} else {
					this.date = [e.data.startDate];
				}

				this.$refs.menu.hide();
			});

			this.calendar.on("clear", () => {
				if (this.range) {
					this.date = [null, null];
				} else {
					this.date = [null];
				}
			});
		},
		calendarOptions() {
			return {
				...this.options,
				// showHeader: false,
				showClearButton: false,
				showValidateButton: false,
				showTodayButton: false,
				displayMode: "inline",
				isRange: this.range,
				startDate: this.date[0],
				startTime: this.date[0],
				endDate: this.date[1],
				endTime: this.date[1],
				// disabledDates: (_, day) => {
				// 	//return this.date[0]? day.getTime() < this.date[0].getTime() :false
				// }
			};
		},

		openMonthPicker() {
			if (this.monthPicker) {
				const yearPickerElement = this.calendar.datePicker._ui.navigation.year;
				yearPickerElement.click()
				const years = this.calendar.datePicker._ui.body.years.querySelectorAll('.datepicker-year') || [];
				years.forEach((year) => {
					year.addEventListener("click", (e) => this.getYear(e), { once: true });
				});
			}
		},
		getYear(e) {
			this.selectedYear = e.currentTarget.dataset.year;
			this.calendar.datePicker._ui.navigation.month.click();
			const month = this.calendar.datePicker._ui.body.months.querySelectorAll('.datepicker-month') || [];
			month.forEach((month) => {
				month.addEventListener("click", (e) => {
					this.getMonth(e)
				}, { once: true });
			});
		},
		getMonth(e) {
			//const selectedYear = e.currentTarget.dataset.year; // Get the year from the clicked element
			const selectedMonth = e.currentTarget.dataset.month; // Get the month from the clicked element
			// Update this.date with the selected year and month
			this.$refs.menu.hide();
			if (this.selectedYear && selectedMonth) {
				const formattedDate = `${this.selectedYear}-${selectedMonth}-01`;
				this.date = [formattedDate];
			}

		},

	},
};
</script>
<style lang="scss">
.mdc-datepicker {
	z-index: 1000000;

	*,
	*::after,
	*::before {
		box-sizing: border-box;
	}

	.timepicker {
		display: none;
	}

	.datetimepicker.is-primary .datetimepicker-header .datetimepicker-selection-day {
		color: var(--primary-color);
		min-width: fit-content;
	}

	.datetimepicker.is-primary .datepicker-body .datepicker-dates .datepicker-days .datepicker-date .date-item.is-active {
		background: var(--primary-color);
		border-color: var(--primary-color);
	}

	.datetimepicker.is-primary .datepicker-body .datepicker-dates .datepicker-days .datepicker-date .date-item.is-today {
		border-color: var(--primary-color);
		color: var(--primary-color);
	}

	.datetimepicker.is-primary .datepicker-body .datepicker-dates .datepicker-days .datepicker-date .date-item:hover {
		border-color: var(--primary-color);
	}

	.datetimepicker.is-primary .datepicker-body .datepicker-dates .datepicker-days .datepicker-date.datepicker-range {
		background: var(--primary-color);
	}

	.datetimepicker.is-primary .datepicker-body .datepicker-dates .datepicker-days .datepicker-date.datepicker-range .date-item {
		background-color: var(--primary-color);
		color: #fff;
	}

	.datetimepicker.is-primary .datepicker-nav {
		background: var(--primary-color);
		color: #fff;
	}

	.has-text-success {
		color: var(--primary-color) !important;
	}

	.has-text-warning {
		color: var(--primary-color-dark) !important;
	}

	.button.is-text:active,
	.button.is-text.is-active {
		background-color: unset;
		color: unset;
	}

	.datetimepicker .datetimepicker-header {
		display: none;
	}

	.button.is-text {
		text-decoration: none !important;
		color: white
	}
}
</style>
