
const isBrowser = typeof(window) !== "undefined"
const unique = {
	title: true,
	meta: 'name'
};
const createHead = () => {
	const config = {
		install: (app) => {
			app.config.globalProperties.$useHead = config.useHead
		},	
		useHead: isBrowser ? (tags, _, headers) => {
			if(tags && tags.title)
				document.title = tags.title;
			if(headers && headers.location)
				location.href = headers.location;
		} : (tags, code, headers) => {
			if(tags) for(const name in tags) {
				const array = Array.isArray(tags[name]) ? tags[name] : [tags[name]];
				for(const newTag of array) {
					if(!config.tags[name] || unique[name] === true) {
						config.tags[name] = newTag; // set or overrite same tag
						continue;
					}
					if(!Array.isArray(config.tags[name]))
						config.tags[name] = [config.tags[name]];
					if(unique[name]) {
						const uniqueKey = unique[name];
						const uniqueValue = newTag[uniqueKey];
						if(uniqueValue) {
							const match = config.tags[name].findIndex(existing => existing[uniqueKey] == uniqueValue);
							if(match >= 0) {
								// overrite same tag with matching key
								config.tags[name][match] = newTag;
								continue;
							}
						}
					}
					config.tags[name].push(newTag); // add another instance of the tag
				}
			}
			if(headers) for(const k in headers) {
				const name = k.split('-')
					.map(part =>
						part[0].toUpperCase() + part.substring(1).toLowerCase())
					.join('-');
				const value = '' + headers[k];
				config.headers[name] = value;
			}
			if(Number.isInteger(code)) config.code = code;
		},
		tags: {},
		headers: {}
	};
	return config
}

export default createHead
