export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salut i18n !!............"])},
  "accueil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
  "hOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos offres"])},
  "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offres"])},
  "oneOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une offre publiée"])},
  "oneOfferRech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une offre publiée correspondent à votre recherche"])},
  "noOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune offre n'est publiée, n'hésitez pas à déposer une candidature spontanée"])},
  "noOfferRech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune offre trouvée, n'hésitez pas à changer les filtres ou déposer une candidature spontanée "])},
  "positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["postes"])},
  "onePosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["poste"])},
  "noPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun poste"])},
  "voirTO": {
    "sm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOUTES OFFRES"])},
    "lg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOIR TOUTES NOS OFFRES"])}
  },
  "siteWeb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voir le site web"])},
  "iTravaille": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous souhaitez travailler chez nous ?"])},
  "iTravailleSubtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez ci-dessous !"])},
  "envoiCandid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez nous votre candidature spontanée !"])},
  "spontanee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANDIDATURE SPONTANÉE"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les offres les plus récentes"])},
  "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VOIR TOUTES NOS OFFRES"])},
  "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pourriez être intressé par"])},
  "clientWebsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site web _"])},
  "Mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions légales"])},
  "Police": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
  "nbRecherche": {
    "plur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offres d’emploi correspondent à votre recherche"])},
    "Publiee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["offres publiées "])}
  },
  "recherch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
  "offEmploi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres d'emploi"])},
  "searchPlaceholder": {
    "sm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
    "lg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])}
  },
  "jPostule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je postule"])},
  "Publiée": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiée le "])},
  "envi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’environnement"])},
  "poste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le poste"])},
  "profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil recherché"])},
  "ans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
  "resultat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liste des offres"])},
  "pre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["précédent"])},
  "suiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suivant"])},
  "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["localisations"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["autre(s)"])},
  "countrie.s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pays"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
  "contract": {
    "permanent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDI"])},
    "fixed-term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDD"])},
    "apprenticeship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternance"])},
    "internship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage"])},
    "civic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service civique"])},
    "fr-vie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIE"])},
    "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indépendant"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation"])},
    "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
    "public-permanent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanent"])},
    "public-non-permanent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non permanent"])},
    "plusieur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plusieurs types de contrats"])},
    "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "education": {
    "-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun diplôme"])},
    "-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAP, BEP, Bac pro"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baccalauréat"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+2, BTS"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+3, Bachelor, Licence, BUT"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+4, Master 1"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+5, Master, Ingénieur"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+8, Doctorat"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecin"])},
    "2,2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+2, BTS"])},
    "3,3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+3, Bachelor, Licence, BUT"])},
    "4,4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+4, Master 1"])},
    "4,5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+4, Master 1"])},
    "5,5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+5, Master, Ingénieur"])},
    "8,8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+8, Doctorat"])},
    "9,9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecin"])}
  },
  "tt": {
    "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternance télétravail / sur site"])},
    "occasional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télétravail toléré ponctuellement"])},
    "forbidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun télétravail possible"])},
    "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télétravail total"])}
  },
  "Localisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation"])},
  "Contrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat"])},
  "Télétravail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télétravail"])},
  "Formation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation"])},
  "Expérience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expérience"])},
  "Auto-Complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-Complete"])},
  "mail-phone": {
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail / Téléphone"])},
    "deux": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre adresse email et votre numéro de téléphone"])},
    "mail-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre adresse email"])},
    "phone-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre numéro de téléphone"])},
    "lun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre adresse mail et votre téléphone"])},
    "coop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir les coordonnées du candidat proposé"])}
  },
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J’accepte "])},
  "cond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" les conditions d'utilisation et la politique de confidentialité"])},
  "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les conditions d’utilisation et la politique de confidentialité"])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
  "debut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début"])},
  "mCondid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de candidature"])},
  "mCandidtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez et laissez-vous guider"])},
  "importMan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'ai pas de CV"])},
  "importCv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai un CV"])},
  "teleCv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger mon CV"])},
  "teleCvCoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le CV du candidat proposé"])},
  "docTele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV téléchargé"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger ou faire glisser le CV ici"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger ou faire glisser le document ici"])},
  "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposer ici"])},
  "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format supportés: pdf, doc(x), odt, rtf, jpeg, png"])},
  "miseAjour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé il y a une minute"])},
  "mj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "modifierFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacer par un autre fichier"])},
  "pupload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi télécharger mon CV ?"])},
  "uploadText": {
    "text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature rapide"])},
    "text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postuler facilement depuis mon mobile"])},
    "text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuls les recruteurs de cette platforme peuvent consulter mon cv."])}
  },
  "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["retour"])},
  "infoGen": {
    "graphText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles"])},
    "formText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez vos informations personnelles"])},
    "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "prenom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "naissance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "ville": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "cp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année"])}
  },
  "genders": {
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masculin"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Féminin"])},
    "undisclosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non renseigné"])}
  },
  "formulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire"])},
  "steps": {
    "1CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 CV"])},
    "4CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 CV"])},
    "3Formulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Formulaire"])},
    "3Informations_personelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Informations personelles"])},
    "2Informations_personelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Informations personelles"])},
    "1Informations_personelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Informations personelles"])},
    "2Experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Expérience(s)"])},
    "3Formation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Formations"])},
    "4Compétences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Compétences"])},
    "5Formulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 Formulaire"])},
    "6Validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 Validation"])},
    "5Validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 Validation"])},
    "6Formulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 Formulaire"])},
    "4Formulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Formulaire"])},
    "1Formulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Formulaire"])},
    "1Informations_candid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Informations candidat"])}
  },
  "exp": {
    "graphText": {
      "Experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expériences"])},
      "text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez par les postes les plus récents"])},
      "text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentionnez les dates de début et de fin de chaque poste"])}
    },
    "formText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez votre parcours professionnel"])},
    "formTextVide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé du poste"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une expérience"])},
    "maintenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maintenant"])},
    "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé du poste"])},
    "entreprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l’entreprise"])},
    "debut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
    "fin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
    "postActuelle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J’occupe ce poste actuellement"])},
    "descp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez votre expérience professionnelle"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" à "])},
    "du": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" du "])},
    "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" pendant "])},
    "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" depuis "])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" et "])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])}
  },
  "edc": {
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'est ma formation actuelle"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau de formation"])},
    "no_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun diplôme"])},
    "cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAP, BEP, Brevet"])},
    "bac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAC"])},
    "bac+1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAC +1"])},
    "bac+2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAC +2"])},
    "bac+3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAC +3"])},
    "bac+4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAC +4"])},
    "bac+5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAC +5"])},
    "bac+6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAC +6"])},
    "bac+7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAC +7"])},
    "bac+8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAC +8"])},
    "bac+9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAC +9"])},
    "bac+10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAC +10"])},
    "bac+11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAC +11"])},
    "bac+12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAC +12"])}
  },
  "formation": {
    "graphText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation"])},
    "formText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez votre formation"])},
    "formTextVide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du diplôme"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une formation"])},
    "deplome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du diplôme"])},
    "eteb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année d'obtention"])},
    "lieu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])},
    "descp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez votre diplome"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" à "])},
    "du": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" du "])}
  },
  "comp": {
    "graphText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compétences"])},
    "formText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez vos compétences"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compétences techniques"])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compétences"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langues"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soft skills"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AJOUTER NIVEAU DE COMPÉTENCE"])}
  },
  "validateAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je souhaite recevoir des offres similaires par e-mail"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir aux résultats de recherche"])},
  "doneTous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrir nos offres"])},
  "doneText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous confirmons que votre candidature a été transmise à notre équipe de recrutement et sera traitée dans les plus brefs délais."])},
  "doneTextCoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous confirmons que votre proposition a été transmise à notre équipe de recrutement et sera traitée dans les plus brefs délais."])},
  "doneTextError": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, un problème technique a empêché votre candidature d'être envoyée au recruteur :("])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous invitons à réessayer un peu plus tard et vérifier votre saisie."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes nos excuses pour ce contre-temps. Dans tous les cas, vos données de contact ont été sauvegardées."])},
    "1Coop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, un problème technique a empêché votre proposition d'être envoyée au recruteur :("])}
  },
  "doneTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre candidature a bien été envoyée"])},
  "doneTitleCoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre proposition a bien été envoyée"])},
  "doneTitleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'envoi de la candidature"])},
  "doneTitleErrorCoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'envoi de la proposition"])},
  "soumettreCandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["soumettre ma candidature"])},
  "soumettreCoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["soumettre ma proposition"])},
  "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours d'envoi..."])},
  "plusieursLoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plusieurs localisations"])},
  "ilya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a "])},
  "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" minutes"])},
  "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" heures"])},
  "jr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" jours"])},
  "hier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier"])},
  "renisialiser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réinitialiser"])},
  "aujourdhui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
  "countries": {
    "algeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algérie"])},
    "angola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "benin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénin"])},
    "botswana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "burkina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina Faso"])},
    "burundi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "cameroon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameroun"])},
    "cape verde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cap vert"])},
    "central african republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République centrafricaine"])},
    "chad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tchad"])},
    "comoros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comores"])},
    "congo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo"])},
    "congo, democratic republic of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République démocratique du Congo"])},
    "djibouti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
    "egypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egypte"])},
    "equatorial guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinée équatoriale"])},
    "eritrea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erythree"])},
    "ethiopia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethiopie"])},
    "gabon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "gambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambie"])},
    "ghana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinée"])},
    "guinea-bissau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinée-Bissau"])},
    "ivory coast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côte d’Ivoire"])},
    "kenya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
    "lesotho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "liberia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
    "libya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libye"])},
    "madagascar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
    "malawi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "mali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "mauritania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritanie"])},
    "mauritius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ile Maurice"])},
    "morocco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maroc"])},
    "mozambique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
    "namibia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibie"])},
    "niger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "nigeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
    "rwanda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "sao tome and principe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao Tomé-et-Principe"])},
    "senegal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sénégal"])},
    "seychelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "sierra leone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "somalia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalie"])},
    "south africa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afrique du Sud"])},
    "south sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soudan du Sud"])},
    "sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soudan"])},
    "swaziland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swaziland"])},
    "tanzania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzanie"])},
    "togo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "tunisia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisie"])},
    "uganda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouganda"])},
    "zambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambie"])},
    "zimbabwe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])},
    "albania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albanie"])},
    "andorra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorre"])},
    "armenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arménie"])},
    "austria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autriche"])},
    "azerbaijan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijan"])},
    "belarus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biélorussie"])},
    "belgium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgique"])},
    "bosnia and herzegovina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnie-Herzégovine"])},
    "bulgaria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarie"])},
    "croatia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatie"])},
    "cyprus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chypre"])},
    "czech republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République Tchèque"])},
    "denmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danemark"])},
    "estonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonie"])},
    "finland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finlande"])},
    "france": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
    "georgia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgie"])},
    "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemagne"])},
    "greece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grèce"])},
    "hungary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongrie"])},
    "iceland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Islande"])},
    "ireland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irelande"])},
    "italy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italie"])},
    "latvia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettonie"])},
    "liechtenstein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "lithuania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lituanie"])},
    "luxembourg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
    "macedonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macédoine"])},
    "malta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malte"])},
    "moldova": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldavie"])},
    "monaco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
    "montenegro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays-Bas"])},
    "norway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norvège"])},
    "poland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pologne"])},
    "portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "romania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roumanie"])},
    "san marino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Marin"])},
    "serbia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbie"])},
    "slovakia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovaquie"])},
    "slovenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovénie"])},
    "spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagne"])},
    "sweden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suède"])},
    "switzerland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suisse"])},
    "ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
    "united kingdom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Royaume-Uni"])},
    "vatican city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatican"])},
    "antigua and barbuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua-et-barbuda"])},
    "bahamas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "barbados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Barbade"])},
    "belize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
    "costa rica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "cuba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "dominica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominique"])},
    "dominican republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République dominicaine"])},
    "el salvador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "grenada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenade"])},
    "guatemala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "haiti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "honduras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "jamaica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaique"])},
    "mexico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexique"])},
    "nicaragua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
    "panama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paname"])},
    "saint kitts and nevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint-Christophe et Niévès"])},
    "saint lucia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sainte Lucie"])},
    "saint vincent and the grenadines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Vincent et les Grenadines"])},
    "trinidad and tobago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad et Tobago"])},
    "united states": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les Etats Unis d'Amérique"])},
    "argentina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentine"])},
    "bolivia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivie"])},
    "brazil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brésil"])},
    "chile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chili"])},
    "colombia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombie"])},
    "ecuador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equateur"])},
    "guyana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyane"])},
    "paraguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "peru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perou"])},
    "suriname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
    "uruguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "venezuela": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "afghanistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
    "bahrain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrein"])},
    "bangladesh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "bhutan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhoutan"])},
    "brunei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
    "burma (myanmar)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birmanie (Myanmar)"])},
    "cambodia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambodge"])},
    "china": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chine"])},
    "east timor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timor oriental"])},
    "india": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inde"])},
    "indonesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonésie"])},
    "iran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
    "iraq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irak"])},
    "israel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
    "japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japon"])},
    "jordan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordanie"])},
    "kazakhstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakhstan"])},
    "korea, north": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corée du Nord"])},
    "korea, south": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corée du Sud"])},
    "kuwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koweit"])},
    "kyrgyzstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyrgyzstan"])},
    "laos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "lebanon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liban"])},
    "malaysia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaysie"])},
    "maldives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldives"])},
    "mongolia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolie"])},
    "nepal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Népal"])},
    "oman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
    "pakistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "palestine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palestine"])},
    "philippines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philippines"])},
    "qatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quatar"])},
    "russian federation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fédération Russe"])},
    "saudi arabia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabie Saoudite"])},
    "singapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapour"])},
    "sri lanka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "syria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syrie"])},
    "tajikistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajikistan"])},
    "thailand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thailande"])},
    "turkey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turquie"])},
    "turkmenistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
    "united arab emirates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emirats Arabes Unis"])},
    "uzbekistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouzbekistan"])},
    "vietnam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "yemen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yemen"])},
    "australia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australie"])},
    "fiji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
    "kiribati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["République de Kiribati"])},
    "marshall islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iles Marshall"])},
    "micronesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronésie"])},
    "nauru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "new zealand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle Zélande"])},
    "palau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "papua new guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papouasie Nouvelle Guinée"])},
    "samoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iles Samoa"])},
    "solomon islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iles Salomon"])},
    "tonga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "tuvalu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "vanuatu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])}
  },
  "devby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développé par"])},
  "Form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez compléter les informations ci-dessous afin de faciliter le traitement de votre candidature."])},
  "FormCoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez compléter les informations ci-dessous."])},
  "FormText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir le formulaire: "])},
  "fileError": {
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier est trop volumineux ! La taille ne doit pas dépasser 5Mo."])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format de fichier invalide. Formats supportés: pdf, doc(x), odt, rtf, jpeg, png."])},
    "invalidChar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du fichier invalide. Sont autorisés uniquement les caractères alphanumériques avec accents), les espaces et les symboles -,_ (hormis l'extension)"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'envoyer le fichier. Assurez-vous que la taille ne dépasse pas 5Mo, que le format soit supporté (pdf, doc(x), odt, rtf, jpeg, png) et que le nom ne contient pas des caractères spéciaux."])}
  },
  "CareerSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site emploi"])},
  "JobList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des offres d'emploi"])},
  "connecter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SE CONNECTER"])},
  "inscrit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'INSCRIRE"])},
  "addMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "confpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe"])},
  "confpasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe et le mot de passe de confirmation ne correspondent pas."])},
  "loginText": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souscrivez gratuitement à notre plateforme!!"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature rapides qui te permet de gagner le temps de resaisie de votre informations pour chaque candidature"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postuler facilement depuis votre mobile"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez vos candidatures"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyez visisble vis à vis des recruteurs"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérez vos alertes email et recevez chaque jour les nouvelles offres d’emploi qui vous correspondent"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrouvez vos offres sauvegardées"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription gratuite"])}
  },
  "noOffre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune offre disponible, supprimez des critères pour pouvoir filtrer"])},
  "signinEror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ou mot de passe incorrect"])},
  "signinerr_429": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action bloquée à cause de plusieurs tentatives consécutives, veuillez patienter avant de réessayer"])},
  "signupError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un compte existe déjà avec cette adresse, si c'est le votre, allez dans l'onglet connexion et suivez le lien mot de passe oublié si besoin"])},
  "newCandid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a bien été créé. Vous pourrez l'utiliser afin de suivre votre candidature."])},
  "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnecter"])},
  "connecterL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "inscritL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
  "validationExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, votre lien de validation est expiré."])},
  "validationInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, votre lien de validation est invalide."])},
  "passwordErrorRegex": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe invalide. Veuillez vous assurer que votre mot de passe répond aux exigences suivantes: "])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins une majuscule"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un chiffre"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un caractère spécial"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur minimale de 8 caractères"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins une minuscule"])}
  },
  "forgetPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
  "forgetPwText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser "])},
  "mpactuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
  "nvpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "changepassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer Mot de passe"])},
  "vCompte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte vérifié"])},
  "vrCompte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer le lien de vérification"])},
  "avCompte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de vérification"])},
  "param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "modifMp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le mot de passe"])},
  "candidatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidatures"])},
  "tous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
  "suivi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi"])},
  "monCompte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Compte"])},
  "Envoyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé"])},
  "Encours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
  "Cloturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôturée"])},
  "etatProg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etat de progression"])},
  "pasCandid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez envoyé aucune candidature pour le moment."])},
  "resetpasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s’est produite. Merci de réessayer ultérieurement. Si le problème persiste, veuillez contacter le support"])},
  "resetPasswordSucces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un mail de réinitialisation vous a été envoyé par mail."])},
  "forgetMp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
  "connectedTanque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes connecté en tant que  "])},
  "envCand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer ma candidature"])},
  "modCand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier ma candidature"])},
  "candiSansCompte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidater sans compte"])},
  "connexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "creer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jour"])},
  "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heure"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an"])},
  "chargement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un instant..."])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "invalidForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien saisie est invalide. Assurez-vous d'avoir copier-coller l'intégralité des caractères du lien."])},
  "expiredForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien a expiré"])},
  "errorpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite. Veuillez contacter le support technique."])},
  "formsend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos réponses ont déjà été envoyées."])},
  "formsentresp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les réponses ont été enregistrées avec succès."])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "coopInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations candidat"])},
  "coopInfoPer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles du candidat proposé"])},
  "captcha_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je ne suis pas un robot"])},
  "captcha_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La verification a échoué."])},
  "captcha_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vérification a expiré. Veuillez réessayer."])},
  "captcha_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifié"])},
  "captcha_verifying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification en cours..."])},
  "captcha_waitAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification bientôt terminée"])}
}