<template>
	<div>
		<span>
			<div class="bg-white container wh-100">
				<div class="row pe-0 pe-lg-3">
					<div class="px-5 pt-3 d-none d-lg-block col-3 background">
						<section class="p-3 spaced noborder">
							<span class="d-flex">
								<router-link
									:to="{ name: ROUTES_NAME.USERSUIVI, params: { config: 'candidature' }, query: { type: 'Tous' } }"
									class=" d-flex flex-row  flex-vertical-center leftDecoLine"
								>
									<span class="material-icons-outlined fs-5 me-3">
										account_circle
									</span> <span>{{ $t(`candidatures`) }} </span>

								</router-link>
								<!--
									<MatIconButtonToggle
									ref="candidatures"
									onIcon="expand_less"
									offIcon="expand_more"
									class="material-icons d-flex text-primary ms-2"
								/>
								-->
							</span>
							<BsCollapsible
								:trigger="() => $refs.candidatures"
								:open="false"
							>
								<router-link
									:to="{ name: ROUTES_NAME.USERSUIVI, params: { config: 'candidature' }, query: { type: 'Tous' } }"
									class=" d-flex flex-row  flex-vertical-center  ms-1 mb-2 text-decoration-none "
									:class="{ 'leftDecoLine': $route.name == 'UserSuivi' && $route.query.type == 'Tous' }"
								>
									<span>{{ $t(`tous`) }} </span>

								</router-link>
								<!--	<router-link
									:to="{ name: ROUTES_NAME.USERSUIVI, params: { config : 'candidature'},query:{ type :'Recquise'} }"
									class=" d-flex flex-row  flex-vertical-center ms-1 mb-2 text-decoration-none "
									:class="{ 'leftDecoLine': $route.name == 'UserSuivi' && $route.query.type=='Recquise' }"
								> <span>ACTION RECQUISE </span>
									
								</router-link> 
								<router-link
									:to="{ name: ROUTES_NAME.USERSUIVI, params: { config : 'candidature'},query:{ type :'Traitement'} }"
									class=" d-flex flex-row  flex-vertical-center ms-1 mb-2 text-decoration-none "
									:class="{ 'leftDecoLine': $route.name == 'UserSuivi' && $route.query.type=='Traitement' }"
								> <span>EN COURS DE TRAITEMENT </span>
									
								</router-link> 
								<router-link
									:to="{ name: ROUTES_NAME.USERSUIVI, params: { config : 'candidature'},query:{ type :'Archicées'} }"
									class=" d-flex flex-row  flex-vertical-center ms-1 mb-2 text-decoration-none "
									:class="{ 'leftDecoLine': $route.name == 'UserSuivi' && $route.query.type=='Archicées' }"
								> <span>ARCHIVÉES </span>
								</router-link>-->

							</BsCollapsible>

							<!--<span class="d-flex">
								<router-link
									:to="{ name: ROUTES_NAME.USERSUIVI, params: { config : 'favoris'} }"
									class=" d-flex flex-row flex-vertical-center leftDecoLine mb-2"
								>
									<span class="material-icons-outlined fs-5 me-3">
										bookmark_border
									</span> 
									<span>	Favoris et suggestions
									</span> 
								</router-link>
								<MatIconButtonToggle
									ref="educationExpand"
									onIcon="expand_less"
									offIcon="expand_more"
									class="material-icons d-flex text-primary"
								/>
							</span>
							<BsCollapsible
								:trigger="() => $refs.educationExpand"
								:open="false"
							/>					-->
						</section>
					</div>
					<div class="col col-lg-9 position-relative p-0 mt-5 pb-5">
						<!-- Menu  
						<div
							class="d-flex align-items-end my-3"
							style="box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);height: 50px;top: 91px;width:100%"
						>
							<router-link
								:to="{ name: $route.name, params: { config: 'candidature' } }"
								class="flex-fill noTextDeco"
							>
								<TabButton
									class="tabs"
									name="t1"
									:class="{ active: view == 'userSuivi' }"
								>
									<span class="tabTitle">Candidature</span>
								</TabButton>
							</router-link>
						</div>-->
						<template
							v-if="$route.name == ROUTES_NAME.USERSUIVI && $route.params.config == 'candidature' && $route.query.type == 'Tous'"
						>
							<OfferRow />
						</template>

					</div>
				</div>
			</div>
		</span>
	</div>
</template>
<script>
import { ROUTES_NAME } from "@/router"
import OfferRow from "@/components/application/offer-row.vue";

export default {
	components: {
		OfferRow
	},
	data() {
		return {
			ROUTES_NAME,
			view: "favoris",
			application: null

		}
	},
	watch: {
		$route() {
			this.view = this.$route.params.config
		},
	},
	async mounted() {
		if (this.$route.params.config == "candidature" && (!this.$route.query.config || !this.$route.query.type)) this.$router.push({
			name: ROUTES_NAME.USERSUIVI,
			params: { config: "candidature" },
			query: { type: "Tous" }
		});


	},
}
</script>
<style scoped lang="scss">

.wh-100{
	min-height: 100vh;
}</style>