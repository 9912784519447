
const searchConfig = {
	recent: [{
		id: 3131785257,
		list: [
			{id: 3949872880,label: "UX/UI designer", type: "sector"},
			{id: 3949872880,label: "Fullstack developer", type: "sector"},
			{id: 250573306,label: "Lyon", type: "location"}
		], 
		hint: "Une semaine"
	},{
		id: 3076129330,
		list: [
			{id: 1751070620,label: "Banque", type: "sector"},
			{id: 4161725895,label: "Distribution", type: "sector"},
			{id: 123,label: "France", type: "location"}
		], 
		hint: "2 semaines"
	},
	],
	location: [{
		id: 123,
		label: "France",
		list:[
			{
				id:1731925042,
				label: "Auvergne-Rhome-Alpe",
				count: 2,
				list: [
					{
						id: 250573306,
						label: "Lyon",
						count: 2
					},
					{
						id: 3623614746,
						label: "Saint-etienne",
						count: 2
					},
					{
						id: 3463367306,
						label: "Grenoble",
						count: 2
					}
				]
			},
			{
				id:400431460,
				label: "Bourgiogne-Franche-Comté",
				count: 2
			},
			{
				id:312130212,
				label: "Grand Est",
				count: 2
			},
			{
				id:3977215281,
				label: "Haut-de-France",
				count: 2
			},
		],
		count: 12
	},{
		id: 4161725895,
		label: "Belgique",
		count: 12
	},{
		id: 2317732337,
		label: "Paris",
		count: 12
	},{
		id: 4204710613,
		label: "Lyon",
		count: 12
	}],
	contract: [
		{id: 668303553, label: "CDD", count: 40},
		{id: 1624430973, label: "CDI", count: 40},
		{id: 3420311304, label: "Alternance", count: 40},
		{id: 2626789413, label: "Sous traitance", count: 40},
	],
	sector: [
		{
			id: 4161725895,
			label: "Commerce / Distribution",
			count: 12,
			list:[
				{id: 668303553, label: "Commerce", count: 6},
				{id: 1624430973, label: "Distribution", count: 6},
			]
		},{
			id: 2317732337,
			label: "Banque / Asuurance / Finance",
			count: 18,
			list:[

				{id: 1751070620, label: "Banque", count: 6},
				{id: 3213098720, label: "Asuurance", count: 6},
				{id: 2531715253, label: "Finance", count: 6},
			]
		}
	],
	specialty: [
		{
			id: 4161725895,
			label: "Commerce / Distribution",
			count: 12,
			list:[
				{id: 668303553, label: "Commerce", count: 6},
				{id: 1624430973, label: "Distribution", count: 6},
			]
		},{
			id: 2317732337,
			label: "Banque / Asuurance / Finance",
			count: 18,
			list:[

				{id: 1751070620, label: "Banque", count: 6},
				{id: 3213098720, label: "Asuurance", count: 6},
				{id: 2531715253, label: "Finance", count: 6},
			]
		}
	],
	minSalary: {
		min:0,
		max:100000,
		default: 30000,
		step:5000,
		count:542,
		hint: (value) => value == 0 ? "Pas de salaire minimum" : `${value} € / an minimum`
	}

}

export default {
	getSearchConfig(){
		return searchConfig;
	}
}