<template lang="html">
	<div class="d-flex">
		<button
			ref="btn1"
			class="flex-center border rounded-start"
			:title="config?.button1?.title"
			@click="select(config?.button1?.name)"
		>
			<span class="mdc-ripple-surface" />
			<span
				class="material-icons"
				:class="[
					value == config?.button1?.name ? 'text-primary' : 'text-secondary',
				]"
			>
				{{ config?.button1?.icon }}
			</span>
		</button>
		<button
			ref="btn2"
			class="flex-center border rounded-end"
			:title="config?.button2?.title"
			@click="select(config?.button2?.name)"
		>
			<span class="mdc-ripple-surface" />
			<span
				class="material-icons"
				:class="[
					value == config?.button2?.name ? 'text-primary' : 'text-secondary',
				]"
			>
				{{ config?.button2?.icon }}
			</span>
		</button>
	</div>
</template>
<script>
export default {
	props: {
		config: {
			type: Object,
			default: () => ({
				button1: {
					name: "rows",
					icon: "view_agenda",
					title: "Vue en ligne",
				},
				button2: {
					name: "columns",
					icon: "apps",
					title: "Vue en colonne",
				},
			}),
		},
		modelValue: { type: String, default: () => "rows" },
	},
	emits: ["update:modelValue", "change"],
	data() {
		return {
			value: null,
		};
	},
	watch: {
		modelValue: {
			handler() {
				this.value = this.modelValue;
			},
			immediate: true,
		},
	},
	mounted() {
	},
	methods: {
		select(name) {
			const changed = this.value != name;
			this.value = name;
			this.$emit("update:modelValue", this.value);
			changed && this.$emit("change", this.value);
		},
	},
};
</script>
<style lang="scss" scoped>
button {
	background: white;
	border: border 1px var(--app-background);
	height: 40px !important;
	width: 40px !important;
	position: relative;
	padding: 0;
	overflow: hidden;
}
</style>
