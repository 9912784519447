<template>
	<div
		v-for="(c, index) in config"
		:key="c"
	>
		<Transition
			:name="step >= oldStep ? 'slide-up' : 'slide-down'"
			class="d-none d-lg-block"
			mode="out-in"
		>
			<div
				v-if="index == step"
				class="position-absolute top-0 start-0 w-100 h-100"
			>
				<component
					:is="c.graphComponent"
					:showtext="showtext"
				/>
			</div>
		</Transition>
	</div>
</template>
<script>
export default {
	props: {
		config: {
			type: Array,
			default: () => []
		},
		step: {
			type: Number,
			default: () => null
		},
		oldStep: {
			type: Number,
			default: () => 0
		},
		showtext: {
			type: Boolean,
			default: true
		},
	}
}
</script>