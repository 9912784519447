<template lang="">
	<canvas
		ref="chart"
		class="w-100 h-100"
	/>
</template>
<script>
export default {
	name: "ChartComponent",
	props:{
		data: {type: Object, default: () => {}},
		options:  {type: Object, default: () => {}},
		plugins: {type: Array, default: () => []},
		type: {type: String, default: () => 'doughnut'}
	},
	data(){
		return {
			chart: null
		}
	},
	mounted() {

		

		this.$watch(
			(vm) => [vm.data, vm.options, vm.plugins, vm.type],
			async () => {
				if(this.chart){
					return this.chart.update()
				}
				if(this.data){

					const config = {
						type: this.type,
						data: this.data,
						plugins: this.plugins,
						options: this.options
					};
					import("chart.js/auto").then(Chart => {
						this.chart = new Chart.default(this.$refs.chart, config)
					})
				}
			},
			{immediate: true}
		)
	},
}
</script>
<style lang="">
  
</style>