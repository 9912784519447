/** Circular progress bar, implemented as a custom element
 * using border-radius (two overlapping half-circles)
 * 
 * Tag: <cricle-progress>
 * 
 * Attributes:
 * 	o `progress`: float (0 to 1) or percent (0% to 100%) syntax
 * 
 * CSS custom variables:
 * 	o `--color`
 * 		color of the progress arc
 * 	o `--track-color`
 * 		color of the circle track (background for the progress arc)
 * 	o `--background-color`
 * 		color of the disk inside the progress circle
 * 		transparency is not supported
 * 	o `--track-margin`
 * 		additional thickness of background the circle track,
 * 		compared to the foreground progress arc
 * 		a value of zero may cause aliasing effects
 * 	o `--thickness`
 * 		thickness of the progress arc and its track
 * 		relative (percent) values are not supported
 **/
const css = `
:host { display: block }
div {
	box-sizing: border-box;
	position: absolute;
}
#container {
	width: 100%; height: 100%;
	position: relative;
	border-radius: 50%;
	--bg-clr: var(--background-color, white);
	--ring-clr: var(--track-color, var(--bg-clr));
	--arc-clr: var(--color, lightgray);
	--arc-width: var(--thickness, 3px);
	--arc-margin: var(--track-margin, 1px);
	--ring-width: calc(var(--arc-width) + 2 * var(--arc-margin));
}
.ring {
	border-radius: 50%;
	border-style: solid;
	border-color: var(--ring-clr);
}
#track {
	width: 100%; height: 100%;
	border-width: var(--ring-width);
}
.half, #rot {
	left: 50%; transform-origin: 0 50%;
	border-radius: 0 100% 100% 0 / 0 50% 50%;
	border-style: solid;
}
.fg {
	border-color: var(--arc-clr);
	border-width: var(--arc-width);
	width: calc(50% - var(--arc-margin));
	height: calc(100% - 2 * var(--arc-margin));
	top: var(--arc-margin);
}
.bg {
	border-color: var(--ring-clr);
	border-width: var(--ring-width);
	width: 50%;
	height: 100%;
	top: 0;
}
#inner {
	width: calc(100% - 2 * var(--arc-width));
	height: calc(100% - 2 * var(--arc-width));
	left: var(--arc-width);
	top: var(--arc-width);
	background: var(--bg-clr);
	border: solid var(--ring-clr) var(--arc-margin);
}
#progressValue {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 0.9em;
	color: var(--arc-clr);
  }
  `
// TODO: support innerHtml ?
const html = `
<div id="container">
	<div class="ring" id="track"></div>
	<div class="half fg"></div>
	<div id="rot" class="bg"></div>
	<div class="ring" id="inner"></div>
	<div id="progressValue"></div>

</div>`

function cleanProgress(p) {
	p = p.trim();
	if (!p) p = '0';
	if (p[p.length - 1] == '%') p = parseFloat(p.substring(0, p.length - 1)) / 100;
	else p = parseFloat(p);
	if (!p || p < 0) p = 0;
	else if (p > 1) p = 1;
	return p;
}

if (typeof window == 'undefined') {
	if (typeof HTMLElement == 'undefined')
		global.HTMLElement = class SSRDummy { };
	if (typeof customElements == 'undefined')
		global.customElements = { define: () => { } };
}
class CircleProgress extends HTMLElement {
	#p = 0
	#showValue = false;
	constructor() {
		super();
		const shadow = this.attachShadow({ mode: "open" });
		shadow.innerHTML = `<style>${css}</style>${html}`;
	}
	static get observedAttributes() { return ['progress'] }
	get progress() { return this.getAttribute('progress') || '0' }
	set progress(p) { this.setAttribute('progress', p) }
	get showValue() { return this.getAttribute('showvalue') === 'true'; }
	set showValue(value) {this.setAttribute('showvalue', value);}
	attributeChangedCallback(name, oldValue, p) {
		if (!this.isConnected) return;
		if (name != 'progress') return;
		p = cleanProgress(p);
		if (p == this.#p) return;
		this.#p = p;
		const rotated = this.shadowRoot.getElementById('rot');
		if (p > .5) {
			p -= .5;
			rotated.className = 'fg';
		} else {
			rotated.className = 'bg';
		}
		rotated.style.transform = `rotate(${p}turn)`;

		
		if (name === 'progress') {
			this.#showValue = this.showValue;
			const progressValueDiv = this.shadowRoot.getElementById('progressValue');
			progressValueDiv.style.display = this.#showValue ? 'block' : 'none';
	  
			// Update progress value only if showValue is true
			if (this.#showValue && this.#p !== null) {
			  const progressPercent = Math.round(this.#p * 100);
			  progressValueDiv.textContent = `${progressPercent}%`;
			}
		  }
	}
}
customElements.define("circle-progress", CircleProgress);