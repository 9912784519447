<template lang="">
	<div>
		<div
			class="advanced w-100 h-100 d-flex flex-row"
			:class="{show:show}"
		>
			<MatButton
				class=" mt-4 back ms-1  justify-content-start"
				icon="arrow_back_ios_new"
				@click="menuclose"
			/>
			<SearchInput
				v-model="selectedSearch"
				:stats="stats"
				class="w-90 h-100 mt-3 mb-5 ret"
				@menu-opened="openMenuResp"
				@menu-closed="closeMenuResp"
				:flatMenu="true"
				:inputSelection="false"
			/>
			<MatButton
				outlined
				class="post-resume pill position-fixed bottom-0 bg-white renisialiser ms-3 mb-3"
				@click="clear"
			>
				{{ $t(`renisialiser`) }}
			</MatButton>
			<MatButton
				raised 
				cta
				class="post-resume pill position-fixed bottom-0 end-0 me-3 mb-3"
				@click="menuclose"
			>
				{{ $t(`recherch`) }}
			</matbutton>
		</div>
		<div
			ref="offerHeader"
			class="offers-header overflow-hidden  d-none d-lg-block"
			:class="[$siteconf?.banner ? 'banner' : 'bg-primary' , menuOpened? 'menu-opened': '' ] "
		>
			<div
				ref="firstDiv"
				class="blur-background"
				v-if="$siteconf?.banner"
			/>
			<div
				class="position-relative header-content flex-column container-xxl"
			>
				<h1 class="h3 text-white text-center mb-5 d-none d-lg-block titleblur">
					{{ offersPageTitle }}
				</h1>
				<div class="flex-vertical-center w-100 px-lg-5">
					<div class="flex-max flex-vertical-center">
						<SearchInput
							v-model="selectedSearch"
							:stats="stats"
							class="w-75"
							@menu-opened="menuOpened = false"
							@menu-closed="menuOpened = false"
							@data="getStatusData"
						/>
					</div>

					<div class="flex-horizontal-center flex-column post-section me-5 d-none ">
						<div class="subtitle2 text-white mb-2">
							Simplifier votre recherche d'emploi
						</div>
						<MatButton
							ref="postResume"
							outlined
							class="post-resume pill bg-white"
						>
							déposer votre cv
						</MatButton>
					</div>
				</div>
			</div>
		</div>

		<div class="d-block d-lg-none h-100">
			<div
				ref="offerHeader"
				v-if="scrollpx<10"
				class="offers-header overflow-hidden"
				:class="[$siteconf?.banner ? 'banner' : 'bg-primary' , menuOpened? 'menu-opened': '' ] "
			>
				<div
					ref="firstDiv"
					class="blur-background"
					v-if="$siteconf?.banner"
				/>
				<div
					class="position-relative header-content flex-column container-xxl"
				>
					<h1 class="h3 text-white text-center d-block d-lg-none mb-3 titleblur">
						{{ $t(`recherch`) }}
					</h1>
					<div class="flex-vertical-center w-100 px-lg-5">
						<div class="flex-max flex-vertical-center">
							<SearchInput
								v-model="selectedSearch"
								:stats="stats"
								class=""
								@click="openMenuResp"
								:withMenu="false"
							/>
						</div>
					</div>
				</div>
			</div>
			<headerContainer
				title="Rechercher"
				type="nobg"
				v-if="scrollpx>=10"
				class=" position-fixed w-100 "
			/>
		</div>
		<div
			class="px-3 px-lg-5 mt-4 offers-body container-xxl mb-4"
			:class="{ 'menu-opened': menuOpened }"
		>
			<div
				class="row"
				style="margin-bottom: 90px;"
			>
				<div class=" ">
					<div
						class="flex-vertical-center"
					>
						<div
							class="subtitle1 flex-max "
							v-if="leadingOffer"
						>
							{{ $t('chargement') }}
						</div>
						<div
							class="subtitle1 flex-max "
							v-else
						>
							<div
								v-if="nbrOffres==0 && !leadingOffer"
								class="subtitle1 flex-max "
							>
								{{ selectedSearch.length==0? $t('noOffer') : $t('noOfferRech') }}
							</div>
							<div
								v-else-if="nbrOffres==1"
								class="subtitle1 flex-max "
							>
								{{ selectedSearch.length==0? $t('oneOffer') : $t('oneOfferRech') }}
							</div>
							<div
								v-else
								class="subtitle1 flex-max "
							>
								{{ nbrOffres }} {{ selectedSearch.length==0? $t('nbRecherche.Publiee') : $t('nbRecherche.plur') }}
							</div>
						</div>

						<MatButton
							:label="'tri par: ' + sort"
							icon="expand_more"
							trailingIcon
							class="d-none"
						/>
						<MatButton
							:label=" sort"
							icon="expand_more"
							trailingIcon
							class="d-none"
						/>
						<ButtonSwitcher
							class=" d-none d-lg-flex"
							@change="switchView"
							:modelValue="view"
						/>
					</div>
					<span :class="{ viewSection:true, cols: view=='columns'}">
						<div
							class="row mt-3 mt-lg-5 "
							id="col"
						>
							<div
								class="col-12 col-md-6 col-lg-4 mb-4 "
								v-for="item in offers"
								:key="item.id"
							>
								<OfferCard :offer="item" />
							</div>
						</div>
						<div
							id="row"
						>
							<OfferRow
								v-for="offer in offers"
								:key="offer.id"
								:offer="offer"
							/>
						</div>
						<span>
							<div>
								<ButtonPagination
									v-if="pages>=2"
									:nbPage="pages"
									@change="pagination"
								/>
							</div>
						</span></span>
				</div>
				<div class="col-3 position-relative d-none ">
					<section class="p-4 spaced-3">
						<section class="p-3 spaced position-relative">
							<h6 class="alert-title">
								Créer une alerte emploi
							</h6>
							<p class="body2 txt-secondary">
								Insérez votre adresse email et nous vous enverrons toutes les
								nouvelles offres d'emploi
							</p>
							<div class="text-center spaced">
								<input
									disabled
									class="news-letter border p-2"
									placeholder="Email"
								>
								<MatButton
									dense
									disabled
									icon="notification_important"
									label="Créer une alerte"
									trailing-icon
									raised
								/>
							</div>
						</section>
						<template v-if="!showMap">
							<p class="subtitle2">
								Localisations
							</p>
							<section
								class="map"
							>
								<Map
									:data="mapData"
									:config="mapCenter"
									@marker-click="onMarkerClick"
								/>
							</section>
						</template>
						<template v-if="!showContractsChart">
							<p class="subtitle2">
								Contrats
							</p>
							<section
								id="contract-section"
								class="bg-primary-light"
							>
								<ContractsChart
									:contracts="contracts"
									@chart-click="selectContract"
								/>
							</section>
						</template>
						<p class="subtitle2 d-none">
							Les offres consultés dernièrement
						</p>
						<section
							class="p-3 spaced d-none"
							v-if="display"
						>
							<div class="flex-vertical-center">
								<div class="recent-logo avatar p-2">
									<img
										v-if="$siteconf?.logo"
										class="w-100 h-100"
										alt="d4j logo"
										src="/s/images/logo_mini.png"
									>
								</div>
								<div class="ms-3">
									<div class="subtitle1">
										UX UI designer
									</div>
									<div class="subtitle1 txt-secondary">
										Data4job
									</div>
								</div>
							</div>

							<div class="flex-vertical-center">
								<div class="recent-logo avatar p-2">
									<img
										v-if="$siteconf?.logo"
										class="w-100 h-100"
										alt="d4j logo"
										src="/s/images/logo_mini.png"
									>
								</div>
								<div class="ms-3">
									<div class="subtitle1">
										UX UI designer
									</div>
									<div class="subtitle1 txt-secondary">
										Data4job
									</div>
								</div>
							</div>
							<div class="flex-vertical-center">
								<div class="recent-logo avatar p-2">
									<img
										v-if="$siteconf?.logo"
										class="w-100 h-100"
										alt="d4j logo"
										src="/s/images/logo_mini.png"
									>
								</div>
								<div class="ms-3">
									<div class="subtitle1">
										UX UI designer
									</div>
									<div class="subtitle1 txt-secondary">
										Data4job
									</div>
								</div>
							</div>
						</section>
						<p class="subtitle2 d-none">
							Les entreprises du moments
						</p>
						<section
							class="p-3 spaced d-none"
							v-if="display"
						>
							<div class="flex-vertical-center">
								<div class="popular-logo avatar p-1">
									<img
										v-if="$siteconf?.logo"
										class="w-100 h-100"
										alt="d4j logo"
										src="/s/images/logo_mini.png"
									>
								</div>
								<div class="ms-3 body2">
									Data4job
								</div>
							</div>
							<div class="flex-vertical-center">
								<div class="popular-logo avatar p-1">
									<img
										v-if="$siteconf?.logo"
										class="w-100 h-100"
										alt="d4j logo"
										src="/s/images/logo_mini.png"
									>
								</div>
								<div class="ms-3 body2">
									Data4job
								</div>
							</div>
							<div class="flex-vertical-center">
								<div class="popular-logo avatar p-1">
									<img
										v-if="$siteconf?.logo"
										class="w-100 h-100"
										alt="d4j logo"
										src="/s/images/logo_mini.png"
									>
								</div>
								<div class="ms-3 body2">
									Data4job
								</div>
							</div>
						</section>
					</section>
				</div>
			</div>
		</div>
		<div class="d-flex ms-2 d-lg-none indexButton pb-3 justify-content-center">
			<router-link
				position="static"
				class="me-2 "
				:to="{name:ROUTES_NAME.POST, params:{offerId:'0', name: 'candidature_spontanee'}}"
				v-if="$siteconf.spontaneous_app"
			>
				<MatButton
					raised
					class="me-0 me-lg-3 border"
					style="box-shadow: none;"
					:label=" $t(`spontanee`) "
				/>
			</router-link>
		</div>
	</div>
</template>
<script>
import SearchInput from "@/components/application/search-input.2.vue";
import OfferRow from "@/components/application/offer-row.vue";
import ButtonSwitcher from "@/components/application/button-switcher.vue";
import ButtonPagination from "@/components/application/button-pagination.vue";
import { ROUTES_NAME } from '@/router';
import ContractsChart from "@/components/application/contracts-chart.vue"
import Map from "@/components/application/map.vue"
import OfferCard from "@/components/application/offer-card.vue";
import headerContainer from "@/components/application/header-caintainer.vue"
import { pageTitle } from "@/utils/utils";

// const criterias = [
// 	{
// 		label: "Recherche recente",
// 		type: "selection",
// 		id: "_2874481597",
// 		elements: [
// 			{ id: "_2677893971", label: "UI Designer", count: 12049 },
// 			{ id: "_2689792695", label: "Lyon", count: 45 },
// 			{ id: "_2717061917", label: "CDI", count: 22 },
// 			{ id: "_4228337963", label: "UI Designer", count: 12049 },
// 			{ id: "_3514031010", label: "Lyon", count: 45 },
// 			{ id: "_745437643", label: "CDI", count: 22 },
// 			{ id: "_1593406474", label: "UI Designer", count: 12049 },
// 			{ id: "_2900950", label: "Lyon", count: 45 },
// 			{ id: "_3276888701", label: "CDI", count: 22 },
// 		],
// 	},
// 	{
// 		label: "Mot clés",
// 		type: "selection",
// 		id: "_1107550682",
// 		elements: [
// 			{ id: "_2934599614", label: "Junior", count: 55 },
// 			{ id: "_2924979123", label: "Start up", count: 65 },
// 			{ id: "_4024226637", label: "Congés", count: 87 },
// 			{ id: "_4272531702", label: "CAC 40", count: 21 },
// 		],
// 	},
// 	{
// 		label: "Contrats",
// 		type: "selection",
// 		id: "_110755065",
// 		elements: [
// 			{ id: "_2934594614", label: "Indépendant", count: 55 },
// 			{ id: "_2924971123", label: "CDI", count: 65 },
// 			{ id: "_4024223637", label: "CDD", count: 87 },
// 			{ id: "_4272537702", label: "Alternance", count: 21 },
// 			{ id: "_4272539702", label: "Stage", count: 25 },
// 		],
// 	},
// 	{
// 		label: "Localisations",
// 		type: "selection",
// 		id: "_129851691",
// 		elements: [
// 			{ id: "_4035610632", label: "Dijon", count: 23 },
// 			{ id: "_1603715621", label: "Paris", count: 44 },
// 			{ id: "_3633472187", label: "Rennes", count: 4 },
// 			{ id: "_1603719621", label: "Toulouse", count: 44 },
// 			{ id: "_3633482187", label: "Montluçon", count: 4 },
// 		],
// 	},
// 	{
// 		label: "Experience",
// 		type: "number",
// 		id: "_581726547",
// 		step: 1,
// 		min: 0,
// 		max: 5,
// 		default: 3
// 	},
// 	{
// 		label: "Age",
// 		type: "number",
// 		id: "_581756547",
// 		min: 15,
// 		max: 50,
// 		step: 5,
// 		default: 20
// 	},
// ];

export default {
	name: "OffersPage",
	components: {
		SearchInput,
		OfferRow,
		OfferCard,
		ButtonSwitcher,
		ContractsChart,
		Map,
		ButtonPagination,
		headerContainer
	},

	data() {
		return {
			display: false,
			ROUTES_NAME,
			sort: "pertinence",
			menuOpened: false,
			offers: this.$data.offers,
			map: null,
			//criterias,
			selectedSearch: [],
			primaryColor: "",
			mapData: [
				{ position: [47.931, -0.571], label: Math.ceil(Math.random() * 100), id: "_3633472187" },
				{ position: [43.405, 0.857], label: Math.ceil(Math.random() * 100), id: "_1603719621" },
				{ position: [46.347, 2.351], label: Math.ceil(Math.random() * 100), id: "_3633482187" },
				{ position: [48.836, 2.659], label: Math.ceil(Math.random() * 100), id: "_1603715621" },
				{ position: [47.145, 4.922], label: Math.ceil(Math.random() * 100), id: "_4035610632" },
			],
			showMap: true,
			showContractsChart: true,
			mapCenter: {
				center: [46.649, 2.856],
				zoom: 5
			},
			view: "row",
			n: 12,
			s: 0,
			page: 1,
			pages: 0,
			scrollpx: 0,
			show: false,
			stats: undefined,
			nbrOffres: 0,
			leadingOffer: false
		};
	},
	computed: {
		contracts() {
			return this.criterias.find(c => c.id == "_110755065").elements
		},
		offersPageTitle() {
			return this.$siteconf?.title?.offersPage ?? this.$t('offEmploi')
		}
		// locations() {
		// 	//return this.criterias.find(c => c.id == "_129851691").elements
		// }

	},
	watch: {
		selectedSearch() {
			let query = { search: this.selectedSearch }//!this.$utils.isEmpty(newVal) && {search: JSON.stringify(newVal)}
			if (this.$route.query.page) {
				query = { ...query, "page": this.$route.query.page };
			}
			this.$router.push(JSON.parse(JSON.stringify({ name: ROUTES_NAME.OFFERS, query })))
		},
		$route: {
			handler(newVal, oldVal) {
				if (newVal.name == oldVal?.name) {
					if (newVal.query?.search == [] || !this.$utils.equals(newVal.query?.search, oldVal.query?.search)) this.init("reinitialiserPage")
					else this.init()
				}
			},
			immediate: true
		},
		offers: {
			handler(newVal, oldVal) {
				if (!this.$utils.equals(newVal, oldVal)) {
					if (Object.keys(this.offers).length !== 0) this.$cache.shortIdList = this.offers.map(x => {
						let item = { "shortid": x.short_id, "id": x._id, "title": x.job_title }
						return item
					})
				}
			},
		},
		nbrOffres: {
			handler(newVal) {
				this.nbrOffres = newVal
				this.$cache.nbrOff = this.nbrOffres
			}
		}
	},
	beforeMount() { this.init().catch(e => console.error(e)) },
	async serverPrefetch() { await this.init() },
	mounted() {
		this.primaryColor = this.$utils.getRootColor()
		setTimeout(() => {
			if (this.offers && Object.keys(this.offers).length !== 0) this.$cache.shortIdList = this.offers.map(x => {
				let item = { "shortid": x.short_id, "id": x._id, "title": x.job_title }
				return item
			}
			)
			this.$cache.nbrOff = this.nbrOffres
		}, 500)
		window.addEventListener('scroll', this.handleScroll);

	},
	unmounted() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		handleScroll() {
			this.scrollpx = window.scrollY;
		},
		async init(reinitialiserPage) {
			this.$useHead({ title: pageTitle(this, this.$t("JobList")) });
			const search = this.$route.query?.search && JSON.parse(JSON.stringify(this.$route.query.search));
			if (!this.selectedSearch.length && search) {
				this.selectedSearch = this.$route.query?.search && JSON.parse(JSON.stringify(this.$route.query.search));
			}

			if (reinitialiserPage == "reinitialiserPage") {
				let query = { ...this.$route.query, "page": 1 }
				this.$router.push({
					name: ROUTES_NAME.OFFERS,
					query,
				});
			}

			if (this.$route.query.page) {
				this.s = (this.$route.query.page - 1) * this.n;
				this.page = this.$route.query.page
			}
			else { this.s = 0; }
			let params = { "n": this.n, "s": this.s }
			if (reinitialiserPage == "reinitialiserPage") {
				params.s = 0
			}


			if (this.selectedSearch.length) {
				this.selectedSearch.forEach((element) => {
					let type = ""
					// eslint-disable-next-line max-len
					element.type == "locations" ? type = "loc" : (element.type == "contracts") ? type = "ctr" : (element.type == "remotes") ? type = "rmt" : (element.type == "kw") ? type = "kw" : (element.type == "experience") ? type = "exp" : (element.type == "educations") ? type = "edu" : (element.type == "loc") ? type = "loc" : (element.type == "jobrnt") ? type = "jobrnt" : type = element.type
					element.id = element.id?.replace(/[$<>{};:]/g,'');
					element.label = element.label?.replace(/[$<>{};:]/g,'');
					if (params[type]) params[type] = params[type] + ',' + element.id
					else params[type] = element.id
					params = { ...params }
				});
			}
			try {
				this.leadingOffer = true
				let allOffers = await this.$service.offer.findAll(params);
				this.offers = allOffers.offers
				this.nbrOffres = allOffers.stats.total
				this.stats = allOffers.stats

				this.pages = Math.ceil(this.nbrOffres / this.n)
				this.leadingOffer = false

			} catch (e) {
				this.leadingOffer = false
				this.offers = {}
				this.nbrOffres = 0
				this.stats = {}
				this.pages = 0
			}
			this.view = this.$pref.buttonView ? this.$pref.buttonView : this.$siteconf.layout == "card" ? "columns" : "rows"
		},
		switchView(view) {
			this.page = 1
			this.s = 0
			this.view = view
			this.$pref.buttonView = view
		},
		pagination(page) {
			if (page == "suivant") { this.s = this.s + this.n; this.page++ }
			else if (page == "précédent" && this.page > 1) { this.s = this.s - this.n; this.page-- }
			else {
				this.s = (page - 1) * this.n;
				this.page = page
			}
			let query = { ...this.$route.query, "page": this.page }
			this.$router.push({
				name: ROUTES_NAME.OFFERS,
				query,
			});
		},
		onMarkerClick(evt) {
			this.addSearch(this.mapData[evt.dataIndex].id)
		},
		selectContract(evt) {
			this.addSearch(this.contracts[evt.dataIndex].id)
		},
		addSearch(search) {

			this.selectedSearch.selection = [...new Set([
				...(this.selectedSearch.selection || []),
				search
			])]
			window.scrollTo({ top: 0, behavior: "smooth" })
		},
		openMenuResp() {
			if (window.innerWidth < 550) {
				this.show = true
			}



		},
		closeMenuResp() {
			if (window.innerWidth < 550) {
				this.show = false
			}

		},
		menuclose(e) {
			e.preventDefault()
			this.show = false
		},
		clear() {
			this.selectedSearch = []
		},
		getStatusData() {
			this.init()
		},
	},
};
</script>
<style scoped lang="scss">
.offers-header {
	position: relative;
	background-size: cover;

	&.menu-opened {
		position: fixed;
		z-index: 10;
		//top: 80px;
		left: 0;
		width: 100%;
	}

	.post-section {
		.post-resume {
			--mdc-button-outline-color: var(--primary-color);
		}
	}
}

.offers-body {
	&.menu-opened {
		padding-top: var(--offer-header-padding);
	}
}

.news-letter {
	background-color: white;
	width: min(260px, 100%);
}

.recent-logo {
	height: 40px;
	width: 40px;
}

.popular-logo {
	height: 20px;
	width: 20px;
}

.alert-title {
	padding-right: 100px;
	min-height: 50px;
}

section.map {
	height: 300px;
}

.test {
	//	height: 90px;
	top: 75px;
}

.advanced {
	&.show {
		transform: translateX(0%);
	}

	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: white;
	z-index: 1005;
	transform: translateX(100%);
}

.back {
	//margin-top: 46px !important;
	margin: 0px;
	margin-top: 0px;
	margin-left: 0px;
	margin-left: -35px;
}

.ret {
	margin-left: -45px;
}

.viewSection {
	#col {
		display: none
	}

	#row {
		display: block
	}
}

.viewSection.cols {
	#col {
		display: flex
	}

	#row {
		display: none
	}
}

@media(max-width:991px) {
	.viewSection {
		#col {
			display: flex
		}

		#row {
			display: none
		}
	}
}

.indexButton {
	position: sticky;
	bottom: 0;
}
</style>
