<template>
	<div
		v-bind="$attrs"
		class="form-container d-flex flex-column "
	>
		<div
			class="flex-vertical-center px-1 px-lg-5 bg-white h-spaced-2 form-header app-shadow"
			ref="header"
			:class="{ centred: style == CONTAINER_STYLE.CENTRED_STYLE }"
		>
			<slot name="header" />
		</div>
		<div
			class="form-body flex-max app-shadow position-relative"
			:class="{ centred: style == CONTAINER_STYLE.CENTRED_STYLE, 'cooptation-width': $siteconf.cooptation }"
		>
			<slot name="graph" />
			<div
				class="form d-flex flex-column"
				:class="{ 'cooptation': $siteconf.cooptation }"
			>
				<slot />
			</div>
		</div>
	</div>
</template>
<script>

export const CONTAINER_STYLE = Object.freeze({
	RIGHT_STYLE: "RIGHT_STYLE",
	CENTRED_STYLE: "CENTRED_STYLE"
})


export default {
	data() {
		return {
			CONTAINER_STYLE,
			style: CONTAINER_STYLE.RIGHT_STYLE
		}
	},
	props: {
		containerStyle: {
			type: String,
			default: () => CONTAINER_STYLE.RIGHT_STYLE
		}
	},
	watch: {
		containerStyle() {
			this.style = this.containerStyle || CONTAINER_STYLE.RIGHT_STYLE
		}
	},
	mounted() {
		this.style = this.containerStyle || CONTAINER_STYLE.RIGHT_STYLE
		//this.$refs.header.style.height = this.$refs.header.clientHeight+"px";
	},
}
</script>
<style lang="scss" scoped>
.form-container {

	position: absolute;
	height: calc(100% - 60px);
	width: 100%;
	background-color: var(--app-background);

	.form-header {
		z-index: 100;
		overflow: hidden;
		transition: 1s;
		// &.centred{
		//   height: 0px !important;
		//   opacity: 0;
		// }
	}

	.form-body {
		width: 67%;
		height: 100%;
		background: var(--form-background);
		transition: 1s;

		@media (max-width: 991px) {
			background-color: white !important;
			width: 100%;

			.form {
				height: 100% !important;
				width: 100% !important;
				margin-right: 0 !important;
				margin-top: 0 !important;

			}
		}

		&.centred {
			width: 100%;

			&.cooptation-width {
				width: 67%;

				@media (max-width: 991px) {
					width: 100%;
				}
			}

			.form {
				height: calc(100% - 20vh);
				position: absolute;
				right: 50%;
				top: 20px;
				margin-top: 0;
				width: 50%;
				margin-right: -25%;

				@media (max-width: 991px) {
					height: 100%;
					position: absolute;
					right: 0;
					top: 0;
					margin-top: 0;
					width: 100%;
					margin-right: 0;
				}

				&.cooptation {
					height: calc(100% - 20vh);
					position: absolute;
					right: 0%;
					top: 66px ;
					margin-top: 0;
					width: 100%;

				@media (max-width: 991px) {
					top: 20px;
				}

				}
			}
		}

		.form {
			height: calc(100% - 20vh);
			position: absolute;
			right: 0;
			top: 0;
			margin-top: 10vh;
			width: 72%;
			margin-right: -36%;
			transition: 1s;

		}

	}

}
</style>