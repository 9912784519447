<template lang="">
	<div
		ref="select"
		class="mdc-select mdc-select--outlined"
		:class="{'dense': dense,
			' mdc-select--required':required
		}"
	>
		<input
			v-if="name"
			type="hidden"
			:name="name"
		>
		<div
			class="mdc-select__anchor"
			role="button"
			aria-haspopup="listbox"
			aria-expanded="false"
			aria-labelledby="demo-label demo-selected-text"
			aria-required="true"
		>
			<span class="mdc-notched-outline">
				<span class="mdc-notched-outline__leading" />
				<span class="mdc-notched-outline__notch">
					<span
						id="outlined-select-label"
						class="mdc-floating-label"
					>{{ label }}</span>
				</span>
				<span class="mdc-notched-outline__trailing" />
			</span>
			<span class="mdc-select__selected-text-container">
				<span
					id="demo-selected-text"
					class="mdc-select__selected-text"
				/>
			</span>
			<span class="mdc-select__dropdown-icon">
				<svg
					class="mdc-select__dropdown-icon-graphic"
					viewBox="7 10 10 5"
					focusable="false"
				>
					<polygon
						class="mdc-select__dropdown-icon-inactive"
						stroke="none"
						fill-rule="evenodd"
						points="7 10 12 15 17 10"
					/>
					<polygon
						class="mdc-select__dropdown-icon-active"
						stroke="none"
						fill-rule="evenodd"
						points="7 15 12 10 17 15"
					/>
				</svg>
			</span>
		</div>

		<div
			class="mdc-select__menu mdc-menu mdc-menu-surface "
			:class="{'mdc-menu-surface--fullwidth': !fixedMenuPosition}"
			:style="positionBottom ? 'transform-origin: center top;left: 0px;top: 40px;height: 150px !important;' : ''"
		>
			<ul
				class="mdc-list"
				role="listbox"
				:aria-label="label"
			>
				<slot />
			</ul>
		</div>
	</div>
</template>
<script>
import {MDCSelect} from '@material/select';
export default {
	props:{
		label:{
			type: String,
			default: null
		},
		modelValue:{
			type: String,
			default: null
		},
		name: {
			type: String,
			default: null
		},
		fixedMenuPosition: Boolean,
		dense: Boolean,
		required: {
			type: Boolean,
			default: () => false,
		},
		positionBottom: {
			type: Boolean,
			default: () => false,
		},
	},
	emits:["update:modelValue"],
	watch:{
		modelValue(){
			if(this.select && this.select.value != this.modelValue){
				this.select.value = this.modelValue
			}
		}
	},
	methods:{
		selectedIndex(){
			return this.select.selectedIndex
		},
		selectionChange(){
			this.$emit("update:modelValue", this.select.value)
		}
	},
	mounted(){
		this.select = new MDCSelect(this.$refs.select);
		if(this.fixedMenuPosition){
			this.select.menu.setFixedPosition(true);
		}
		setTimeout(()=> {
			this.select.value = this.modelValue
			this.select.listen('MDCSelect:change', this.selectionChange);
			if(this.fixedMenuPosition && !this.$ssr){
				document.querySelector("body").appendChild(this.select.menu.root);
				this.select.menu.root.style.zIndex=10000
			}
		})
	},
	unmounted(){
		this.select.unlisten('MDCSelect:change', this.selectionChange);
	}
}
</script>
<style lang="scss" scoped>
.mdc-select{
    &.dense .mdc-select__anchor {
        height: 40px !important;
    }
    &.dense .mdc-floating-label--float-above {
        transform: translateY(-26.5px) scale(.75);
    }
}
</style>