<template>
	<span
		v-for="(c, index) in config"
		:key="c"
	>
		<span v-if="$route.params.step != 'done' && !(c.step == 'form' && !form) && !(c.step == 'skills' && $siteconf.lightCv)">
			<span
				class="mdc-chip text-white me-1 defautCur"
				v-if="c.name && index == step"
			>{{ $t('steps.' +
				c.name)
			}}</span>
			<span
				class="mdc-chip text-white me-1 defautCur"
				v-if="c.name && index < step"
			>{{ $t('steps.' +
				c.name).charAt(0) }}</span>
			<span
				class="mdc-chip border empty  me-1 defautCur"
				v-if="c.name && index > step"
			>{{ $t('steps.' +
				c.name).charAt(0) }}</span>
		</span>
	</span>
</template>
<script>
export default {
	props: {
		config: {
			type: Array,
			default: () => []
		},
		step: {
			type: Number,
			default: () => null
		},
		form: {
			type: Boolean,
			default: () => true
		}
	}
}
</script>
<style lang="scss" scoped>
.mdc-chip {
	background-color: var(--primary-color);
}
.empty {
	background-color: transparent !important;
	border-color: var(--primary-color) !important;
	color: var(--primary-color);
	cursor: default
}
</style>