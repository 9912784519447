<template lang="">
	<ParentGraph
		:title="$t('exp.graphText.Experience')"
		:show="errorImage"
	>
		<div
			class="position-relative"
			style="top:100px"
			v-if="!$siteconf.hideImages?.application"
		>
			<div
				class="p-5 bg-white details me-3 info"
				v-if="errorImage"
			>
				<div class="d-flex">
					<span class="material-icons-round me-2">
						done
					</span>{{ $t('exp.graphText.text_1') }}
				</div>
				<div class="d-flex">
					<span class="material-icons-round me-2">
						done
					</span>{{ $t('exp.graphText.text_2') }}
				</div>
			</div>
			<div
				
				class="position-fixed bottom-0"
			>
				<img
					v-if="errorImage"
					alt="white background"
					src="/s/images/Calque_6.png"
					class="position-absolute"
				>
				<img
					class="position-absolute"
					:src="imageSrc"
					ref="Image"
					alt="experience"
				>
			</div>
		</div>
	</ParentGraph>
</template>
<script>
import { imageErrorHandling} from "@/../public/js/imageErrorHandling"
export default {
	mixins: [imageErrorHandling],

	data() {
		return {
			errorImage: false,
			imageSrc: "/c/images/experience",
			fallbackImageSrc: "/s/images/Experience.png"
		};
	},
	mounted() {
		// Check the image dimensions on mount
		this.preloadImage(this.imageSrc, this.fallbackImageSrc);
	}
	};
</script>
<style lang="scss" scoped>
  img{
	bottom: -20px;
  }
  .info{
    position: absolute;
    top: 20%;
    left: 70px;
    background: white;
    border-radius: 100px;
    width: 60%;
	min-width: 360px;
z-index: 1;
  }
  .img2{
	top: 150px;
    bottom: 0;
    width: 320px;
    height: 320px;  }

.graph{
 top:230px
}
</style>