<template lang="">
	<div v-if="e">
		<MatSelect
			v-model="e.level"
			dense
			required
			:label="$t('edc.level')"
			positionBottom
			:class="{'pe-none': makeDisabled}"
		>
			<MatListItem
				v-for="val in educLevels"
				:key="val.val"
				:value="val.val"
				:label="$t('edc.'+val.key)"
			/>
		</MatSelect>
		<div
			v-if="e.level !== '-2'"
			class="d-flex my-3"
		>
			<MatInput
				dense
				v-model="e.title"
				class="me-3"
				:label="$t('formation.deplome')"
				:required="e.level !== '-2'"
				:disabled="e.level === '-2'"
				:class="{'pe-none': makeDisabled}"
			/>
			<MatInput
				dense
				v-model="e.institution"
				:label="$t('formation.eteb')"
				:disabled="e.level === '-2'"
				:class="{'pe-none': makeDisabled}"
			/>
		</div>

		<div
			class="d-flex my-3"
			v-if="e.level !== '-2'"
		>
			<MatSelect
				class="me-3"
				v-if="!e.still"
				v-model="e.start"
				dense
				:label="$t('formation.date')"
				positionBottom
				:required="e.level !== '-2'"
				:disabled="e.level === '-2'"
				:class="{'pe-none': makeDisabled}"
			>
				<MatListItem
					v-for="val in years"
					:key="val"
					:value="val"
					:label="val"
				/>
			</MatSelect>
			<div class="flex-vertical-center">
				<MatCheckbox
					v-model="e.still"
					required
					:disabled="e.level === '-2'"
					:class="{'pe-none': makeDisabled}"
				/>
				<label class="body1 txt-secondary">{{ $t('edc.current') }}</label>
			</div>
		</div>		
		<div
			class="d-flex flex-vertical-center mb-3"
			v-if="e.level !== '-2'"
		>
			<MatInput
				dense
				class="me-2"
				v-model="e.location"
				:label="$t('formation.lieu')"
				:disabled="e.level === '-2'"
				:class="{'pe-none': makeDisabled}"
			/>
		</div>
	</div>
</template>
<script>
const dates = Array(40).fill()
	.map((_, idx) => new Date().getYear() + 1900 - idx)
	.map(year => `${year - 1}/${year}`)
export default {
	data() {
		return {
			e: null,
			dates,
			educLevels: EducLevels,
			years: this.generateYearsList(),
		}
	},
	props: {
		data: {
			type: Object,
			default: () => null
		},
		disabled:{
			type: Boolean,
			default: true
		},
	},
	mounted() {
		this.e = this.data
	},
	methods: {
		stillChange(e) {
			e.still = !e.still;
		},
		generateYearsList() {
			const currentYear = new Date().getFullYear();
			const startYear = 1950; // Change this to your desired start year
			const years = [];

			for (let year = currentYear; year >= startYear; year--) {
				years.push(year.toString());
			}

			return years;
		}

	},
	computed: {
		makeDisabled(){
			return this.disabled && this.$route.name=='userDetail'
		}
	}
}
const EducLevels = [
	{ key: "no_degree", val: "-2" },
	{ key: 'cap', val: "-1" },
	{ key: 'bac', val: "0" },
	{ key: 'bac+1', val: "1" },
	{ key: 'bac+2', val: "2" },
	{ key: 'bac+3', val: "3" },
	{ key: 'bac+4', val: "4" },
	{ key: 'bac+5', val: "5" },
	{ key: 'bac+6', val: "6" },
	{ key: 'bac+7', val: "7" },
	{ key: 'bac+8', val: "8" },
	{ key: 'bac+9', val: "9" },
	{ key: 'bac+10', val: "10" },
	{ key: 'bac+11', val: "11" },
	{ key: 'bac+12', val: "12" }
];
</script>
<style lang="">

</style>
