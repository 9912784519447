

import MatDatepicker from "@/components/material/mat-datepicker.vue";
import MatAutocomplete from "@/components/material/mat-autocomplete.vue";
import MatOption from "@/components/material/mat-option.vue";
import MatChipset from "@/components/material/mat-chipset.vue";
import MatButton from "@/components/material/mat-button.vue";
import MatFab from "@/components/material/mat-fab.vue";
import MatSelect from "@/components/material/mat-select.vue";
import MatInput from "@/components/material/mat-input.vue";
import MatTextarea from "@/components/material/mat-textarea.vue";
import MatFormfield from "@/components/material/mat-formfield.vue";
import MatCheckbox from "@/components/material/mat-checkbox.vue";
import MatRadio from "@/components/material/mat-radio.vue";
import MatMenu from "@/components/material/mat-menu.vue";
import MatRadioGroup from "@/components/material/mat-radio-group.vue";
import MatSlider from "@/components/material/mat-slider.vue";
import MatCheckboxGroup from "@/components/material/mat-checkbox-group.vue";
import MatListItem from "@/components/material/mat-list-item.vue";
import MatIconButton from "@/components/material/mat-icon-button.vue";
import MatIconButtonToggle from "@/components/material/mat-icon-button-toggle.vue";
import ParentForm from "@/components/application/forms/parent-form"
import ParentGraph from "@/components/application/forms/graphics/parent-graph"
import BsCollapsible from "@/components/bootstrap/bs-collapsible"
import TabButton from "@/components/application/tab-button"
import FormContainer from "@/components/application/form-container"
import SvgIcon from "@/components/application/svg-icon"
import MatSwitch from "@/components/material/mat-switch.vue"

const defaultComponent = {
	template:"<slot/>"
}

function defineCustomElements(app) {
	app.component("MwcButton", defaultComponent);
	app.component("MwcMenu", defaultComponent);
	app.component("MwcFab", defaultComponent);
	app.component("MwcIconButtonToggle", defaultComponent);
	app.component("MwcIconButton", defaultComponent);
	app.component("MwcRipple", defaultComponent);
	app.component("MwcSlider", defaultComponent);
	app.component("MwcCheckbox", defaultComponent);
	app.component("MwcFormfield", defaultComponent);
	app.component("MwcTextarea", defaultComponent);
	app.component("MwcTextfield", defaultComponent);
	app.component("VueSlider", defaultComponent);
}

export default {
	install: (app) => {
		app.component("MatDatepicker", MatDatepicker)
		app.component("MatAutocomplete", MatAutocomplete)
		app.component("MatOption", MatOption)
		app.component("MatChipset", MatChipset)
		app.component("MatButton", MatButton)
		app.component("MatFab", MatFab)
		app.component("MatSelect", MatSelect)
		app.component("MatInput", MatInput)
		app.component("MatTextarea", MatTextarea)
		app.component("MatCheckbox", MatCheckbox)
		app.component("MatRadio", MatRadio)
		app.component("MatMenu", MatMenu)
		app.component("MatSlider", MatSlider)
		app.component("MatCheckboxGroup", MatCheckboxGroup)
		app.component("MatRadioGroup", MatRadioGroup)
		app.component("MatFormfield", MatFormfield)
		app.component("MatListItem", MatListItem)
		app.component("MatIconButton", MatIconButton)
		app.component("MatIconButtonToggle", MatIconButtonToggle)
		app.component("ParentForm", ParentForm)
		app.component("ParentGraph", ParentGraph)
		app.component("BsCollapsible", BsCollapsible)
		app.component("TabButton", TabButton)
		app.component("FormContainer", FormContainer)
		app.component("SvgIcon", SvgIcon);
		app.component("MatSwitch", MatSwitch);

		if(typeof window === "undefined"){
			defineCustomElements(app)
		}else{
			import("vue3-slider").then(module => {
				const slider = module.default
				app.component("VueSlider", slider);
			})
		}
	},
};