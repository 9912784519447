<template lang="">
	<button
		ref="button"
		class="mdc-icon-button"
		:class="{'mini': mini,'noFocus':noFocus}"
	>
		<div
			class="mdc-icon-button__ripple"
			:style="noFocus?'--mdc-ripple-fg-size: 0px;':''"
		/>
		<span class="mdc-icon-button__focus-ring" />
		<i
			v-if="icon"
			class="material-icons"
		>{{ icon }}</i>
		<slot v-else />
	</button>
</template>
<script>
export default {
	props:{
		icon:{
			type: String,
			default: null
		},
		mini: Boolean,
		noFocus: {
			type: Boolean,
			default: () => false,
		},
	},
	mounted(){
		let iconButtonRipple = new window.mdc.ripple.MDCRipple(this.$refs.button)
		iconButtonRipple.unbounded = true;
	}
}
</script>
<style lang="scss" scoped>
    .mdc-icon-button {
    width: var(--mdc-icon-button-size, 48px);
    height: var(--mdc-icon-button-size, 48px);
    padding: calc( (var(--mdc-icon-button-size, 48px) - var(--mdc-icon-size, 24px)) / 2 );
    display: inline-flex;
    align-items: center;
    justify-content: center;
	i{
		font-size: var(--mdc-icon-size, 24px);
		width: var(--mdc-icon-size, 24px);
		height: var(--mdc-icon-size, 24px);
	}
	&.mini{
		--mdc-icon-button-size: 40px;
		--mdc-icon-size: 20px
	}
}
.noFocus:hover{
	background-color: rgba(0,0,0,0.1) ;
}
</style>