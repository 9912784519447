import CryptoJS from "crypto-js";

export default {
	hash(pwd) {
		// return pwd;
		var hash = CryptoJS.SHA256(pwd + "y2{<]N=J5T/BX%2M");
		var base64 = CryptoJS.enc.Base64.stringify(hash);
		return base64;
	},
};
