<template lang="">
	<div
		class="text-center final-result-container pb-2 overflow-hidden"
	>
		<div class="mail_container bg-primary-light flex-center mx-auto">
			<!-- <img src="/s/images/mail_3.png"> -->
			<span
				v-if="code == 200"
				class="material-icons check text-primary"
			>
				check_circle
			</span>
			<span
				v-else
				class="material-icons check text-primary"
			>
				unpublished
			</span>
		</div>
		<p class=" h5 pb-2">
			{{ getTitle }}
		</p>
		<span v-if="$route.params.name!='candidature_spontanee'">
			<p class="h6">{{ title }}<span v-if="$siteconf.client">{{ $siteconf.client }}</span></p>
		</span>
		<p
			class="body1 txt-secondary py-4"
			v-if="code == 200"
		>
			{{ $siteconf.cooptation ? $t('doneTextCoop') : $t('doneText') }}
		</p>
		<div
			v-else
			class="mt-3 mb-4"
		>
			<span class="body1 txt-secondary py-4">
				{{ $siteconf.cooptation ? $t('doneTextError.1Coop') : $t('doneTextError.1') }} <br> {{ $t('doneTextError.2') }} <br>{{ $t('doneTextError.3') }}
			</span> 
		</div>
		<!-- TODO: DVT account -->
		<div
			v-if="$siteconf.thanks_code && code == 200"
			v-html="$siteconf.thanks_code"
		/>
	</div>
</template>
<script>
import { ROUTES_NAME } from '@/router'
export default {
	emits: ['getJobTitle'],
	data() {
		return {
			ROUTES_NAME,
		}
	},
	props: {
		title: {
			type: String,
			default: ""
		},
		code: {
			type: Number,
			default: 200
		}
		
	},
	computed: {
		getTitle(){
			if (this.code !== 200) return this.$siteconf.cooptation ? this.$t('doneTitleErrorCoop') : this.$t('doneTitleError')
			else return this.$siteconf.cooptation ? this.$t('doneTitleCoop') : this.$t('doneTitle')
		},
	},
}
</script>
<style lang="scss" scoped>
	.mail_container{
		width: 181px;
		height: 174px;
		border-radius: 50%;
		position: relative;
		&::before{
			content: "";
			background: url(/s/images/mail_3.png);
			background-repeat: no-repeat;
			background-position: center;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
		.check{
			font-size: 40px;
			margin-top: -30px;
		}
	}
	.final-result-container{
		margin-top: -20px;
		margin-bottom: -20px;
	}
</style>
