export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!............"])},
  "accueil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "hOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our offers"])},
  "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["job offers"])},
  "oneOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One published offer"])},
  "oneOfferRech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One published job offer matches your search"])},
  "noOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No job offers are published, so don't hesitate to submit an spontaneous application"])},
  "noOfferRech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No job offers found, please change filters or submit an spontaneous application"])},
  "positions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positions"])},
  "onePosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["position"])},
  "noPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No position available"])},
  "voirTO": {
    "sm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALL OFFERS"])},
    "lg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEE ALL OFFERS "])}
  },
  "siteWeb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view website"])},
  "iTravaille": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested to work with us?"])},
  "iTravailleSubtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here !"])},
  "envoiCandid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send your spontaneous application"])},
  "spontanee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPONTANEOUS APPLICATION"])},
  "recent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most recent offers"])},
  "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXPLORE all offers"])},
  "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For you"])},
  "clientWebsite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["_ website"])},
  "Mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal information"])},
  "Police": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentiality policy"])},
  "nbRecherche": {
    "plur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["job offers matching your criteria"])},
    "Publiee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["published job offers"])}
  },
  "recherch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])},
  "offEmploi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job offers"])},
  "searchPlaceholder": {
    "sm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer name"])},
    "lg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer name or keywords"])}
  },
  "jPostule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I apply"])},
  "Publiée": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published on "])},
  "envi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The environment"])},
  "poste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The position"])},
  "profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile required"])},
  "ans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years"])},
  "resultat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results list"])},
  "pre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["previous"])},
  "suiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
  "contract": {
    "permanent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanent"])},
    "fixed-term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDD"])},
    "apprenticeship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprenticeship"])},
    "internship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internship"])},
    "civic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civic service"])},
    "fr-vie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIE"])},
    "freelance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelance"])},
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training"])},
    "coaching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coaching"])},
    "public-permanent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanent official"])},
    "public-non-permanent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non permanent official"])},
    "plusieur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Several types of contracts"])},
    "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "education": {
    "-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No diploma"])},
    "-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAP, BEP, Bac pro"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baccalauréat"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+2, BTS"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+3, Bachelor, Licence, BUT"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+4, Master 1"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+5, Master, Engineer"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+8, Doctorate"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])},
    "2,2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+2, BTS"])},
    "3,3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+3, Bachelor, Licence, BUT"])},
    "4,4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+4, Master 1"])},
    "5,5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+5, Master, Engineer"])},
    "8,8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bac+8, Doctorate"])},
    "9,9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doctor"])}
  },
  "tt": {
    "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternating remote / on site"])},
    "occasional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote work occasionally tolerated"])},
    "forbidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No remote work possible"])},
    "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totally remote"])}
  },
  "Localisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "Contrats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contracts"])},
  "Télétravail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote work"])},
  "Formation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
  "Expérience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
  "Auto-Complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-Complete"])},
  "mail-phone": {
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email / Phone"])},
    "deux": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address and phone number"])},
    "mail-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email addressl"])},
    "phone-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your phone number"])},
    "lun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address or your phone number"])},
    "coop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the details of the proposed candidate"])}
  },
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept "])},
  "cond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms of use and privacy policy"])},
  "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of use and privacy policy"])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
  "debut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "mCondid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Mode"])},
  "mCandidtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click and let us guide you"])},
  "importMan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't have a CV"])},
  "importCv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have a CV"])},
  "teleCv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload my resume"])},
  "teleCvCoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload the resume of the proposed candidate"])},
  "docTele": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded document"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload / Drop my CV here"])},
  "drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release to drop my CV here"])},
  "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported formats: pdf, doc(x), odt, rtf, jpeg, png"])},
  "miseAjour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated one minute ago"])},
  "mj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
  "modifierFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replace with another file"])},
  "pupload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why upload my resume?"])},
  "uploadText": {
    "text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick application"])},
    "text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply easily from your mobile"])},
    "text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only recruiters on this platform can view your resume."])}
  },
  "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back"])},
  "infoGen": {
    "graphText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
    "formText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your personal information"])},
    "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "prenom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "naissance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "adress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "ville": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "cp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years"])}
  },
  "genders": {
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "undisclosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't want to declare"])}
  },
  "formulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form"])},
  "steps": {
    "1CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 RESUME"])},
    "4CV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 RESUME"])},
    "3Formulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Form"])},
    "3Informations_personelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Personal information"])},
    "2Informations_personelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Personal information"])},
    "1Informations_personelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Personal information"])},
    "2Experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Experience"])},
    "3Formation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Education"])},
    "4Compétence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Skills"])},
    "5Formulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 Form"])},
    "6Validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 Validation"])},
    "5Validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 Validation"])},
    "6Formulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 Form"])},
    "4Formulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 Form"])},
    "1Formulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 Form"])},
    "1Informations_candid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Candidat information"])}
  },
  "exp": {
    "graphText": {
      "Experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
      "text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start with the most recent positions"])},
      "text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List the start and end dates of each job"])}
    },
    "formText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe your professional background"])},
    "formTextVide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title of first experience"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add experience"])},
    "maintenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["now"])},
    "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job title"])},
    "entreprise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
    "debut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "fin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
    "postActuelle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I currently hold this position"])},
    "descp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe your work experience"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" at "])},
    "du": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" from "])},
    "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" for "])},
    "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" for "])},
    "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" and "])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["months"])}
  },
  "edc": {
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is my current formation"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education level"])},
    "no_degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])},
    "cap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])},
    "bac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])},
    "bac+1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])},
    "bac+2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])},
    "bac+3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])},
    "bac+4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])},
    "bac+5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])},
    "bac+6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])},
    "bac+7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])},
    "bac+8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])},
    "bac+9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])},
    "bac+10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])},
    "bac+11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])},
    "bac+12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODO"])}
  },
  "formation": {
    "graphText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "formText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe your education"])},
    "formTextVide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title of first degree"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a diploma "])},
    "deplome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the diploma"])},
    "eteb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation year"])},
    "lieu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "descp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe your degree"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" at "])},
    "du": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" from "])}
  },
  "comp": {
    "graphText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "formText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highlight your skills"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical skills"])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soft skills"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD SKILL LEVEL"])}
  },
  "validateAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to receive similar offers by e-mail"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to search result"])},
  "doneTous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover our offers"])},
  "doneText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We confirm that your application has been forwarded to our recruitment team and will be processed as soon as possible"])},
  "doneTextCoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We confirm that your proposal has been forwarded to our recruitment team and will be processed as soon as possible"])},
  "doneTextError": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, due to a technical issue, your application was unable to be sent to the recruiter :("])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We invite you to retry later and verify your input."])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We apologize for this inconvenience. Nevertheless, your contact information has been saved."])},
    "1Coop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, due to a technical issue, your proposal was unable to be sent to the recruiter :("])}
  },
  "doneTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your application has been sent"])},
  "doneTitleCoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your proposal has been sent"])},
  "doneTitleError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure to send the application"])},
  "doneTitleErrorCoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failure to send the proposal"])},
  "soumettreCandi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submit my application"])},
  "soumettreCoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submit my proposal"])},
  "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting..."])},
  "plusieursLoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple locations"])},
  "ilya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" minutes ago"])},
  "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" hours ago"])},
  "jr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" days ago"])},
  "hier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Yesterday"])},
  "renisialiser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reset"])},
  "aujourdhui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
  "countries": {
    "algeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algeria"])},
    "angola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angola"])},
    "benin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benin"])},
    "botswana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Botswana"])},
    "burkina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burkina"])},
    "burundi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burundi"])},
    "cameroon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cameroon"])},
    "cape verde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cape Verde"])},
    "central african republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central African Republic"])},
    "chad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chad"])},
    "comoros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comoros"])},
    "congo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo"])},
    "congo, democratic republic of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congo, Democratic Republic of"])},
    "djibouti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Djibouti"])},
    "egypt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egypt"])},
    "equatorial guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equatorial Guinea"])},
    "eritrea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eritrea"])},
    "ethiopia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethiopia"])},
    "gabon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gabon"])},
    "gambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambia"])},
    "ghana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghana"])},
    "guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea"])},
    "guinea-bissau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guinea-Bissau"])},
    "ivory coast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ivory Coast"])},
    "kenya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenya"])},
    "lesotho": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesotho"])},
    "liberia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liberia"])},
    "libya": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libya"])},
    "madagascar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madagascar"])},
    "malawi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malawi"])},
    "mali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mali"])},
    "mauritania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritania"])},
    "mauritius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauritius"])},
    "morocco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Morocco"])},
    "mozambique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mozambique"])},
    "namibia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namibia"])},
    "niger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niger"])},
    "nigeria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nigeria"])},
    "rwanda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rwanda"])},
    "sao tome and principe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao Tome and Principe"])},
    "senegal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senegal"])},
    "seychelles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seychelles"])},
    "sierra leone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sierra Leone"])},
    "somalia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somalia"])},
    "south africa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Africa"])},
    "south sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["South Sudan"])},
    "sudan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudan"])},
    "swaziland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swaziland"])},
    "tanzania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanzania"])},
    "togo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Togo"])},
    "tunisia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisia"])},
    "uganda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uganda"])},
    "zambia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zambia"])},
    "zimbabwe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimbabwe"])},
    "albania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albania"])},
    "andorra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andorra"])},
    "armenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armenia"])},
    "austria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Austria"])},
    "azerbaijan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azerbaijan"])},
    "belarus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belarus"])},
    "belgium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgium"])},
    "bosnia and herzegovina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnia and Herzegovina"])},
    "bulgaria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgaria"])},
    "croatia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croatia"])},
    "cyprus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyprus"])},
    "czech republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czech Republic"])},
    "denmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denmark"])},
    "estonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estonia"])},
    "finland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finland"])},
    "france": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
    "georgia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Georgia"])},
    "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
    "greece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greece"])},
    "hungary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungary"])},
    "iceland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iceland"])},
    "ireland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ireland"])},
    "italy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italy"])},
    "latvia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latvia"])},
    "liechtenstein": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liechtenstein"])},
    "lithuania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lithuania"])},
    "luxembourg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxembourg"])},
    "macedonia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macedonia"])},
    "malta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "moldova": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moldova"])},
    "monaco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaco"])},
    "montenegro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montenegro"])},
    "netherlands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netherlands"])},
    "norway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norway"])},
    "poland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poland"])},
    "portugal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "romania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romania"])},
    "san marino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["San Marino"])},
    "serbia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serbia"])},
    "slovakia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovakia"])},
    "slovenia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenia"])},
    "spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spain"])},
    "sweden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweden"])},
    "switzerland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switzerland"])},
    "ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
    "united kingdom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Kingdom"])},
    "vatican city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vatican City"])},
    "antigua and barbuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antigua and Barbuda"])},
    "bahamas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahamas"])},
    "barbados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barbados"])},
    "belize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belize"])},
    "canada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canada"])},
    "costa rica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costa Rica"])},
    "cuba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuba"])},
    "dominica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominica"])},
    "dominican republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominican Republic"])},
    "el salvador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Salvador"])},
    "grenada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grenada"])},
    "guatemala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guatemala"])},
    "haiti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haiti"])},
    "honduras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Honduras"])},
    "jamaica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamaica"])},
    "mexico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexico"])},
    "nicaragua": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicaragua"])},
    "panama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panama"])},
    "saint kitts and nevis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Kitts and Nevis"])},
    "saint lucia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Lucia"])},
    "saint vincent and the grenadines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saint Vincent and the Grenadines"])},
    "trinidad and tobago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinidad and Tobago"])},
    "united states": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United States"])},
    "argentina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argentina"])},
    "bolivia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolivia"])},
    "brazil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brazil"])},
    "chile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chile"])},
    "colombia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colombia"])},
    "ecuador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecuador"])},
    "guyana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyana"])},
    "paraguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paraguay"])},
    "peru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peru"])},
    "suriname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suriname"])},
    "uruguay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uruguay"])},
    "venezuela": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venezuela"])},
    "afghanistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afghanistan"])},
    "bahrain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bahrain"])},
    "bangladesh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bangladesh"])},
    "bhutan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bhutan"])},
    "brunei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brunei"])},
    "burma (myanmar)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burma (Myanmar)"])},
    "cambodia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambodia"])},
    "china": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "east timor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["East Timor"])},
    "india": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["India"])},
    "indonesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indonesia"])},
    "iran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iran"])},
    "iraq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iraq"])},
    "israel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Israel"])},
    "japan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
    "jordan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jordan"])},
    "kazakhstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kazakhstan"])},
    "korea, north": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korea, North"])},
    "korea, south": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korea, South"])},
    "kuwait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuwait"])},
    "kyrgyzstan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyrgyzstan"])},
    "laos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laos"])},
    "lebanon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebanon"])},
    "malaysia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaysia"])},
    "maldives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maldives"])},
    "mongolia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mongolia"])},
    "nepal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepal"])},
    "oman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oman"])},
    "pakistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakistan"])},
    "palestine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palestine"])},
    "philippines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philippines"])},
    "qatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qatar"])},
    "russian federation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russian Federation"])},
    "saudi arabia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saudi Arabia"])},
    "singapore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapore"])},
    "sri lanka": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sri Lanka"])},
    "syria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syria"])},
    "tajikistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tajikistan"])},
    "thailand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thailand"])},
    "turkey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkey"])},
    "turkmenistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turkmenistan"])},
    "united arab emirates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["United Arab Emirates"])},
    "uzbekistan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzbekistan"])},
    "vietnam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vietnam"])},
    "yemen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yemen"])},
    "australia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australia"])},
    "fiji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiji"])},
    "kiribati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiribati"])},
    "marshall islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marshall Islands"])},
    "micronesia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Micronesia"])},
    "nauru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nauru"])},
    "new zealand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Zealand"])},
    "palau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palau"])},
    "papua new guinea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papua New Guinea"])},
    "samoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samoa"])},
    "solomon islands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solomon Islands"])},
    "tonga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonga"])},
    "tuvalu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvalu"])},
    "vanuatu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vanuatu"])}
  },
  "devby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by"])},
  "Form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete the information below to facilitate the processing of your application."])},
  "FormCoop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete the information below."])},
  "FormText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete the form: "])},
  "fileError": {
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file is too big! It should be 5MB maximum."])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid format. Supported formats: pdf, doc(x), odt, rtf, jpeg, png."])},
    "invalidChar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid file name. Only letters, numbers, spaces and symbols (-,_) are allowed"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot upload file. Make sure that size < 5MB, format is supported (pdf, doc(x), odt, rtf, jpeg, png) and name doesn't contain special characters"])}
  },
  "CareerSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job site"])},
  "JobList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job offer list"])},
  "connecter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOG IN"])},
  "inscrit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGISTER"])},
  "addMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "confpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
  "confpasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password and confirmation password do not match."])},
  "loginText": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to our platform for free!"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick application that saves you the time of re-entering your information for each application"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply easily from your mobile phone"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep track of your applications"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be visible to recruiters"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your email alerts and receive new job offers that match your profile every day"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your saved job offers"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free registration"])}
  },
  "noOffre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No offers available, remove criteria to filter"])},
  "signinEror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect email or password."])},
  "signinerr_429": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action blocked due to multiple consecutive attempts, please wait before trying again"])},
  "signupError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An account already exists at this address. If it's yours, go to the login tab and follow the forgotten password link if necessary."])},
  "newCandid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been created. You can use it to track your application."])},
  "signout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect"])},
  "connecterL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "inscritL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "validationExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, your validation Link is expired."])},
  "validationInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, your validation link is invalid."])},
  "passwordErrorRegex": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid password. Please ensure that your password meets the following requirements:"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one capital letter"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one numeric digit"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one special character"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum length of 8 characters"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one lower case character"])}
  },
  "forgetPw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
  "forgetPwText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "mpactuel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
  "nvpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "changepassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
  "vCompte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified account"])},
  "vrCompte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend verification link"])},
  "avCompte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending verification"])},
  "param": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "modifMp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify password"])},
  "candidatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job applications"])},
  "tous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "Envoyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent"])},
  "Encours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In progress"])},
  "Cloturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
  "etatProg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State of progress"])},
  "pasCandid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't sent in any applications yet."])},
  "resetpasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Please try again later. If the problem persists, please contact support"])},
  "resetPasswordSucces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A reset email has been sent to you by email."])},
  "forgetMp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
  "connectedTanque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are connected as "])},
  "envCand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send my application"])},
  "modCand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify my application"])},
  "candiSansCompte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply without an account"])},
  "connexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "creer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day"])},
  "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hour"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["year"])},
  "chargement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "invalidForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The link is invalid. Make sure you have copied and pasted all the characters in the link."])},
  "expiredForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The link has expired."])},
  "errorpost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred. Please contact technical support."])},
  "formsend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your answers have already been sent."])},
  "formsentresp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The answers were successfully registered."])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "coopInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate information"])},
  "coopInfoPer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate's personal information"])},
  "captcha_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am not a robot"])},
  "captcha_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification failed. Try again later."])},
  "captcha_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification expired. Try again."])},
  "captcha_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])},
  "captcha_verifying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifying..."])},
  "captcha_waitAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifying... please wait."])}
}