<template lang="">
	<button
		class="text-primary"
		v-bind="$attrs"
		@click="select"
	>
		<span class="mdc-ripple-surface" />
		<slot />
	</button>
</template>
<script>
export default {
	mounted() {
	},
	methods: {
		select(event){
			const target = event.target;
			const name  = target.getAttribute("name");
			if(name){
				const elements = document.querySelectorAll(`[name='${name}'`);
				for(let e of elements){
					e.classList.remove("active");
				}
			}
			target.classList.add("active");
      
		}
	},
}
</script>
<style lang="scss" scoped>
  button{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    &.active{
      border-bottom: 2px solid var(--primary-color);
    }
  }
</style>