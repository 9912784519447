<template lang="">
	<aside
		ref="matDrawer"
		class="mdc-drawer mdc-drawer--dismissible"
		dir="rtl"
		@click="drawer.open=false"
	>
		<div
			class="mdc-drawer__content"
			dir="ltr"
		>
			<slot />
		</div>
	</aside>

	<div
		class="mdc-drawer-scrim"
		style="background:rgba(0,0,0,.5)"
		@click="drawer.open=false"
	/>
</template>
<script>
export default {
	mounted(){
		this.drawer = window.mdc.drawer.MDCDrawer.attachTo(this.$refs.matDrawer);

	},
	methods:{
		open(){
			this.drawer.open=true
		},
		close(){
			this.drawer.open=false
		}
	}
};
</script>
<style lang="scss" scoped>
.mdc-drawer{
    z-index: 1003;
}
</style>