<template lang="">
	<div class="px-4 py-1 criteria-container">
		<MatChipset
			v-model="selection"
			class="selected-criteria"
			v-if="showSelection"
		/>
		<template v-if="noOffre">
			<div
				class=""
				v-for="(value, name) in searchConfig"
				:key="name"
			>
				<div
					class="d-flex flex-center h-spaced-1"
					v-if="!value?.hideconf && value.data?.length>0 "
				>
					<span
						class="material-icons-outlined"
					>
						{{ value.info.icon }}
					</span>
					<div class="input-text flex-max">
						{{ $t(value.info.title) }}
					</div>
					<span
						v-if="value.info.advanced"
						class="material-icons-outlined pointer"
						@click="hideConfig(value, name, 'show')"
					>
						chevron_right
					</span>
				</div>
				<component
					:is="value.template"
					:data="value.data"
					:filter="filter"
					ref="templates"
					v-bind="value.attrs"
					v-model="value.selected"
					@add="addSelection($event, name)"
					@remove="removeSelection($event, name)"
					@hide-header="hideHeader($event,name)"
					class="mb-2"
				/>
			</div>

			<div
				class="advanced"
				:class="{show:show}"
				ref="advanced"
			>
				<div
					class="d-flex flex-center h-spaced-1 mt-2 ms-3 me-5"
					v-if="!advancedTemplate?.hideconf "
				>
					<span
						class="material-icons-outlined pointer"
						@click="hideConfig"
					>
						chevron_left
					</span>
					<div
						class="input-text flex-max"
						v-if=" advancedTemplate.title"
					>
						{{ $t(advancedTemplate.title) }}
					</div>
				</div>
				<component
					:is="advancedTemplate.advancedtemplate"
					:data="advancedTemplate.advanceddata"
					:filter="filter"
					v-bind="advancedTemplate.attrs"
					v-model="advancedTemplate.selected"
					@add="addSelection($event, advancedTemplate.name)"
					@remove="removeSelection($event, advancedTemplate.name)"
					@hide-header="hideHeader($event,advancedTemplate.name)"
					class="mb-2"
				/>
			</div>
		</template>
		<span
			v-else
			class="text-center d-block"
		>
			{{ $t(`noOffre`) }}
		</span>
	</div>
</template>
<script>
//import TokenTemplate from "./search-criteria-section-template/token-template.vue"
//import SliderTemplate from "./search-criteria-section-template/slider-template.vue"
import CheckboxTreeTemplate from "./search-criteria-section-template/checkbox-tree-template.vue"
import AdvancedCheckboxTreeTemplate from "./search-criteria-section-template/advanced-checkbox-tree-template.vue"
import RemoteWorkTemplate from "./search-criteria-section-template/remote-work-template.vue"
import CheckboxTemplate from "./search-criteria-section-template/checkbox-template.vue"
//import vueSlideTemplate from "./search-criteria-section-template/vueSlider-template.vue"
import KeyWord from "./search-criteria-section-template/keyWord.vue"
import { SearchIconMapping } from "@/utils/utils"
import { EducationLevel } from "@/utils/constants";
import { Direction } from "./search-criteria-section-template/checkbox-template.vue"

import { shallowRef } from "vue"
export default {
	// components: {TokenTemplate, CheckboxTreeTemplate, CheckboxTemplate, RemoteWorkTemplate,SliderTemplate},
	data() {
		return {
			EducationLevel,
			selection: [],
			advancedTemplate: "",
			show: false,
			hideheader: false,
			Direction,
			searchConfig: {
				kw: {
					template: shallowRef(KeyWord),
					attrs: {
						title: "Mot-Clé",
						icon: SearchIconMapping.word,
					},
					info: {
						title: "Mot-Clé",
						icon: SearchIconMapping.word,
					},
					selected: [],
				},
				locations: {
					template: shallowRef(CheckboxTreeTemplate),
					advancedtemplate: shallowRef(AdvancedCheckboxTreeTemplate),
					attrs: {
						title: "Lieu souhaité",
						icon: SearchIconMapping.location,
						advanced: false
					},
					info: {
						title: "Lieu souhaité",
						icon: SearchIconMapping.locations,
						advanced: false
					},
					selected: [],
				},
				contracts: {
					template: shallowRef(CheckboxTemplate),
					attrs: {
						title: "Contrats",
						icon: SearchIconMapping.contract,
						advanced: false
					},
					info: {
						title: "Type de contrat recherché",
						icon: SearchIconMapping.contracts,
						advanced: false
					},
					selected: [],
					advanced: false
				},

				remotes: {
					template: shallowRef(RemoteWorkTemplate),
					selected: [],
					info: {
						title: "Télétravail",
						icon: SearchIconMapping.remotes,
						advanced: false
					},
				},
				experience: {
					template: shallowRef(CheckboxTemplate),
					selected: [],
					attrs: {
						title: "Expérience",
						icon: SearchIconMapping.experience,
						advanced: false
					},
					info: {
						title: "Expérience",
						icon: SearchIconMapping.experience,
						advanced: false
					},
				},
				educations: {
					template: shallowRef(CheckboxTemplate),
					attrs: {
						title: "Formation",
						icon: SearchIconMapping.education
					},
					info: {
						title: "Formation",
						icon: SearchIconMapping.education,
						advanced: false
					},
					selected: 0
				}
			},
			searchInput: ""
		}
	},
	props: {
		modelValue: {
			type: Array,
			default: () => []
		},
		filter: {
			type: String,
			default: () => ""
		},
		stats: { type: Object, default: () => { } },
		autoCompleteList: { type: Object, default: () => { } },
		search: {
			type: String,
			default: () => ""
		},
		showSelection: { type: Boolean, default: () => false },
		noOffre: { type: Boolean, default: () => false }
	},
	emits: ["update:modelValue", "remove-search"],
	computed: {
	},
	watch: {
		modelValue: {
			handler() {
				if (!this.$utils.equals(this.modelValue, this.selection)) {
					this.selection = this.modelValue
					this.updateSelectionModel()
				} else {
					this.selection = JSON.parse(JSON.stringify(this.modelValue))
				}
			}
		},
		stats: {
			handler(newVal) {
				//	console.log(this.search);
				if (newVal) {
					if (this.$siteconf.extraIndexes) {
						for (const key in this.$siteconf.extraIndexes) {
							if (Object.hasOwnProperty.call(this.$siteconf.extraIndexes, key)) {
								this.searchConfig[key] = {
									template: shallowRef(CheckboxTemplate), // You need to define your template
									attrs: {
										title:"Tags",
										icon:  this.$siteconf.extraIndexes[key].icon ? this.$siteconf.extraIndexes[key].icon : SearchIconMapping.jobrnt,
										advanced: false,
										direction: Direction.VERTICAL
									},
									info: {
										title: this.$siteconf.extraIndexes[key] ? this.$siteconf.extraIndexes[key].label[this.$siteconf.lang] : '' ,
										icon: this.$siteconf.extraIndexes[key].icon ? this.$siteconf.extraIndexes[key].icon : SearchIconMapping.jobrnt,
										advanced: false,

									},
									selected: [],
								};
							}
						}
					}
					this.fillSearchConfData()
				}
			},
		},
		autoCompleteList: {
			handler() {
				this.fillAutoCompData();
			},
			//immediate:true
		},
		search: {
			handler() {
				this.searchInput = this.search
				if (this.search) this.searchConfig["kw"].data = this.search
				else delete this.searchConfig["kw"].data
			},
			//immediate:true
		},
		selection(newVal, oldVal) {
			if (!this.$utils.equals(newVal, oldVal)) {
				this.$emit("update:modelValue", this.selection)
			}
		},
	},
	mounted() {
		//this.fillSearchConfData()
	},
	methods: {
		fillSearchConfData() {
			if (!this.searchInput) {
				setTimeout(() => {
					if (Object.keys(this.searchConfig)[0] != "kw")
							this.searchConfig = Object.fromEntries(Object.entries(this.searchConfig).reverse());
					for (let key in this.searchConfig) {

						if (key == "experience" && this.stats["experiences"]) {
							this.searchConfig[key].data = Object.values(this.stats["experiences"])
						} else if (key == "experience" && !this.stats["experiences"]) {
							this.searchConfig[key].data = []
						}
						else if (key == "educations" && this.stats["educations"]) {
							this.searchConfig[key].data = Object.values(this.stats["educations"])[0]
						} else if (key == "educations" && !this.stats["educations"]) this.searchConfig[key].data = []
						else if (key == "locations" && this.stats[key]) {
							this.searchConfig[key].info.advanced = true
							this.searchConfig[key].data = Object.values(this.stats[key])[0].slice(0, 5)
							this.searchConfig[key].advanceddata = Object.values(this.stats[key])[1]
						}
						else if (key == "locations" && !this.stats[key]) {
							this.searchConfig[key].data = []
							this.searchConfig[key].advanceddata = []
						}
						else if (key == "remotes" && this.stats["remotes"]) {
							this.searchConfig[key].data = []
							this.searchConfig[key].data = Object.values(this.stats["remotes"])
						} else if (key == "remotes" && !this.stats["remotes"]) { this.searchConfig[key].data = [] }
						else if (key == "contracts" && this.stats["contracts"]) {
							this.searchConfig[key].data = Object.values(this.stats["contracts"])
						} else if (key == "contracts" && !this.stats["contracts"]) { this.searchConfig[key].data = [] }


						//if (this.stats[key] && this.stats[key].length > 1) this.searchConfig[key].advanced = true
					}
					for (const key in this.$siteconf.extraIndexes) {
						if (Object.hasOwnProperty.call(this.$siteconf.extraIndexes, key)) {
							if (key in this.stats && this.stats[key]) {
								this.searchConfig[key].data = Object.values(this.stats[key]);
								this.searchConfig[key].data[0] = this.searchConfig[key].data[0].slice(0,5)
							} else if (key in this.stats && !this.stats[key]) {
								this.searchConfig[key].data = [];
							}
						}
					}
					this.selection = this.modelValue
					this.updateSelectionModel()
				});
			}

		},
		fillAutoCompData() {
			setTimeout(() => {
				if (!this.$utils.isEmpty(this.autoCompleteList)) {
					//verif if autoComplete list contient one object with one element if true it well be deleting the kw from the result
					const keys = Object.keys(this.autoCompleteList);
					if (keys.length === 1) {
						const key = keys[0]; // Get the one and only key
						const value = this.autoCompleteList[key];
						if (Array.isArray(value) && value.length == 1) {
							if(Object.keys(this.searchConfig)[0] === "kw") this.searchConfig= Object.fromEntries(Object.entries(this.searchConfig).reverse());
						} else if(Object.keys(this.searchConfig)[0] != "kw")
							this.searchConfig= Object.fromEntries(Object.entries(this.searchConfig).reverse());
					}
					else if (Object.keys(this.searchConfig)[0] != "kw") this.searchConfig = Object.fromEntries(Object.entries(this.searchConfig).reverse());

					this.processAutoCompleteList(this.autoCompleteList["contracts"], "contracts");
					this.processAutoCompleteList(this.autoCompleteList["educations"], "education");
					this.processAutoCompleteList(this.autoCompleteList["experiences"], "experience");
					this.processAutoCompleteList(this.autoCompleteList["locations"], "locations");
					this.processAutoCompleteList(this.autoCompleteList["remotes"], "remotes");
					for (const key in this.$siteconf.extraIndexes) {
						if (Object.hasOwnProperty.call(this.$siteconf.extraIndexes, key)) {
							if (key in this.autoCompleteList && this.autoCompleteList[key]) {
								this.processAutoCompleteList(this.autoCompleteList[key], key);
							}
						}
					}


					//this.processAutoCompleteList(this.autoCompleteList["jobrnt"], "jobrnt");
					//this.fillSearchConfData(

					for (let key in this.searchConfig) {
						if (key == "contracts" && this.autoCompleteList["contracts"]) {
							this.searchConfig[key].data = [this.autoCompleteList["contracts"]]
						} else if (key == "contracts" && !this.autoCompleteList["contracts"]) {
							this.searchConfig[key].data = []
						}
						if (key == "experience" && this.autoCompleteList["experiences"]) {
							this.searchConfig[key].data = [this.autoCompleteList["experiences"]]
						} else if (key == "experience" && !this.autoCompleteList["experiences"]) {
							this.searchConfig[key].data = []
						}
						else if (key == "educations" && this.autoCompleteList["educations"]) {
							this.searchConfig[key].data = this.autoCompleteList["educations"]
						} else if (key == "educations" && !this.autoCompleteList["educations"]) {
							this.searchConfig[key].data = []
						}
						else if (key == "locations" && this.autoCompleteList["locations"]) {
							this.searchConfig[key].info.advanced = false
							this.searchConfig[key].data = this.autoCompleteList["locations"]
							this.searchConfig[key].advanceddata = []
						}
						else if (key == "locations" && !this.autoCompleteList["locations"]) {
							this.searchConfig[key].info.advanced = false
							this.searchConfig[key].data = []
							this.searchConfig[key].advanceddata = []
						}
						else if (key == "remotes" && this.autoCompleteList["remotes"]) {
							this.searchConfig[key].data = [this.autoCompleteList["remotes"]]
						} else if (key == "remotes" && !this.autoCompleteList["remotes"]) {
							this.searchConfig[key].data = []
						}
						for (const key in this.$siteconf.extraIndexes) {
							if (Object.hasOwnProperty.call(this.$siteconf.extraIndexes, key)) {
								if (key in this.autoCompleteList && this.autoCompleteList[key]) {
									this.searchConfig[key].data = [this.autoCompleteList[key].slice(0, 5)];
								} else if (key in this.stats && !this.stats[key]) {
									this.searchConfig[key].data = [];
								}
							}
						}
					}
					this.selection = this.modelValue
					this.updateSelectionModel()

				}
				else {
					if (this.searchInput.length > 2) {
						this.searchConfig["remotes"].data = []
						this.searchConfig["locations"].data = []
						this.searchConfig["educations"].data = []
						this.searchConfig["experience"].data = []
						this.searchConfig["contracts"].data = []
						for (const key in this.$siteconf.extraIndexes) {
							if (Object.hasOwnProperty.call(this.$siteconf.extraIndexes, key)) {
								this.searchConfig[key].data = []
							}
						}
					}
				}
			}, 100)
		},
		selectedEducation(obj) {
			let index = this.selection.findIndex(el => { return el.type == "educations" })
			if (obj && index == -1) {
				this.selection.push({
					id: obj,
					label: EducationLevel[obj],
					type: "educations"
				})
			}
			if (obj && index != -1) {
				this.selection[index] = {
					id: obj,
					label: EducationLevel[obj],
					type: "educations"
				}
			}

		},

		hideHeader(action, key) {
			this.searchConfig[key].hideconf = action
			if (this.advancedTemplate) this.advancedTemplate.hideConfig = action
			//this.hideheader=action
		},
		updateSelectionModel() {
			for (let key in this.searchConfig) {

				let selected = this.searchConfig[key].selected;
				if (Array.isArray(selected)) {
					this.searchConfig[key].selected = this.selection.filter(s => s.type == key).map(s => s.id)
				} else {
					this.searchConfig[key].selected = this.selection.find(s => s.type == key)?.id
				}

			}
		},
		updateUI() {
			this.$refs.templates.forEach(template => {
				template.updateUI && typeof (template.updateUI) == "function" && template.updateUI()
			});

		},
		addSelection(data, type, skipEmit) {
			if (type == "educations") {
				this.selectedEducation(data.id)
			}
			else {
				if (Array.isArray(data)) {
					data.forEach(e => {
						this.addSelection(e, type, true)
					})
				} else if (data) {
					if (!data.type) {
						data.type = type
					}
					let found = this.selection.find(s => this.$utils.equals(s, data))
					if (!found) this.selection.push(data);
				}
			}
			this.selection = Object.assign([], this.selection)

			if (type == "kw") this.$emit("remove-search")

			if (!skipEmit) this.$emit("update:modelValue", this.selection)
		},
		removeSelection(data, type, skipEmit) {
			if (Array.isArray(data)) {
				data.forEach(e => {
					this.removeSelection(e, type, true)
				})
			} else if (data) {
				if (type == "recent") this.selection = this.selection.filter(s => s.id != data.id)
				else this.selection = this.selection.filter(s => s.id != data.id || s.type != type)
			}
			if (!skipEmit) this.$emit("update:modelValue", this.selection)
		},
		hideConfig(value, type, event) {
			if (event == "show") {
				this.advancedTemplate = value
				//Object.assign({}, value);
				this.advancedTemplate.name = type
				this.advancedTemplate.title = this.searchConfig[type].info.title
				//this.advancedTemplate.data= Object.values(this.stats[type])[1]
				if (this.advancedTemplate.data.length) this.advancedTemplate.hideConfig = false
				this.show = true
			}
			else {
				//this.searchConfig.locations.selected=this.advancedTemplate.selected
				this.show = false
			}

		},
		processAutoCompleteList(list, type) {
			if (list) {
				list.forEach(el => {
					el.type = type;
					el.label = el.l; // Use el.l instead of el.label
					el.selected = false;
					if (el.i) {
						const startIndex = el.i[0];
						const endIndex = el.i[1];
						if (el.m) {
							el.m = el.m.replaceAll("<strong>", "").replaceAll("</strong>", "")
							let strongText = "<strong>" + el.m.slice(startIndex, endIndex) + "</strong>";
							el.m = el.m.slice(0, startIndex) + strongText + el.m.slice(endIndex);
						}
					}
				});
			}
		}

	}
}
</script>
<style lang="scss" scoped>
.advanced {
	&.show {
		transform: translateX(0%);
	}

	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: white;
	transform: translateX(100%);
	overflow: auto;
}

.criteria-container {
	position: relative;
	overflow: hidden;

}
</style>
