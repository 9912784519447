<template lang="">
	<div class=" d-flex justify-content-end ms-3">
		<MatIconButton
			icon="edit"
			class="text-primary  editbutton"
			v-show="$route.name == 'userDetail' && disabled"
			@click="edit('edit')"
		/>
		<MatIconButton
			icon="save"
			class="text-primary editbutton  "
			v-show="$route.name == 'userDetail' && !disabled"
			@click="edit('update')"
		/>
		<MatIconButton
			icon="cancel"
			class="text-primary editbutton me-5"
			v-show="$route.name == 'userDetail' && !disabled"
			@click="edit('cancelUpdate')"
		/>
	</div>
	<div v-bind="$attrs">
		<div :class="[headerClass]">
			<p
				class="h6 px-3 pb-1"
				v-if="title"
			>
				{{ title }}
			</p>
			<p
				v-if="text"
				class="txt-secondary body2"
				v-html="text"
			/>
		</div>
		<slot />
	</div>
</template>
<script>
export default {
	emits: ['update'],

	props:{
		title:{
			type: String,
			default: ""
		},
		text:{
			type: String,
			default: ""
		},
		headerClass:{
			type: String,
			default: ""
		},
		disabled:{
			type: Boolean,
			default: true
		},
	}, 
	methods:{
		edit(type){
			this.$emit("update",type );
		},
	},
}
</script>
<style lang="scss">
.editbutton{
	position: absolute;
	top: 6px;
} 
</style>