<template lang="">
	<div
		class=""
	>
		<div class="flex-vertical-center px-3">
			<CheckboxTree
				ref="tree"
				:data="list"
				v-model="selected"
			/>
		</div>
	</div>
</template>
<script>
import CheckboxTree from "./checkbox-tree.vue"
export default {
	components: {CheckboxTree},
	data(){
		return {
			show: true,
			list:[],
			selected: []
		}
	},
	props: {
		title: {
			type: String,
			default: () => ""
		},
		icon: {
			type: String,
			default: () => ""
		},
		hint: {
			type: String,
			default: () => ""
		},
		modelValue: {
			type: Array,
			default: () => []
		},
		filter:{
			type: String,
			default: ()=>""
		},
		data: {
			type: Array,
			default: () => []
		}
	},
	emits: ["update:modelValue", "add", "remove","hideHeader"],
	watch:{
		modelValue:{
			handler(){ 
				if(!this.$utils.equals(this.selected, this.modelValue)){
					this.selected = this.modelValue
				}
			}, 
			immediate: true
		},
		data:{
			handler(){
				this.list = this.data
			}, 
			immediate: true
		},
		selected(newVals, oldVals){
			if(!this.$utils.equals(this.selected, this.modelValue)){
				let changes = this.checkChanges(newVals, oldVals)
				this.$emit("add", changes.added)
				this.$emit("remove", changes.removed)
				this.$emit("update:modelValue", this.selected)
			}
		},
		filter() {
			// if (!this.filter) {
			// 	this.showAll()
			// } else {
			// 	if (this.title.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0) return this.showAll()
			// 	this.list = this.filterTree(this.data)
			// 	if(!this.list.length) {this.show = false; this.$emit("hideHeader", true)}
			// }
		}
	},
	methods:{
		showAll() {
			this.show = true
			this.list = Object.assign([], this.data)
			this.$emit("hideHeader", false)
		},
		filterTree(root) {
			let list = JSON.parse(JSON.stringify(root));
			let notMatchedList = list.filter(c => c.label.toLowerCase().indexOf(this.filter.toLowerCase()) < 0)
			for (let l of notMatchedList) {
				if (l.list)
					l.list = this.filterTree(l.list)
			}

			return list.filter(c => c.label.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0 || (c.list && c.list.length))
		},
		checkChanges(newVals, oldVals){
			let changes = {added: [], removed: []}

			for(let newVal of newVals){
				if(!oldVals.includes(newVal)){
					changes.added.push({id: newVal, label: this.$refs.tree.findById(this.data, newVal)?.label});
				}
			}
			for(let oldVal of oldVals){
				if(!newVals.includes(oldVal)){
					changes.removed.push({id: oldVal, label: this.$refs.tree.findById(this.data, oldVal)?.label});
				}
			}

			return changes
		}
	}
}
</script>
<style lang="scss" scoped>
</style>