<template lang="">
	<div
		ref="slider"
		class="mdc-slider"
		:class="{'mdc-slider--discrete': discrete, 'mdc-slider--tick-marks': withTickMarks, 'disabled': disabled}"
	>
		<input
			ref="input"
			class="mdc-slider__input"
			type="range"
			:min="min"
			:max="max"
			:step="step"
		>
		<div class="mdc-slider__track">
			<div class="mdc-slider__track--inactive" />
			<div class="mdc-slider__track--active">
				<div class="mdc-slider__track--active_fill" />
			</div>
			<div
				class="mdc-slider__tick-marks"
				v-if="status"
			>
				<div
					v-for="n in [0,1,2]"
					:key="n"
					class="mdc-slider__tick-mark--active"
				>
					<span
						class="step-label bottom"
					>{{ getDate(n) }}</span>
					<span
						class="step-label top"
					>{{ statusType(n) }}</span>
				</div>
			</div>
		</div>
		<div class="mdc-slider__thumb">
			<div
				class="mdc-slider__value-indicator-container"
				aria-hidden="true"
			>
				<div class="mdc-slider__value-indicator">
					<span class="mdc-slider__value-indicator-text">
						{{ value }}
					</span>
				</div>
			</div>
			<div class="mdc-slider__thumb-knob" />
		</div>
	</div>
</template>
<script>

export default {
	data() {
		return {
			value: 0,
			stepLabels: Array.from({ length: this.max - this.min + 1 }, (_, i) => i + parseInt(this.min)),
		}
	},
	computed: {

	},
	props: {
		label: {
			type: String,
			default: null
		},
		modelValue: {
			type: [Number, String],
			default: null
		},
		min: {
			type: [Number, String],
			default: 0
		},
		max: {
			type: [Number, String],
			default: 10
		},
		step: {
			type: [Number, String],
			default: 1
		},
		status: {
			type: Object,
			default: () => { }
		},
		disabled: {
			type: Boolean,
			default: false
		},
		discrete: Boolean,
		withTickMarks: Boolean

	},
	watch: {
		modelValue() {
			if (this.slider && this.modelValue != this.slider.getValue()) {
				this.slider.setValue(this.modelValue)
				this.value = this.modelValue
				this.$refs.input.setAttribute('value', this.value)
				this.layout()
			}
		},
	},
	emits: ["update:modelValue"],
	methods: {
		layout() {
			this.slider.layout()
		},
		initialize() {
			this.slider.initialize()
		},
		sliderChange(evt) {
			this.value = evt.detail.value
			this.$emit("update:modelValue", evt.detail.value)
		},
		statusType(n) {
			if (n == this.value) {
				if (n == 0) { return this.$t('Envoyer') }
				if (n == 1) return this.$t('Encours')
				if (n == 2) return this.$t('Cloturer')
			}
			else {
				if (n == 0 && this.status.sent) { return this.$t('Envoyer') }
				if (n == 1 && this.status.processed) return this.$t('Encours')
				if (n == 2 && this.status.closed) return this.$t('Cloturer')
			}

		},
		getDate(n) {
			if (n == 0) return new Date(this.status.sent).toLocaleDateString("fr")
			else if (n == 1) return this.status.processed ? new Date(this.status.processed).toLocaleDateString("fr") : ""
			else if (n == 2) return this.status.closed ? new Date(this.status.closed).toLocaleDateString("fr") : ""
		}
	},
	mounted() {
		setTimeout(() => {
			if (this.status && this.disabled) {
				
				if (this.status.processed) this.value = 1
				else if (this.status.closed) this.value = 2
				else this.value = 0
				this.$refs.input.setAttribute('value', this.value)
				this.slider = new window.mdc.slider.MDCSlider(this.$refs.slider);
				this.slider.listen("MDCSlider:change", this.sliderChange)
			} else {
				this.value = this.modelValue != null ? this.modelValue : (this.min || 0)
				this.$refs.input.setAttribute('value', this.value)
				this.slider = new window.mdc.slider.MDCSlider(this.$refs.slider);
				this.slider.listen("MDCSlider:change", this.sliderChange)
			}

		})
	},
	unmounted() {
		this.slider.unlisten("MDCSlider:change", this.sliderChange)
	}
}
</script>
<style lang="scss">
.mdc-slider {
	&.disabled {
		cursor: not-allowed;
		opacity: 0.8;
		pointer-events: none;
	}
}

.step-label {
	position: absolute;

	transform: translateX(-50%);

	&.bottom {
		top: 15px;
	}

	&.top {
		bottom: 10px;
	}
}
</style>