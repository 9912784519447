import { createStore } from 'vuex';

const store = createStore({
	state() {
		return {
			token: '',
			email: '',
		}
	},
	mutations: {
		setToken(state) {
			const session = document.cookie
				.split(";")
				.map((e) => e.trim())
				.map((e) => e.split("="))
				.find((t) => t[0] == "session" || t[0] == "__Secure-session");


			const emailCookie = document.cookie
				.split(";")
				.map((e) => e.trim())
				.map((e) => e.split("="))
				.find((t) => t[0] == "email");

			state.token = session && session.length == 2 && session[1];
			state.email = emailCookie && emailCookie.length == 2 && emailCookie[1];
			if (!state.email) {
				const storedEmail = localStorage.getItem('userEmail');
				state.email = storedEmail || '';
			}

		},
		setEmail(state, email) {
			state.email = email;
			localStorage.setItem('userEmail', email);
		},
		unsetToken(state) {
			document.cookie =
				"session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; sameSite=strict";
			document.cookie =
				"__Secure-session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; sameSite=strict; secure=true";
			document.cookie =
				"email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; sameSite=strict";
			// Remove the email from localStorage when logging out
			localStorage.removeItem('userEmail');
			state.token = '';
			state.email = '';
		}
	}
})

export default store;