<template>
	<label
		ref="matInput"
		:disabled="disabled"
		:class="{
			'mdc-text-field--with-leading-icon': icon,
			'mdc-text-field--disabled': disabled,
			'dense': dense,
			'left':left
		}"
		class="mdc-text-field mdc-text-field--outlined app-text-field border-bottom w-100"
	>
		<span class="mdc-notched-outline">
			<span class="mdc-notched-outline__leading" />
			<span class="mdc-notched-outline__notch">
				<span
					class="mdc-floating-label"
					:class="
						{'gray': disabled}"
				>{{ label }}</span>
			</span>
			<span class="mdc-notched-outline__trailing" />
		</span>
		<input
			v-bind="$attrs"
			class="mdc-text-field__input" 
			:class="{
				'ms-3': icon,
				'gray': disabled
			}"
			aria-labelledby="my-label-id"
			@input="$emit('update:modelValue', $event.target.value)"
			:disabled="disabled"
			:type="type"
			:required="required"
		>
		<i
			v-if="icon"
			:class="[`material-icons${iconStyle ? '-' + iconStyle : ''}`]"
			class="mdc-text-field__icon mdc-text-field__icon--leading"
			role="button"
		>{{ icon }}</i>
		<div
			v-if="password"
			class="mdc-icon-button"
			@click="$emit('toggleVisibility')"
		>
			<i
				v-if="type == 'text'"
				class="material-icons mdc-icon-button__icon"
			>
				visibility
			</i>
			<i
				v-if="type == 'password'"
				class="material-icons mdc-icon-button__icon"
			>
				visibility_off
			</i>
		</div>
	</label>
</template>

<script>
export default {
	name: "MatInput",
	props: {
		icon: {
			type: String,
			default: () => "",
		},
		label: {
			type: String,
			default: () => "",
		},
		iconStyle: {
			type: String,
			default: () => "",
		},
		modelValue: {
			type: [String, Number],
			default: () => "",
		},
		disabled: {
			type: Boolean,
			default: () => false,
		},
		dense: Boolean,
		password: Boolean,
		required: Boolean,
		type: {
			type: String,
			default: () => "",
		},
		name: {
			type: String,
			default: () => "",
		},
		left: {
			type: Boolean,
			default: () => false,
		},
	},
	emits: ["update:modelValue", "toggleVisibility"],
	computed: {},
	watch: {
		modelValue: {
			handler(newVal) {
				this.textField && (this.textField.value = newVal || "");
			},
		},
	},
	mounted() {
		var MDCTextField = window.mdc.textField.MDCTextField;
		this.textField = new MDCTextField(this.$refs.matInput);
		if (this.modelValue) {
			this.textField.value = this.modelValue
		}
	},
	methods: {},
};
</script>

<style lang="scss" scoped>

.mdc-text-field {
    &.dense {
        height: 40px !important;
    }
}
.mdc-text-field--outlined{
    &.dense .mdc-floating-label--float-above {
        transform: translateY(-26.5px) scale(0.75) !important;
    }
	&.mdc-text-field--with-leading-icon.dense .mdc-floating-label--float-above{
        transform: translateY(-26.5px) translateX(-32px) scale(0.75) !important;
	}
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  //margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.gray{
	color: rgba(0, 0, 0, 0.6);}
	.left .mdc-floating-label {
    left: 0 !important;
}

.left .mdc-floating-label {
    left: 0 !important;
}

.left .mdc-floating-label--float-above {
	left: 36px !important;
}

.mdc-icon-button {
    width: var(--mdc-icon-button-size, 24px);
    height: var(--mdc-icon-button-size, 24px);
    // padding: calc( (var(--mdc-icon-button-size, 40px) - var(--mdc-icon-size, 22px)) / 2 );
    display: inline-flex;
    align-items: center;
	align-self: center;
    justify-content: center;
	i{
		font-size: var(--mdc-icon-size, 22px);
		width: var(--mdc-icon-size, 22px);
		height: var(--mdc-icon-size, 22px);
	}
	&.mini{
		--mdc-icon-button-size: 40px;
		--mdc-icon-size: 20px
	}
}
</style>
