<template>
	<section
		class="my-4 flex-vertical-center"
		:class="{ 'noreduistop mt-0': $route.name == ROUTES_NAME.USERSUIVI && offer.status }"
	>
		<div
			v-if="offer.active"
			class="disabled-offer"
		/>
		<div
			class="offer-img align-self-stretch"
			:class="banner"
			:style="offer.banner ? { 'background-image': 'url(/c/images/offer' + offer.banner + ')' } : ''"
		/>
		<div
			v-if="offer.company_logo"
			class="company-logo avatar bg-white"
		>
			<img
				class="h-90 w-100"
				:src="offer.company_logo"
			>
		</div>
		<div class="p-3 flex-max">
			<div class="flex-vertical-center mb-2 position-relative">
				<p class="flex-max txt-secondary h6">
					{{ offer.company }}
				</p>
				<div class="position-absolute end-0 top-0 flex-vertical-center">
					<!--<MatIconButtonToggle
              mini
              class="text-primary"
              :v-model="data.favorite"
              onIcon="bookmark"
              offIcon="bookmark_border"
              @click="toggleFavorite"
            />
            <MatIconButton
              mini
              @click="remove"
            >
              <i
                class="fas fa-eye-slash fa-xs"
                slot="icon"
              />
            </MatIconButton>-->
					<span
						v-if="showShare && $route.name != ROUTES_NAME.USERSUIVI"
						class="d-flex"
					>
						<MatFab
							outlined
							mini
							class="fa-border iconborder me-2 "
							@click="share($event, 'fb')"
						>
							<i
								class="fa-brands fa-facebook-f text-primary xs"
								slot="icon"
							/>
						</MatFab>
						<MatFab
							outlined
							mini
							class="fa-border iconborder me-2 "
							@click="share($event, 'linkedin')"
						>
							<i
								class="fa-brands fa-linkedin-in text-primary xs"
								slot="icon"
							/>
						</MatFab>
						<MatFab
							outlined
							mini
							class="fa-border iconborder me-2 "
							@click="share($event, 'twitter')"
						>
							<i
								class="fa-brands fa-x-twitter text-primary xs "
								slot="icon"
							/>
						</MatFab>
					</span>
					<MatFab
						outlined
						mini
						class="fa-border iconborder me-2 d-none"
						@click="viewShare"
						v-if="$route.name == ROUTES_NAME.USERSUIVI"
					>
						<i
							class="fa-regular fa-trash-can text-primary xs"
							slot="icon"
						/>
					</MatFab>
					<MatIconButton
						mini
						icon="share"
						@click="viewShare"
						v-if="!offer.active && showShare && $route.name != ROUTES_NAME.USERSUIVI"
					/>
					<div
						class="pourvue"
						v-if="offer.active"
					>
						<p class="text-white mx-5 h6">
							Pourvue
						</p>
					</div>
				</div>
			</div>
			<h3 class="mb-2 h5">
				{{ offer.job_title }}
			</h3>
			<div class="d-flex">
				<div
					class="flex-vertical-center me-4"
					v-if="contract"
				>
					<span class="material-icons-outlined me-1"> description </span>
					<p
						class="fw-normal h6 m-0"
						v-if="contract"
					>
						{{ $t('contract.' + contract) }}
					</p>
				</div>
				<div
					class="flex-vertical-center me-4"
					v-if="offer.geo && location"
				>
					<span class="material-icons me-1"> place </span>
					<p
						v-if="location != 'plusieursLoc'"
						class="fw-normal h6 m-0"
					>
						{{ location }}
					</p>
					<p
						v-if="location == 'plusieursLoc'"
						class="fst-italic h6 m-0"
					>
						{{ $t('plusieursLoc') }}
					</p>
				</div>
				<div
					class="flex-vertical-center me-2"
					v-if="date"
				>
					<span class="material-icons me-1"> schedule </span>
					<p class="fw-normal h6 m-0">
						{{ $t(`Publiée`) }} : {{ date }}
					</p>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import { ROUTES_NAME } from "@/router"
import { Contract } from "@/utils/constants";
import { shortLocations } from "@/utils/utils";

export default {
	name: "OfferRowComponent",
	props: {
		offer: {
			type: Object,
			default: null,
		},
	},

	data() {
		return {
			Contract,
			ROUTES_NAME,
			showShare: false,
		}
	},
	mounted() {
		//console.log(this.offer)
	},
	computed: {
		/*favorite() {
			return this.data?.favorite
		},*/
		offerTitle() {
			return this.offer.job_title?.split(" ").join("-")
		},
		contract() {
			return this.offer?.contract?.length == 1
				? this.offer?.contract[0]
				: (this.offer?.contract?.length > 1) ? "plusieur" : null;
		},
		location() {
			return shortLocations(this.offer.geo.filter(g => g));
		},
		date() {
			const duration = this.$moment.duration(
				this.$moment().diff(this.$moment(this.offer.pub_date))
			);
			const days = Math.round(duration.asDays());
			const hours = Math.round(duration.asHours());
			const minutes = Math.round(duration.asMinutes());
			if (minutes <= 59 || hours <= 12) {
				return this.$t('aujourdhui')
			}
			return days == 1 ? this.$t('hier') : new Date(this.offer.pub_date).toLocaleDateString("fr")
		},
		banner(){
			if (this.offer.banner) return 'bg_img';
			else if (this.$siteconf.disableGradient) return 'bg-noimg-nograd'
			else return 'bg-noimg';
		}
	},
	methods: {
		saveShortid() {
			this.$toMove.shortId = this.offer.short_id
			this.$toMove.search = { "params": this.$route.params, "query": this.$route.query }
		},
		toggleFavorite(e) {
			e.preventDefault();
		},
		remove(e) {
			e.preventDefault()
		},
		viewShare(e) {
			e.preventDefault()
			this.showShare = !this.showShare

		},
		share(e, rs) {
			e.preventDefault()
			const url = this.url.origin + '/offers/' + this.offer.short_id + '/' + this.toURL(this.offer.job_title)
			this.$utils.share(rs, url)
		},
	},
};
</script>
<style lang="scss" scoped>
.offer-img {
	// height: 100%;
	width: 200px;
	clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%);
	background: black;
	background-size: cover;
}

.company-logo {
	height: 75px;
	width: 75px;
	//background: white;
	margin-left: -60px;
	z-index: 1;
}

.disabled-offer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	background: rgba(255, 255, 255, .5);
}

a {
	transition: transform .5s;
	display: block;

	&:hover {

		//color: initial !important;
		section {
			//box-shadow: 0px 6px 15px rgb(0 0 0 / 80%);
		}
	}
}

.pourvue {
	transform: rotate(45deg);
	left: -97px;
	position: absolute;
	bottom: -40px;
	background-color: black;
	z-index: 2;
}

.bg-noimg {
	background: linear-gradient(150deg, var(--primary-light) 20.16%, rgba(var(--gradient-rgb), 0.2) 79%);

}

.bg-noimg-nograd {
	background-color: var(--primary-color);
}

.bg_img {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.mdc-fab--mini {
	width: 30px;
	height: 30px;
}

.xs {
	width: 20px !important;
	height: 20px !important;
	font-size: 18px !important;
}
</style>
