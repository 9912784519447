<template lang="">
	<div
		v-if="e"
	>
		<div
			class="d-flex mb-3"
		>
			<MatInput
				dense
				v-model="e.title"
				class="me-3"
				:label="$t('exp.post')"
				:class="{'pe-none': makeDisabled}"
			/>
			<MatInput
				:class="{'pe-none': makeDisabled}"		
				dense
				v-model="e.institution"
				:label="$t('exp.entreprise')"
			/>
		</div>
		<div class="d-flex flex-vertical-center mb-3">
			<div
				:class="e.still?'w-40 d-flex':'w-60 d-flex'"
			>
				<MatDatepicker
					dense
					v-model="e.start"
					class="me-2"
					:label="$t('exp.debut')"
					monthPicker="true"
					:class="{'pe-none': makeDisabled}"
				/>
				<MatDatepicker
					v-if="!e.still"
					dense
					v-model="e.end"
					class="me-2"
					:label="$t('exp.fin')"
					monthPicker="true"
					:class="{'pe-none': makeDisabled}"
				/>
			</div>
			<div
				:class="e.still?'w-60 flex-vertical-center':'w-40 flex-vertical-center'"
			>
				<MatCheckbox
					v-model="e.still"
					:class="{'pe-none': makeDisabled}"
				/>
				<label class="body1 txt-secondary">{{ $t('exp.postActuelle') }}</label>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	emits: ['openMonthPicker'],
	data(){
		return {
			e: null
		}
	},
	watch: {
		disabled: {
			//handler() { console.log("vlock"+this.disabled); },
		},
	},
	props:{
		data:{
			type: Object,
			default: () => null
		},
		disabled:{
			type: Boolean,
			default: true
		},
	},
	computed: {
		makeDisabled(){
			return this.disabled && this.$route.name=='userDetail'
		}
	},
	methods:{
		stillChange(e){
			e.still = !e.still;
			if(e.still) e.end = null;
			else e.end = new Date()
		},
	},
	mounted() {
		this.e = this.data
	},
}
</script>
<style lang="">
  
</style>