<template lang="">
	<button
		ref="button"
		v-bind="$attrs"
		class="mdc-icon-button"
		:class="{'mini': mini,'noFocus':noFocus}"
	>
		<div
			class="mdc-icon-button__ripple"
			:style="noFocus?'--mdc-ripple-fg-size: 0px;':''"
		/>
		<span class="mdc-icon-button__focus-ring" />
		<i class="material-icons mdc-icon-button__icon mdc-icon-button__icon--on">{{ onIcon }}</i>
		<i class="material-icons mdc-icon-button__icon">{{ offIcon }}</i>
	</button>
</template>
<script>
export default {
	props:{
		onIcon:{
			type: String,
			default: null
		},
		offIcon:{
			type: String,
			default: null
		},
		modelValue: Boolean,
		mini: Boolean,
		noFocus: {
			type: Boolean,
			default: () => false,
		},
	},
	emits: ["update:modelValue"],
	methods:{
		onChange(evt){
			this.$emit("update:modelValue", evt.detail.isOn)
		}
	},	
	mounted(){
		this.iconButtonRipple = new window.mdc.iconButton.MDCIconButtonToggle(this.$refs.button)
		this.iconButtonRipple.on = this.modelValue
		this.iconButtonRipple.listen("MDCIconButtonToggle:change", this.onChange)
	},
	unmounted(){
		this.iconButtonRipple.unlisten("MDCIconButtonToggle:change", this.onChange)
	}

}
</script>
<style lang="scss" scoped>
.mdc-icon-button {
    width: var(--mdc-icon-button-size, 30px);
    height: var(--mdc-icon-button-size, 40px);
    // padding: calc( (var(--mdc-icon-button-size, 40px) - var(--mdc-icon-size, 22px)) / 2 );
    display: inline-flex;
    align-items: center;
    justify-content: center;
	i{
		font-size: var(--mdc-icon-size, 22px);
		width: var(--mdc-icon-size, 22px);
		height: var(--mdc-icon-size, 22px);
	}
	&.mini{
		--mdc-icon-button-size: 40px;
		--mdc-icon-size: 20px
	}
}
// .noFocus:hover{
// 	background-color: rgba(0,0,0,0.1) ;
// }
</style>