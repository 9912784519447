<template lang="">
	<div
		class="mdc-radio mdc-primary"
		ref="radio"
	>
		<input
			class="mdc-radio__native-control"
			type="radio"
			:value="value"
			:name="name"
			:checked="checked"
		>
		<div class="mdc-radio__background">
			<div class="mdc-radio__outer-circle" />
			<div
				class="mdc-radio__inner-circle d-none"
				ref="innerCircle"
			/>
		</div>
		<div class="mdc-radio__ripple" />
		<div class="mdc-radio__focus-ring" />
	</div>
</template>
<script>
export default {
	props:{
		checked: Boolean,
		name: {
			type: String,
			default: null
		},
		value: {
			type: String,
			default: null
		},
        
	},
	mounted(){
		new window.mdc.radio.MDCRadio(this.$refs.radio);
		setTimeout(() => {
			this.$refs.innerCircle.classList.remove("d-none")
		},100);
	}
}
</script>
<style lang="scss" scoped>
    
</style>