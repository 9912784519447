<template lang="">
	<div
		class="mt-2 ms-3 me-5"
	>
		<div class="d-flex flex-fill">
			<CheckboxTreeTemplate 
				:data="list"
				v-model="selected"
				:filter="filter"
				@add="this.$emit('add',$event)"
				@remove="this.$emit('remove',$event)"
				class="flex-fill"
			/>
			<!-- <div>
        <section
          class="map"
        >
          <Map
            :data="mapData"
            :config="mapCenter"
            @marker-click="onMarkerClick"
          />
        </section>
      </div> --> 
		</div>
	</div>
</template>
<script>
//import Map from "@/components/application/map.vue"
import CheckboxTreeTemplate from "./checkbox-tree-template.vue"

export default {
	components: { CheckboxTreeTemplate },
	data() {
		return {
			list: [],
			selected: [],
			map: null,
			mapCenter: {
				center: [46.649, 2.856],
				zoom: 5
			},
			mapData: [
				{ position: [47.931, -0.571], label: Math.ceil(Math.random() * 100), id: "_3633472187" },
				{ position: [43.405, 0.857], label: Math.ceil(Math.random() * 100), id: "_1603719621" },
				{ position: [46.347, 2.351], label: Math.ceil(Math.random() * 100), id: "_3633482187" },
				{ position: [48.836, 2.659], label: Math.ceil(Math.random() * 100), id: "_1603715621" },
				{ position: [47.145, 4.922], label: Math.ceil(Math.random() * 100), id: "_4035610632" },
			],
		}
	},
	props: {
		modelValue: {
			type: Array,
			default: () => []
		},
		filter: {
			type: String,
			default: () => ""
		},
		data: {
			type: Array,
			default: () => []
		}
	},
	emits: ["update:modelValue", "add", "remove"],
	watch: {
		modelValue:{
			handler(){ 
				if(!this.$utils.equals(this.selected, this.modelValue)){
					this.selected = this.modelValue
				}
			}, 
			immediate: true
		},
		data: {
			handler() {
				this.list = this.data
			},
			immediate: true
		},
	},
}
</script>
<style lang="scss" scoped>
section.map {
	height: 200px;
	width: 300px;
}
</style>