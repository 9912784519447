<template>
	<router-link
		v-if="offer"
		:to="{ name: ROUTES_NAME.OFFER_DETAILS, params: { offerId: offer.short_id, name: toURL(offerTitle) } }"
		@click="saveShortid"
	>
		<section>
			<div
				:class="banner ? 'bg_img' : bgcolor"
				:style="banner ? { 'background-image': 'url(/c/images/offer' + offer.banner + ')' } : ''"
				style="height: 142px;"
			>
				<img
					v-if="banner"
					:src="'c/images/offer' + banner"
					@error="handleImageError"
					style="display: none;"
				>
				<div class="flex-row d-flex justify-content-end pt-2 position-relative">
					<!--  <MatIconButtonToggle
						mini
						class=" text-primary"
						:v-model="offer.favoris"
						onIcon="bookmark_border"
						offIcon="bookmark"
						@click="toggleFavorite"
						:class="{'toggleBackground': offer.favoris}"
					/>
					<MatIconButton
						ref="thumbRef"
						mini
						icon="thumb_down_off_alt"
					/>
				-->
					<span
						v-if="showShare"
						class="d-flex"
					>
						<MatFab
							outlined
							mini
							class="fa-border iconborder me-2 "
							@click="share($event, 'fb')"
						>
							<i
								class="fa-brands fa-facebook-f text-primary "
								slot="icon"
							/>
						</MatFab>
						<!--<MatFab
						outlined
						mini
						class="fa-border iconborder me-2"
						@click="share('instagram')"
					>
						<i
							class="fa-brands fa-instagram text-primary xs"
							slot="icon"
						/>
					</MatFab>-->
						<!--<MatFab
						outlined
						mini
						class="fa-border iconborder me-2"
						@click="share($event,'yt')"
					>
						<i
							class="fa-brands fa-youtube text-primary xs"
							slot="icon"
						/>
					</MatFab>-->
						<MatFab
							outlined
							mini
							class="fa-border iconborder me-2 "
							@click="share($event, 'linkedin')"
						>
							<i
								class="fa-brands fa-linkedin-in text-primary xs"
								slot="icon"
							/>
						</MatFab>
						<MatFab
							outlined
							mini
							class="fa-border iconborder me-2 "
							@click="share($event, 'twitter')"
						>
							<i
								class="fa-brands fa-x-twitter text-primary xs "
								slot="icon"
							/>
						</MatFab>
					</span>
					<MatIconButton
						ref="shareRef"
						mini
						class="bg-gray rounded-circle m-0 me-2 small"
						icon="share"
						@click="viewShare"
					/>
				</div>
				<div
					class="flex-vertical-center pb-2 position-relative"
					style="min-height: 86px;"
				>
					<div
						class="mx-2 logo avatar bg-white"
						v-if="offer.company_logo"
					>
						<img
							class="h-90 w-100"
							:src="offer.company_logo"
						>
					</div>
					<div
						class="flex-row text-break ms-2"
						:class="{ 'ps-4': !offer.company_logo }"
					>
						<p
							:class="banner || $siteconf.cooptation ? 'text-white shadow' : 'text-black'"
							class="h6"
						>
							{{ offer.company }}
						</p>
						<h3
							class="ecli h5 pe-4"
							:class="banner || $siteconf.cooptation ? 'text-white shadow' : 'text-black'"
						>
							{{ offer.job_title }}
						</h3>
					</div>
				</div>
			</div>
			<div class="position-relative text-black">
				<div
					class="offer-details"
					style="min-height: 293px;"
				>
					<div class="flex-vertical-center ps-3 ">
						<MatIconButton
							mini
							icon="description"
						/>
						<p
							v-if="contract"
							class="h6"
						>
							{{ $t('contract.' + contract) }}
						</p>
					</div>
					<div
						class="d-flex align-items-center ps-3"
						v-if="offer.geo"
					>
						<MatIconButton
							mini
							icon="location_on"
						/>
						<p
							class="text-truncate pe-5 h6"
							v-if="location != 'plusieursLoc'"
						>
							{{ location }}
						</p>
						<p
							v-if="location == 'plusieursLoc'"
							class="fst-italic h6"
						>
							{{ $t('plusieursLoc') }}
						</p>
					</div>
					<div class="d-flex align-items-center ps-3">
						<MatIconButton
							mini
							icon="schedule"
						/>

						<p class="h6">
							{{ $t(`Publiée`) }} : {{ date }}
						</p>
					</div>
				</div>


				<div
					v-if="!$siteconf.noPreviewOfferDesc && description"
					ref="text"
					class="text offer-desc "
				>
					<div
						ref="beforeText"
						class="beforeText mx-3 mt-2 htmlDesc"
						v-html="description"
					/>
				</div>
			</div>
		</section>
	</router-link>
</template>
<script>
import { ROUTES_NAME } from "@/router"
import { Contract } from "@/utils/constants";
import { shortLocations } from "@/utils/utils";

export default {
	name: "OfferCardComponent",
	data() {
		return {
			Contract,
			ROUTES_NAME,
			showShare: false,
			//banner:null
		};
	},
	props: {
		offer: {
			type: Object, default: () => { }
		}
	},
	computed: {
		description() {
			return this.offer?.desc ? this.offer.desc.replace(/h[1-5]/g, 'p') :""
		},
		offerTitle() {
			return this.offer.job_title?.split(" ").join("-")
		},
		contract() {
			return this.offer.contract?.length == 1
				? this.offer?.contract[0]
				: (this.offer.contract?.length > 1) ? "plusieur" : "";

		},
		location() {
			return shortLocations(this.offer.geo.filter(g => g));
		},
		banner(){
			return this.offer.banner || null
		},
		bgcolor(){
			if (this.$siteconf.disableGradient) return 'bg-noimg-nograd';
			else return 'bg-noimg'
		},
		date() {
			const duration = this.$moment.duration(
				this.$moment().diff(this.$moment(this.offer.pub_date))
			);
			const days = Math.round(duration.asDays());
			const hours = Math.round(duration.asHours());
			const minutes = Math.round(duration.asMinutes());
			if (minutes <= 59 || hours <= 12) {
				return this.$t('aujourdhui')
			}
			return days == 1 ? this.$t('hier') : new Date(this.offer.pub_date).toLocaleDateString("fr")
		},
	},
	mounted() {
		//this.banner=this.offer.banner || null
	},
	methods: {
		handleImageError() {
			this.banner=null
		},
		saveShortid() {
			this.$toMove.shortId = this.offer.short_id
			this.$toMove.search = { "params": this.$route.params, "query": this.$route.query }
		},
		toggleFavorite(e) {
			e.preventDefault();
		},
		viewShare(e) {
			e.preventDefault()
			this.showShare = !this.showShare

		},
		share(e, rs) {
			e.preventDefault()
			const url = this.url.origin + '/offers' + this.offer.short_id + '/' + this.toURL(this.offer.job_title)
			this.$utils.share(rs, url)
		},


	}
}
</script>
<style lang="scss" scoped>
section {
	height: 265px;
	min-width: 235px;

	.offer-desc {
		position: absolute;
		bottom: 0;
		background-color: white;
		height: 100%;
		transform: translateY(100%);
		transition: 0.5s;
		z-index: 200;
		width: 100%;
	}

	&:hover {
		.offer-desc {
			width: 100%;
			transform: translateY(0%);

			&.text {
				max-height: 300px;
				overflow: hidden;

				.beforeText {
					&::before {
						display: block;
						content: "";
						height: 30px;
						position: absolute;
						width: 100%;
						top: 115px;
						background: linear-gradient(0deg, rgb(255, 255, 255) 79%, rgba(255, 255, 255, 1.5) 100%);
					}

				}
			}
		}
	}
}

.bg_img {
	// background-position: center;
	//background-repeat: round;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	&::before {
		filter: brightness(0.5);
		content: "";
		position: relative;

	}
}

.bg-noimg {
	background: linear-gradient(180deg, var(--primary-light) 0%, rgba(217, 217, 217, 0) 100%);
}

.bg-noimg-nograd {
	background-color: var(--primary-color);
}

.toggleBackground {
	background-color: white;
	border-radius: 50%;
}

.mdc-icon-button.mini.small {
	--mdc-icon-button-size: 30px;
	--mdc-icon-size: 15px;
	background: rgba(255, 255, 255, 0.4);

}

.shadow {
	text-shadow: black 0 0 10px, rgba(0, 0, 0, .5) 0 0 4px;
	box-shadow: none !important;

}

.blur-background {
	height: 50%;
	filter: contrast();
}

.ecli {
	width: 100%;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.logo {
	width: 80px;
	height: 80px;
	min-width: 80px;
}

.mdc-fab--mini {
	width: 30px;
	height: 30px;
}

.xs {
	width: 20px !important;
	height: 20px !important;
	font-size: 18px !important;
}

::v-deep(.htmlDesc) {
	p {
		//margin-top: 6px;
	}
}
</style>