<template>
	<div
		class="entreprise-header overflow-hidden"
		:class="[$siteconf?.banner ? 'banner' : 'bg-primary']"
	>
		<div
			class="blur-background"
			v-if="$siteconf?.banner"
		/>
		<div class="position-relative header-content container-xxl">
			<div class="d-none d-lg-block">
				<h3 class="text-white text-center mb-3 titleblur">
					{{ $route.name }}
				</h3>
				<h6 class="text-white text-center mb-3 textShadowDark">
					{{ error.text }}
				</h6>
			</div>
		</div>
	</div>
</template>
<script>
import ERRORS from "@/../public/js/errors"
export default {
	name: "ErrorComponent",
	data() {
		return {
			error: {},
		}
	},
	serverPrefetch() {
		const err = ERRORS(this.$t,this.$route)[this.$route.name];
		const code = (err && err.code) || 500;
		this.$useHead({ title: this.$route.name }, code)
	},
	mounted() {
		//this.error = ERRORS[this.$route.name];

		this.error = ERRORS(this.$t,this.$route)[this.$route.name];
		

	}
}
</script>
<style scoped lang="scss">
.entreprise-header {
	position: relative;
	background-size: cover;
}
</style>