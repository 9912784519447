<template>
	<div>
		<div class="flex-center mb-3">
			<router-link
				:to="{ name: $route.name, params: { config: 'reset-password' } }"
				v-if="form == 'reset-password'"
			>
				<TabButton
					class="tabs text-center"
					name="t1"
					:class="{ active: form == 'reset-password' }"
				>
					{{ $t('nvpassword') }}
				</TabButton>
			</router-link>
		</div>
		<form
			class="px-3 connect-form d-flex flex-column spaced-2"
			@submit="action($event)"
			:class="{ 'border': $route.name == 'userDetail' }"
		>
			<p
				v-if="changeOldpasswordError"
				class="text-danger text-center"
			>
				Une erreur s'est produite.
			</p>
			<p
				v-if="changepasswordSucces"
				class="text-success text-center"
			>
				Le mot de passe a bien été mis à jour.
			</p>

			<span v-if="form == 'changePassword'">
				<MatInput
					dense
					:label="$t('mpactuel')"
					:type="showPass ? 'text' : 'password'"
					required
					password
					name="password"
					v-model="oldpassword"
					@toggle-visibility="toggleVisibility"
				/>
			</span>
			<span>
				<MatInput
					dense
					:label="$t('nvpassword')"
					:type="showPass ? 'text' : 'password'"
					required
					password
					name="password"
					v-model="newPassword"
					@toggle-visibility="toggleVisibility"
				/>
			</span>
			<span>
				<MatInput
					dense
					:label="$t('confpassword')"
					:type="showPass ? 'text' : 'password'"
					required
					password
					name="password"
					v-model="confirmPassword"
					@toggle-visibility="toggleVisibility"
				/>
			</span>

			<span
				v-if="newPassword && !isValidPassword"
				style="font-size: 10px;"
			>{{ $t('passwordErrorRegex.1') }}
				<ul>
					<li :class="hasUpperCase ? 'text-success' : 'text-danger'">{{ $t('passwordErrorRegex.2') }}</li>
					<li :class="hasLowerCase ? 'text-success' : 'text-danger'">{{ $t('passwordErrorRegex.6') }}</li>
					<li :class="digitRegex ? 'text-success' : 'text-danger'">{{ $t('passwordErrorRegex.3') }}</li>
					<li :class="specialCharRegex ? 'text-success' : 'text-danger'"> {{ $t('passwordErrorRegex.4') }}</li>
					<li :class="newPassword.length >= 8 ? 'text-success' : 'text-danger'">{{ $t('passwordErrorRegex.5') }}
					</li>
				</ul>
			</span>
			<span
				v-if="(confirmPassword && newPassword) && !arePasswordsEqual"
				class="text-danger"
			>
				{{ $t('confpasswordError') }}
			</span>
			<div
				class="text-center"
				v-if="form == 'changePassword'"
			>
				<MatButton
					:disabled="isDisabled"
					raised
					label="Mettre à jour mon mot de passe"
				/>
			</div>
			<div
				class="text-center"
				v-if="form == 'reset-password'"
			>
				<MatButton
					:disabled="isDisabled || !this.oldpassword"
					raised
					:label="$t('forgetPwText')"
				/>
			</div>
		</form>
	</div>
</template>
<script>
import { ROUTES_NAME } from "@/router"

export default {
	data() {
		return {
			ROUTES_NAME,
			form: "changePassword",
			newPassword: "",
			oldpassword: "",
			confirmPassword: "",
			captcha: null,
			changeOldpasswordError: false,
			changepasswordSucces: false,
			showNew:false,
			showOld:false,
			showConfirm:false,
			showPass: false,
			isAttached: false
		}
	},
	computed: {
		captchaStrings(){
			return JSON.stringify({
				label: this.$t('captcha_label'),
				error: this.$t('captcha_error'),
				expired: this.$t('captcha_expired'),
				verified: this.$t('captcha_verified'),
				verifying: this.$t('captcha_verifying'),
				waitAlert: this.$t('captcha_waitAlert'),
			});
		},
		isDisabled() {
			return (
				!this.newPassword ||
				!this.confirmPassword ||
				this.newPassword !== this.confirmPassword ||
				!this.$utils.verifPasswordRegex(this.newPassword)
			);
		},
		arePasswordsEqual() {
			return this.newPassword === this.confirmPassword;
		},

		isValidPassword() {
			return this.$utils.verifPasswordRegex(this.newPassword)
		},
		hasUpperCase() {
			return this.$utils.hasUpperCase(this.newPassword)
		},
		hasLowerCase() {
			return this.$utils.hasLowerCase(this.newPassword)
		},
		digitRegex() {
			return this.$utils.digitRegex(this.newPassword)
		}
		,
		specialCharRegex() {
			return this.$utils.specialCharRegex(this.newPassword)
		}
	},
	watch: {
		$route() {
			this.form = this.$route.params.config
		},
	},
	methods: {
		async action(e) {
			e.preventDefault()
			if (this.form == "changePassword") {
				try {
					await this.$service.appservice.changeOldPassword(this.oldpassword, this.newPassword, this.captcha)
					this.changepasswordSucces = true
					this.changeOldpasswordError = false
					this.emptyForm()

				} catch (error) {
					this.changeOldpasswordError = true
					this.changepasswordSucces = false
					this.emptyForm()
				}
			} else {
				try {
					await this.$service.appservice.changeNewPassword(this.$route.params.token, this.newPassword, this.captcha)
					this.$router.push({ name: ROUTES_NAME.HOME })
				} catch (error) {
					if (error.response && error.response.status == 302 && error.response.data === "/invalid-link") this.$router.push({ name: 'INVALID' })
					else if (error.response && error.response.status == 302 && error.response.data === "/expired") this.$router.push({ name: 'EXPIRED' })
					this.changeOldpasswordError = true
				}
			}
		},
		emptyForm() {
			this.newPassword = ""
			this.oldpassword = ""
			this.confirmPassword = ""
		},
		showPassword(e,type) {
			e.preventDefault()
			if(type=='new') this.showNew = !this.showNew
			if(type=='old') this.showOld = !this.showOld
			if(type=='confirm') this.showConfirm = !this.showConfirm
		},
		toggleVisibility() {
		// e.preventDefault();
		this.showPass = !this.showPass;
	},
	},
	async mounted() {
		this.$store.commit('setToken');
		if (this.$route.params.config == "reset-password" && !this.$route.params.token) {
			this.$router.push({ name: ROUTES_NAME.HOME })
		}
		else if (this.$route.params.config == "reset-password" && this.$route.params.token) {
			try {
				await this.$service.appservice.changePassword(this.$route.params.token)
				this.$store.commit('setToken');
			} catch (error) {
				if (error.response && error.response.status == 302 && error.response.data === "/invalid-link") this.$router.push({ name: 'INVALID' })
				else if (error.response && error.response.status == 302 && error.response.data === "/expired") this.$router.push({ name: 'EXPIRED' })
			}

		}
		this.form = this.$route.params.config
	}
}
</script>
<style lang="scss" scoped>
.tabs {
	width: 200px;
	padding: 12px;
}

a {
	border-bottom: none;
}

.passIcon {
	right: 20px;
	margin-top: -4px;
}

.border {
	border-radius: 20px;
	border: 1px solid var(--action-disabled, rgba(0, 0, 0, 0.26));
}
</style>