<template lang="">
	<div
		ref="mat"
		class="mdc-form-field"
		:class="{'mdc-form-field--align-end': end}"
	>
		<slot />
		<label :for="fieldId">{{ label }}</label>
	</div>
</template>
<script>
export default {
	props:{
		end: Boolean,
		label: {
			type: String,
			default: null
		},
		id: {
			type: String,
			default: null
		}
	},
	data(){
		return {
			fieldId: this.$utils.randomId()
		}
	},
	mounted() {
		this.formfield = new window.mdc.formField.MDCFormField(this.$refs.mat);
		if(this.id) this.fieldId = this.id;
		setTimeout(()=>{
			let input = this.$el.querySelector("input")
			if(input)
				this.$el.querySelector("input").id = this.fieldId
		})
	},
}
</script>
<style lang="">
    
</style>