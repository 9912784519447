<template lang="">
	<FormContainer>
		<template #graph>
			<LoginGraph />
		</template>
		<div class="bg-white rounded-shadow p-4 login-form">
			<LoginForm class="login" />
		</div>
	</FormContainer>
</template>
<script>
import LoginForm from "@/components/application/forms/login-form.vue"
import LoginGraph from "@/components/application/forms/graphics/login-graph.vue"
export default {
	name: "LoginPage",
	components:{
		LoginForm,
		LoginGraph
	}
}
</script>
<style lang="scss" scoped> 

  .login-form{
    max-height: 100%;
    min-height: 410px;
    overflow: auto;
    .login{
      max-width: 600px;
      margin: auto
    }
  }
</style>