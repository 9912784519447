<template lang="">
	<div>
		<Chart
			v-if="plugins"
			:data="data"
			:options="options"
			:plugins="plugins"
		/>
	</div>
</template>
<script>
import Chart from "./chart.vue"
import randomColor from "randomcolor"
export default {
	components: {
		Chart
	},
	props: {
		contracts: { type: Object, default: () => { } }
	},
	data() {
		return {
			data: {},
			options: {
				responsive: true,
				cutout: "60%",
				layout: {
					padding: {
						top: 70,
						bottom: 70
					}
				},
				plugins: {
					legend: false,
					datalabels: {
						formatter: (value, context) => {
							return this.data.labels[context.dataIndex] + "\n(" + Math.floor((value / this.totalCount) * 100) + '%)';
						},
						textAlign: "center",
						anchor: "end",
						align: "end",
						offset: 10,
						color: (context) => {
							return this.data.datasets[0].backgroundColor[context.dataIndex]
						},
						labels: {
							title: {
								font: {
									weight: 'bolder',
									size: "14"
								}
							},
						}
					}
				},
				onClick: (event, chartElements) => {
					event.dataIndex = chartElements[0].index;
					this.$emit("chart-click", event)
				},
			},
			plugins: null
		}
	},
	emits: ["chart-click"],
	watch: {
		contracts: {
			handler() {
				const labels = this.contracts.map(c => c.label)
				const data = this.contracts.map(c => c.count)
				this.totalCount = data.reduce((a, b) => a + b, 0);
				this.data = {
					labels,
					datasets: [{
						label: 'Contracts',
						data,
						backgroundColor: randomColor({ luminosity: 'bright', count: data.length }),
						hoverOffset: 4
					}]
				};
			},
			immediate: true
		}
	},
	mounted() {
		import('chartjs-plugin-datalabels').then(res => {
			this.plugins = [res.default]
		})
	}
}
</script>
<style lang="">
  
</style>