
const dense = (myElement) => {
	myElement.outlined=true
	myElement.classList.add("w-100")
	const sheet = new CSSStyleSheet();
	sheet.replaceSync(`
.mdc-text-field,.mdc-select,.mdc-select--outlined .mdc-select__anchor { height: 40px !important; }
.mdc-text-field--outlined .mdc-floating-label--float-above, 
.mdc-select--outlined .mdc-select__anchor .mdc-floating-label--float-above {
transform: translateY(-28.25px) scale(1);}
`);

	const elemStyleSheets = myElement.shadowRoot.adoptedStyleSheets;
	myElement.shadowRoot.adoptedStyleSheets = [...elemStyleSheets, sheet];
}

export default {
	install: (app) => {
		app.directive("dense", dense)
	},
};