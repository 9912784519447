<template lang="html">
	<div class="d-flex justify-content-end mb-3">
		<button
			ref="btn1"
			class="flex-center border rounded-start"
			:disabled="page - 1 <= 0"
			:title="$t('pre')"
			@click="change(config?.button1?.name)"
		>
			<span class="mdc-ripple-surface" />
			<span
				class="material-icons"
				:class="[
					page - 1 > 0 ? 'text-primary' : 'text-secondary',
				]"
			>
				keyboard_double_arrow_left
			</span>
		</button>
		<!--<div class="d-flex align-items-center">
			<span
				v-for="nb in nbPage"
				:key="nb"
				class="me-3 pointer"
			> <span
				@click="change(nb)"
				:class="[ page == nb ? 'text-primary' : '']"
			>{{ nb }} </span></span>
		</div>-->
		<div class="d-flex align-items-center"> 
			<span
				v-for="(item, index) in displayedPages"
				:key="index"
				class="me-3 "
				:class="[item === '...' ? 'defautCur' : 'pointer']"
			>
				<span
					@click="change(item)"
					:class="[(page == item ? 'text-primary' : '')]"
				>{{ item }}</span> 
			</span>
		</div>
		

		<button
			ref="btn2"
			class="flex-center border rounded-end"
			:disabled="page >= nbPage"
			:title="$t('suiv')"
			@click="change(config?.button2?.name)"
		>
			<span class="mdc-ripple-surface" />
			<span
				class="material-icons"
				:class="[
					page < nbPage ? 'text-primary' : 'text-secondary',
				]"
			>
				keyboard_double_arrow_right
			</span>
		</button>
	</div>
</template>
<script>
export default {
	props: {
		config: {
			type: Object,
			default: () => ({
				button1: {
					name: "précédent",
					icon: "keyboard_double_arrow_left",
					title: "précédent",
				},
				button2: {
					name: "suivant",
					icon: "keyboard_double_arrow_right",
					title: "suivant",
				},
			}),
		},
		modelValue: { type: String, default: () => "rows" },
		nbPage: { type: Number, default: () => 1 }

	},
	emits: ["change"],
	data() {
		return {
			value: null,
			page: 1,
		};
	},
	watch: {
		modelValue: {
			handler() {
				this.value = this.modelValue;
			},
			immediate: true,
		},
		$route: {
			handler() {
				this.page = this.$route.query.page || 1
			},
			immediate: true
		}
	},
	mounted() {
	},
	computed: {
		displayedPages() {
			const currentPage = this.page;
			const totalPages = this.nbPage;
			const visiblePages =5; // Number of visible page numbers.
			const halfVisiblePages = Math.floor(visiblePages / 2);

			let startPage = Math.max(1, currentPage - halfVisiblePages);
			let endPage = Math.min(totalPages, startPage + visiblePages - 1);

			const displayed = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

			const result = [];

			// Add ellipsis if not showing the first page.
			if (startPage > 5) {
				result.push(5);
				if (startPage > 6) {
					result.push('...'); // Add ellipsis
				}
			}

			result.push(...displayed);

			// Add ellipsis if not showing the last page.
			if (endPage < totalPages) {
				if (endPage < totalPages - 1) {
					result.push('...'); // Add ellipsis
				}
				result.push(totalPages);
			}

			return result;
		},
	},
	methods: {
		change(name) {
			if (name !="...") this.$emit("change", name);
		},
	},
};
</script>
<style lang="scss" scoped>
button {
	background: transparent;
	border: border 1px var(--app-background);
	height: 40px !important;
	width: 40px !important;
	position: relative;
	padding: 0;
	overflow: hidden;
}
</style>
  