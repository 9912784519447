import utils from '@/utils/utils'
const userDataLife = 24 * 3.6e6;
const version = 1;

function encode(d) {
	if(d instanceof File) {
		const reader = new FileReader();
		return new Promise((resolve, reject) => {
			reader.onerror = reject;
			reader.onload = () => resolve({
				__encoded: 'File',
				name: d.name,
				data: reader.result
			});
			reader.readAsDataURL(d);
		})
	} else if(d instanceof Date) {
		return { __encoded: 'Date', d: d.getTime() }
	}
	return d;
}

function decode(d) {
	if(d instanceof Object && d.__encoded) switch(d.__encoded) {
		case 'File': {
			const comma = d.data.indexOf(',');
			if(comma < 0) throw new Error('bad data url');
			const type = d.data.substring(5, comma).split(';')[0];
			// deprecated but the simplest non async way of converting base64 to File
			const data = atob(d.data.substring(comma+1));
			const buf = new Uint8Array(data.length);
			for(let i = 0; i < data.length; ++i) buf[i] = data.charCodeAt(i);
			return new File([buf], d.name, { type, lastModified: new Date() });
		}
		case 'Date':
			return new Date(d.d);
		default:
			throw new Error('unhandled localStorage type ' + d.__encoded);
	}
	return d;
}

function lsGet(key) {
	return JSON.parse(localStorage.getItem(key));
}
function lsSet(key, value) {
	localStorage.setItem(key, JSON.stringify(value));
}

/** manages user data
 * @param [contactKey] {{email?: string, phone?: string}}
 * - identifing user contact information
 * @param [itemKey] {string}
 * - key of the field we want to read or write
 * @param [itemValue] {unknown}
 * - value to write to the given field
 * 
 * `userContact(contact, key)` read a key. If contact is nullish, uses the last saved contact info.
 * 
 * `userContact(contact, key, value)` sets the given value. If contact is nullish, uses the last saved contact info.
 * 
 * **deprecated** `userData()` returns the last set `contactKey`
 * 
 * **deprecated** `userData(contact)` saves contact info for reuse.
 */
export default function userData(contactKey, itemKey, itemValue) {
	//console.log('userData', contactKey, itemKey, itemValue);
	// get last contact so that we don't break the current architecture
	// TODO: don't do this
	let targetKey;
	if(contactKey) {
		targetKey = {}
		if(contactKey.email)
			targetKey.email = contactKey.email
				.replace(/\s/g, '').toLowerCase();
		if(contactKey.phone)
			targetKey.phone = contactKey.phone
				.replace(/[^0-9+]/g, '');
		if(contactKey.accept)
			targetKey.accept = contactKey.accept
	} else targetKey = lsGet('uc')?.key;
	if(!itemKey) {
		// get/set last contact so that we don't break the current architecture
		if(contactKey) lsSet('uc', {
			key: targetKey,
			exp: Date.now() + userDataLife
		});
		return targetKey;
	}

	if(!targetKey || (!targetKey.email && !targetKey.phone)) {
		if(itemValue !== undefined) return;
		console.warn('no contact key given or saved');
		return {}
	}

	// find best entry
	const ud = lsGet('ud') || [];
	let matches = [];
	for(let i = 0; i < ud.length; ++i) {
		const key = ud[i].key || {};
		const hasMail = key.email && targetKey.email;
		if(hasMail && key.email != targetKey.email)
			continue;
		const hasPhone = key.phone && targetKey.phone;
		if(hasPhone && key.phone != targetKey.phone)
			continue;
		matches.push({
			index: i,
			keys: (hasMail ? 1 : 0) + (hasPhone ? 1 : 0),
			avail: ud[i].data[itemKey] === undefined ? 0 : 1,
			date: ud[i].exp
		})
	}
	// TODO: merge some entries
	matches = matches.sort((a, b) =>
		(b.avail - a.avail) ||	// prefer that the requested key exists
		(b.keys - a.keys) ||	// prefer matching on both mail & phone
		(b.date - a.date) ); // prefer newer entries
	let match;
	if(matches.length) {
		const i = matches[0].index;
		match = ud[i];
		if(targetKey.email && targetKey.phone && matches[0].keys == 1) { // add missing phone or email
			match.key = targetKey;
			lsSet('ud', ud);
		}
	} else {
		match = {
			key: targetKey,
			exp: Date.now() + userDataLife,
			version,
			data: {}
		};
		ud.push(match);
		// we don't save now, but only if there is a set
	}
	if(itemValue === undefined)
		return decode(match.data[itemKey]);
	let encoded = encode(itemValue);
	function write(v) {
		match.data[itemKey] = v;
		lsSet('ud', ud);
	}
	if(encoded instanceof Promise)
		encoded.then(write);
	else write(encoded);

	if (contactKey && contactKey.email) {
		const dataByEmail = ud
		.filter(entry => entry.key && entry.key.email === contactKey.email)
		.map(entry => entry.data);
	
		return dataByEmail;
	}
}

userData.expire = () => {
	// clear local storage if it's from very early versions
	if(localStorage.getItem('cacheVersion') || localStorage.getItem('userData')
	|| localStorage.getItem('expired') || localStorage.getItem('shortidList'))
		localStorage.clear();
	// remove entries that are too old
	const now = Date.now();
	const ud = lsGet('ud');
	if(Array.isArray(ud)) {
		lsSet('ud', ud.filter(item => item.exp > now && item.version == version))
	}
	const uc = lsGet('uc');
	if(uc && uc.exp < now)
		localStorage.removeItem('uc');
}

userData.load = (key, def) => {
	const saved = userData(null, key);
	if(saved && !utils.equals(saved, def))
		return saved;
	return JSON.parse(JSON.stringify(def));
}

userData.merge = (target, key, def) => {
	if(!def) def = {};
	const saved = key ? userData(null, key) : userData();
	if(saved)
		for(const k in saved)
			if(saved[k])
				target[k] = saved[k];
	else
		for(const k in def)
			if(!saved[k])
				target[k] = def[k];
},
userData.clear = () => {
	const uc = lsGet('uc');
	if(uc) localStorage.removeItem('uc');
	const ud = lsGet('ud') 
	if(ud) localStorage.removeItem('ud');
}


if(typeof window != 'undefined') window.userData = userData;