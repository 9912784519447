import utils from "@/utils/utils";
import Offer from "@/services/offer.service";
import search from "@/services/search.service";
import mapUtils from "@/utils/map-utils"
import filter from "@/utils/filter"
import moment from "moment"
import Candidature from "@/services/candidature.service";
import appService from "@/services/app.service";
import userData from "@/services/userData";
import User from "@/services/user.service";
import emitter, { APP_EVENT } from "@/utils/events"

function getRootColor(){
	return getComputedStyle(document.querySelector("#app"))
		.getPropertyValue("--primary-color-rgb").split(",").map(rgb => parseInt(rgb))
}

export default {
	install: (app) => {
		const http = app.config.globalProperties.$http;
		if(!http) throw new Error('$http not yet available')
		const $service = {
			offer: new Offer(http),
			candidature: new Candidature(http),
			search,
			appservice: new appService(http),
			user: new User(http)
		};
		app.config.globalProperties.$utils = {...utils, mapUtils, getRootColor};
		app.config.globalProperties.$service = $service;
		app.config.globalProperties.$filter = filter;
		app.config.globalProperties.$moment = moment;
		app.config.globalProperties.$ssr = typeof(window) === "undefined"
		app.config.globalProperties.$event = emitter
		app.config.globalProperties.$alert = (config) => {
			emitter.$emit(APP_EVENT.SHOW_ALERT, config)
		}

		// application shared state
		// TODO: use vuex
		app.config.globalProperties.$toMove = {}; // for stuff that should *not* be global
		const cacheLife = 5 * 6e4;
		app.config.globalProperties.$cache = new Proxy({}, {
			set(store, key, value) {
				store[key] = { exp: Date.now() + cacheLife, value }
				return true;
			},
			get(store, key) {
				const v = store[key];
				if(!v) return;
				if(v.exp > Date.now()) {
					delete store[key];
					return;
				}
				return store[key].value;
			}
			// TODO: periodic garbadge collection to free memory ?
		});
		if(typeof window == 'undefined') {
			app.config.globalProperties.$userData = () => ({});
			app.config.globalProperties.$pref = {};
		} else {
			userData.expire();
			app.config.globalProperties.$userData = userData;
			app.config.globalProperties.$pref = new Proxy({}, {
				set(_, key, value) {
					localStorage.setItem('pref-' + key, JSON.stringify(value));
					return true;
				},
				get(_, key) {
					return JSON.parse(localStorage.getItem('pref-' + key));
				}
			});
		}
	},
};
