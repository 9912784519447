<template>
	<div
		class="h-100"
		v-if="$config.configLoaded"
	>
		<Header />
		<main>
			<router-view />
		</main>
		<Footer />
	</div>
	<Confirm
		ref="confirm"
		:config="confirmConfig"
		style="z-index:100000"
	/>
</template>
<script>
import Confirm from "./components/application/confirm-dialog.vue";
import Header from "@/components/application/header.vue"
import Footer from "@/components/application/footer.vue";
import { APP_EVENT } from "./utils/events";
export default {
	name: "App",
	components: { Header, Footer, Confirm },
	data() {
		return {
			confirmConfig: { type: "alert" },
		};
	},
	mounted(){
		this.$event.$on(APP_EVENT.SHOW_ALERT, this.showAlert);
		this.$store.commit('setToken')
	},

	methods: {
		showAlert(evt) {
			this.confirmConfig = {};
			for (let key in evt) {
				this.confirmConfig[key] = evt[key];
			}
			this.confirmConfig.type = "alert";
			this.$refs.confirm.open();
		},
	},
};
</script>
<style scoped lang="scss">
main {
	min-height: 100%;
    position: relative;
	@media (min-width: 991px) {
		padding-top: 60px;
	}
	
}
</style>
