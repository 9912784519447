<template lang="">
	<div
		ref="matAutocomplete"
		class="position-relative w-100"
		v-bind="$attrs"
	>
		<MatInput
			ref="autocompleteInput"
			v-model="text"
			:label="label"
			:disabled="disabled"
			@input="autocomplete"
			@click="autocomplete"
		/>
		<MatMenu
			ref="menu"
			type="select"
			class="select-dropdown"
		>
			<template
				v-for="item in items"
				:key="item"
			>
				<slot
					:item="item"
				/>
			</template>
		</MatMenu>
	</div>
</template>
<script>
import MatMenu from "./mat-menu.vue";
import MatInput from "./mat-input.vue";
export default {
	components: { MatMenu, MatInput },
	props: {
		modelValue: {
			type: [Number, String, Array],
			default: () => "",
		},
		label: {
			type: String,
			default: () => "",
		},
		list:{
			type: Array,
			default: () => []
		},
		startFilter:{
			type: [Number, String],
			default: () => 3
		},
		config: { type: Object, default: () => {} },
		disabled: {
			type: Boolean,
			default: () => true
		}
	},
	emits: ['update:modelValue','change','autocomplete'],
	data() {
		return {
			items: [],
			selected: null,
			text: "",
		};
	},
	watch: {
		selected(newVal){
			this.$emit("update:modelValue", newVal);
			this.$emit("change", newVal)
		},
		modelValue: {
			handler(){
				if(this.selected == this.modelValue) return;
				this.selected = this.modelValue;
				this.changeText()
			}, 
			immediate: true}
	},
	mounted() {
		this.$refs.menu.menu.setDefaultFocusState(0);
		this.$refs.menu.menu.setAnchorElement(this.$refs.matAutocomplete);
		this.$refs.menu.menu.setAnchorCorner(9);
		this.selected = this.modelValue;
		this.$refs.menu.menu.listen("MDCMenu:selected", this.menuSelected);
	},
	beforeUnmount(){
		this.$refs.menu.menu.unlisten("MDCMenu:selected", this.menuSelected);
	},
	methods: {
		menuSelected(e) {
			this.selected = this.getValue(this.items[e.detail.index - 1]);
			this.changeText();
		},
		clear(){
			this.selected = null;
			this.changeText()
		},
		autocomplete(e) {
			if (this.autocompleteTimeout) {
				clearTimeout(this.autocompleteTimeout);
			}
			this.selected = null
			if (e.target.value.length < parseInt(""+this.startFilter)) return;
			this.autocompleteTimeout = setTimeout(async () => {
				this.$emit("autocomplete", e.target.value);
				await this.loadItems(e.target.value);
				this.$refs.menu.menu.open = true;
			}, 500);
		},
		changeText() {
			if (!this.selected) return (this.text = "");
			if (this.config?.getLabel) {
				return (this.text = this.config.getLabel(this.selected));
			}
			this.text = this.selected.label || this.selected;
		},
		getValue(item) {
			if (!item) return null;
			if (this.config?.getValue) {
				return this.config.getValue(item);
			}
			return item;
		},
		async loadItems(key) {
			if(this.list){
				return (this.items = this.list
					.filter(e => e.label ? 
						e.label.toLowerCase().includes(key.toLowerCase()) : 
						e.toLowerCase().includes(key.toLowerCase())
					)
				)
			}
			if (!this.config?.query) return;
			if (typeof this.config.query === "function") {
				return (this.items = await this.config.query(key));
			}

		},
	},
};
</script>
<style lang="scss" scoped>
</style>