<template>
	<button
		v-for="sub in actions"
		:key="sub.label"
		class="mdc-fab mdc-fab--mini sub-fab position-fixed outlined"
		:class="{ show: showActions }"
		:style="sub.style"
		@click="onsubmenuClick(sub)"
	>
		<div class="mdc-fab__ripple" />
		<span class="material-icons mdc-fab__icon text-secondary">
			<i
				:class="sub.icon"
				slot="icon"
			/>
		</span>
	</button>
	<button
		ref="matFabButton"
		
		class="mdc-fab mdc-master"
		:class="[buttonStyle, outlinedStyle, ...positions, 'position-' + position]"
		v-bind="$attrs"
		@click="toggleSubmenu"
	>
		<div class="mdc-fab__ripple" />
		<span
			class="material-icons mdc-fab__icon"
			:class="{ show: !showActions }"
		>{{ icon }}</span>
		<span
			class="material-icons mdc-fab__icon"
			:class="{ show: showActions }"
		>clear</span>
		<!-- <div class="mdc-fab__touch"></div> -->
	</button>
</template>

<script>
const space = 3;
export default {
	name: "MatFabButton",
	props: {
		icon: {
			type: String,
			default: () => "",
		},
		matStyle: {
			type: String,
			default: () => "",
		},
		outlined: Boolean,
		position: {
			type: String,
			default: () => "bottom-start",
		},
		actions: {
			type: Array,
			default: () => [],
		},
	},
    emits: ["share-opened"],
	data() {
		return {
			showActions: false,
		};
	},
	computed: {
		positions() {
			if (this.position == "static" || this.position == "relative") {
				return ["position-" + this.position];
			}
			return [
				"position-absolute",
				"m-3",
				...this.position.split("-").map((p) => p + "-0"),
			];
		},
		buttonStyle() {
			if (this.matStyle) {
				return "mdc-fab--" + this.matStyle;
			}
			return "";
		},
		outlinedStyle() {
			//if (this.outlined) {
				return "outlined";
		//	}
			//return "";
		},
	},
	watch: {
		showActions() {
			for (let i = 0; i < this.actions.length; i++) {
				const action = this.actions[i];
                if(i==0) {
                    action.style = {
					left: "0",
					bottom: this.showActions ? (i + 2) * space + "rem" : 0,};
                }
                if (i==1) {
                    action.style = {
					left: "6rem",
					bottom: "0",
                }
            }
                if (i==2) {
                    action.style = {
					left: "4rem",
					bottom: "4rem",
                }
			
        }
    }
		},
	},
	mounted() {
		window.mdc.ripple.MDCRipple.attachTo(this.$refs.matFabButton);
		this.showActions = false;
		if (this.actions && this.actions.length) {
			document.addEventListener("click", this.hideSubmenu);
		}
	},
	beforeUnmount() {
		if (this.actions && this.actions.length) {
			document.removeEventListener("click", this.hideSubmenu);
		}
	},
	methods: {
		toggleSubmenu(evt) {
			evt.stopPropagation();
			if (this.actions && this.actions.length) {
				this.showActions = !this.showActions;
			}
          this.$emit("share-opened" )
		},
		onsubmenuClick(action) {
			this.showActions = !this.showActions;
			if (action && action.onClick && typeof action.onClick === "function") {
				action.onClick();
			}
		},
		hideSubmenu() {
			this.showActions = false;
           // this.$emit("shareOpened" )
		},
	},
};
</script>

<style scoped lang="scss">
.mdc-fab {
    width: 40px !important;
    height: 40px !important;
	background: var(--primary-color);
	.mdc-fab__icon {
		color: white;
	}
	&.outlined {
		background: white;
		.mdc-fab__icon {
			color: var(--primary-color) !important;
		}
		&.sub-fab {
			margin: 1.5rem;
			//transition: 0.5s;
			opacity: 0;
			visibility: hidden;
			.mdc-fab__icon {
				color: rgb(var(--bs-secondary-rgb)) !important;
			}
			&.show {
				visibility: visible;
				opacity: 1;
			}
		}
	}
	&.mdc-fab--micro {
		height: 30px;
		width: 30px;
	}
}
.mdc-master {
	.mdc-fab__icon {
		position: absolute;
		visibility: hidden;
		opacity: 0;
		transition: 0.5s;
		&.show {
			visibility: visible;
			opacity: 1;
		}
	}
//	flex-shrink: 0;
}
</style>
