<template>
	<FormContainer
		v-if="show"
		v-bind="$attrs"
		class="candidate-container show"
		:containerStyle="null"
	>
		<template #graph>
			<component
				:is="config.graphComponent"
				:showtext="true"
			/>
		</template>
		<div>
			<div class="position-absolute app-shadow w-100 candidate-form-content mt-3 mt-lg-0 ">
				<div class="pt-5 px-3 px-lg-5 pb-3 flex-max position-relative overflow-auto">
					<div class="position-relative dynamic-form">
						<Transition
							name="slide-up"
							mode="out-in"
						>
							<div>
								<component
									:is="config.formComponent"
									v-bind="config.props"
								/>
							</div>
						</Transition>
					</div>
				</div>
			</div>
		</div>
	</FormContainer>
</template>
<script>
import { shallowRef } from "@vue/reactivity";
import Dynamic from "@/components/application/forms/dynamic-form.vue";
import AdvancedFormGraph from "@/components/application/forms/graphics/advanced-form-graph.vue";
import { AlertLevel } from "@/components/application/confirm-dialog"

import { ROUTES_NAME } from "@/router"

export default {
    data() {
        return {
            config: {
                step: "form",
                formComponent: shallowRef(Dynamic),
                props: {
                    data: {},
                    formAns: [],
                    onNextClick: () => this.soumettreAuto(true),
                    onPrevClick: () => this.step--
                },
                graphComponent: shallowRef(AdvancedFormGraph),

                name: "3Formulaire",
            },
            show:false

        }

    },
    async mounted() {
        if (!this.$route.params.jwt) this.$router.push({ name: 'INVALID', params: { config: "form" }, })
        else {
            try {
                
                this.dynForm = await this.$service.candidature.getFormByToken(this.$route.params.jwt)
                this.config.props.data = this.dynForm.form
                if (this.config.props.data && this.config.props.data.fields) {
                    this.config.props.data.fields.forEach((field, index) => {
                        field.key = index;
                    });
                }
                this.show=true
            }
            catch (error) {
                if (error.response && error.response.status == 400) this.$router.push({ name: 'INVALID', params: { config: "form" }, })
                else if (error.response && error.response.status == 401) this.$router.push({ name: 'EXPIRED', params: { config: "form" }, })
                else if (error.response && (error.response.status == 404 || error.response.status == 500)) {
                    this.$alert({
                        title: this.$t("formulaire"), 
                        text: this.$t("errorpost"),
                        level: AlertLevel.error,
                    });
                    this.$router.push({
                        name: ROUTES_NAME.HOME,
                    });
                }
                else if(error.response && error.response.status == 403)  {
                    this.$alert({
                        title: this.$t("formulaire"), 
                        text: this.$t("formsend"),
                        level: AlertLevel.info,
                       
                    });
                    this.$router.push({
                        name: ROUTES_NAME.HOME,
                    });
                }


            }
        }

    },
}
</script>
<style lang="scss" scoped>
.candidate-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1002;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transition: 0.2s;
    border-radius: 10px;

    &.show {
        transform: scale(1);
    }
}

.dynamic-form {
    max-width: 600px;
    margin: auto;
}

.candidate-form-content {
    background: white;
    border-radius: var(--border-radius);
    overflow: auto;
    max-height: 100%;
    min-height: 400px;

    @media (max-width: 991px) {
        height: 100% !important;
    }

    // &.centred{
    //   border-top-left-radius: 0;
    //   border-top-right-radius: 0;
    // }
}


.empty {
    background-color: transparent !important;
    border-color: var(--primary-color) !important;
    color: var(--primary-color);
    cursor: default
}

.validate {
    height: 435px !important;
}
</style>