<template lang="">
	<div class="mdc-touch-target-wrapper">
		<div
			ref="matCheckbox"
			class="mdc-checkbox mdc-checkbox--touch mdc-primary"
		>
			<input
				:id="id"
				ref="input"
				type="checkbox"
				:checked="modelValue"
				:disabled="disabled"
				class="mdc-checkbox__native-control"
				@change="change"
				:value="value"
				:data-indeterminate="indeterminate"
			>
			<div class="mdc-checkbox__background">
				<svg
					class="mdc-checkbox__checkmark"
					viewBox="0 0 24 24"
				>
					<path
						class="mdc-checkbox__checkmark-path"
						fill="none"
						d="M1.73,12.91 8.1,19.28 22.79,4.59"
					/>
				</svg>
				<div class="mdc-checkbox__mixedmark" />
			</div>
			<div class="mdc-checkbox__ripple" />
		</div>
	</div>
</template>
<script>

export default {
	props: {
		modelValue: Boolean,
		id: {
			type: String,
			default: () => "",
		},
		stopPropagation: {
			type: Boolean,
			default: () => false,
		},
		value: {
			type: String,
			default: () => "",
		},
		disabled: {
			type: Boolean,
			default: () => false,
		},
		indeterminate: Boolean
	},
	emits: ["update:modelValue", "change"],
	mounted() {
		var MDCCheckbox = window.mdc.checkbox.MDCCheckbox;
		this.checkbox = new MDCCheckbox(
			this.$refs.matCheckbox
		);
	},
	watch:{
		indeterminate(){
			this.checkbox.indeterminate = this.indeterminate
		}
	},
	methods: {
		toggle() {
			this.checkbox.checked = !this.checkbox.checked;
		},
		getChecked() {
			return (this.checkbox && this.checkbox.checked) || false;
		},
		setChecked(checked) {
			this.checkbox.checked = checked;
		},
		change(e) {
			if (this.stopPropagation) {
				e.stopPropagation();
			}
			this.$emit("update:modelValue", this.$refs.input.checked);
			this.$emit("change", e);
		},
	},
};
</script>
<style lang="scss" scoped>

</style>
