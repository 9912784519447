<template>
	<button
		id="selected-switch"
		ref="switch"
		class=" mdc-switch"
		:class="[
			selected ? 'mdc-switch mdc-switch--selected' : 'mdc-switch  mdc-switch--unselected',
		]"
		type="button"
		role="switch"
		:aria-checked="[
			selected ? 'true' : 'false',
		]"
		:disabled="disabled"
	>
		<div class="mdc-switch__track" />
		<div class="mdc-switch__handle-track">
			<div class="mdc-switch__handle">
				<div class="mdc-switch__shadow">
					<div class="mdc-elevation-overlay" />
				</div>
				<div class="mdc-switch__ripple" />
				<div class="mdc-switch__icons">
					<svg
						class="mdc-switch__icon mdc-switch__icon--on"
						viewBox="0 0 24 24"
					>
						<path d="M19.69,5.23L8.96,15.96l-4.23-4.23L2.96,13.5l6,6L21.46,7L19.69,5.23z" />
					</svg>
					<svg
						class="mdc-switch__icon mdc-switch__icon--off"
						viewBox="0 0 24 24"
					>
						<path d="M20 13H4v-2h16v2z" />
					</svg>
				</div>
			</div>
		</div>
	</button>
</template>
<script>
import { MDCSwitch } from '@material/switch';
export default {
	props:{
		selected: {
			type: Boolean,
			default: true
		},
		disabled:{
			type: Boolean,
			default: false
		}
	},
	mounted() {
		this.switch = new MDCSwitch(this.$refs.switch);
	},
	methods: {
	},
}
</script>
<style lang="">
	
</style>