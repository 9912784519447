// import Utils from "@/utils/utils";
// import moment from "moment";

// const offers = []
// for(let i = 0; i < 10; i++){
// 	offers.push(
// 		{
// 			id: Utils.randomId(),
// 			image: "/s/images/offer_image.png",
// 			companyLogo: "/s/images/aigle_logo.png",
// 			companyName: "AIGLE",
// 			title: "UX UI designer",
// 			contract: ["Alternance", "CDI"].slice(Math.round(Math.random())),
// 			location: ["Paris", "Nice"].slice(Math.round(Math.random())),
// 			creationDate: moment()
// 				.add(-(parseInt(Math.random() * 19) + 1), "day")
// 				.toDate(),
// 			favorite: Math.round(Math.random()) ? true : false,
// 			active: Math.round(Math.random()) ? true : false
		
// 		})
// }

export default class Offer {
	constructor(http) { this.http = http }
	findAll(params) {
		return this.http.get(`offers`, {params});
	}
	findOne(offerId) {
		return this.http.get(`offer/${offerId}`)
	}
	autoComplete(string) {
		let params ={ "str" : string}
		return this.http.get(`complete`, {params})
	}
	info(longId) {
		return this.http.get(`offer/${longId}/info`)
	}
}
