<template lang="">
	<ul
		ref="treeRoot"
		class="first-level m-0"
		:class="{show: show}"
	>
		<li
			v-for="l1 in list"
			:key="l1.id"
			class="body1"
			:class="{active: l1.selected}"
		>
			<div class="flex-vertical-center">
				<MatFormfield
					v-if="l1.count"
					:label="`${l1.label} (${l1.count })`"
				>
					<MatCheckbox
						v-model="l1.selected"
						:indeterminate="l1.indeterminate"
						@change="select($event, l1)"
					/>
				</MatFormfield>
				<MatFormfield
					v-else
					:label="`${l1.label}`"
				>
					<MatCheckbox
						v-model="l1.selected"
						:indeterminate="l1.indeterminate"
						@change="select($event, l1)"
					/>
				</MatFormfield>
				<span
					class="material-icons-outlined"
					v-if="l1.list && l1.list.length"
					@click="l1.show = !l1.show"
				>
					{{ l1.show ? 'expand_less' : 'expand_more' }}
				</span>
			</div>
			<template v-if="l1.list && l1.list.length">
				<div class="child-tree">
					<CheckboxTree
						v-model="selected"
						:data="l1.list"
						:level="level+1"
						@selection-change="this.updateSelection($event.checked, $event.element)"
						:childNode="true"
						:show="!!l1.show"
					/>
				</div>
			</template>
		</li>
	</ul>
</template>
<script>

export default {
	name: "CheckboxTree",
	mounted() {
	},
	data() {
		return {
			list: [],
			selected: [],
		}
	},
	props: {
		modelValue: {
			type: Array,
			default: () => []
		},
		data: {
			type: Array,
			default: () => []
		},
		level: {
			type: Number,
			default: () => 0
		},

		childNode:{
			type: Boolean,
			default: false
		},
		show:{
			type: Boolean,
			default: true
		}
	},
	emits: ["update:modelValue", "add", "remove", "selectionChange"],
	watch: {
		data: {
			handler() {
				this.list = Object.assign([], this.data)
				this.setSelected(this.modelValue)
			},
			immediate: true
		},
		modelValue: {
			handler() {
				if (!this.$utils.equals(this.modelValue, this.selected)) {
					this.selected = this.modelValue
					if(!this.childNode)
						this.deselect()
					this.setSelected(this.modelValue)
				}

			},
			immediate: true
		}
	},
	methods: {
		updateSelection(value, e){
			if(!this.childNode){
				//this.checkParent(value, e);
				this.selected = this.findSelected(this.list)
				this.$emit("update:modelValue", this.selected)
			}else{
				this.$emit("selectionChange", {checked: value, element: e})
			}
		},
		select(evt, e, recursive) {
			if (!e) return;
			let value = evt.currentTarget.checked
			e.selected = value
			e.indeterminate = false
			e.list?.forEach(sl => {
				this.select(evt, sl, true)
			})
			if (!recursive) {
				this.updateSelection(value, e)
				this.$emit("selectionChange", {checked: value, element: e})
			}
		},
		checkParent(value, e) {
			let parent = this.getParent(null, e);
			if (!parent) return;
			// let count = parent.list?.filter(sl => sl.selected == true).length;
			if (!value) {
				// let found = count > 0
				// parent.indeterminate = found
				parent.selected = false
			}
			//  else {
			// 	let full = count == parent.list.length;
			// 	parent.selected = true
			// 	parent.indeterminate = !full
			// }
			this.checkParent(value, parent);
		},
		getParent(root, e) {
			let list = root ? root.list : this.list
			if (!list) return null;
			let parent = null;
			for (var sl of list) {
				if (e == sl) {
					parent = root;
				} else {
					parent = this.getParent(sl, e);
				}
				if (parent != null) {
					break;
				}
			}
			return parent
		},
		findById(list, id) {
			if (!list) return null;
			let result = null
			for (let e of list) {
				if (e.id == id) result = e;
				else result = this.findById(e.list, id);
				if (result) break;
			}
			return result;
		},
		findSelected(list) {
			if (!list?.length) return []
			let result = [];
			for (let e of list) {
				if (e.selected) result.push(e.id)
				else if (e.list) result.push(...this.findSelected(e.list))
			}
			return result
		},
		deselect() {
			this.list.forEach(l => {
				this.select({ currentTarget: { checked: false } }, l, true)
			})
		},
		setSelected(list) {
			
			list.forEach(id => {
				let e = this.findById(this.list, id)
				this.select({ currentTarget: { checked: true } }, e, true)
			})
		}

	}
}
</script>
<style lang="scss" scoped>
ul {
	overflow: hidden;
	transition: 1s;
	max-height: 0px;

	&.show {
		max-height: 1000px;
	}
}
.child-tree{
	padding-left: 1.25rem
}
ul.first-level {
	padding-left: 0
}

ul.second-level {
	padding-left: 1.25rem;
}

ul.third-level {
	padding-left: 2.5rem;
}

li {
	list-style: none;
	// margin-bottom: .75rem;
	cursor: pointer;

	&.active {
		color: var(--link-active);
	}
}

MatFormfield {
	--mdc-typography-body2-font-size: 16px;
	--mdc-typography-body2-line-height: 24px;
	--mdc-typography-body2-letter-spacing: 0.15px;
	--mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.6);
}
</style>
  