<template lang="">
	<FormContainer>
		<template #graph />
		<div class="bg-white rounded-shadow p-4 login-form">
			<ForgetPasswordForm class="login" />
		</div>
	</FormContainer>
</template>
<script>
import ForgetPasswordForm from "@/components/application/forms/forget-password-form.vue"
//import LoginGraph from "@/components/application/forms/graphics/login-graph.vue"
export default {
	name: "LoginPage",
	components:{
		ForgetPasswordForm,
		//LoginGraph
	}
}
</script>
<style lang="scss" scoped>
  .login-form{
    max-height: 100%;
    min-height: 410px;
    overflow: auto;
    .login{
      max-width: 600px;
      margin: auto
    }
  }
</style>