<template lang="">
	<div
		v-if="show && data?.length>0 "
		class=""
	>
		<div class="d-flex h-spaced-3 ms-3 mt-2">
			<div
				v-for="c in config"
				:key="c.id"
				:class="[
					c.show ? 'py-2 px-3 block  position-relative' : 'd-none',
					c.selected ? 'checked':'' ]"
				@click="select(c)"
			>
				<span
					v-if="c.show"
					class="mdc-ripple-surface"
				/>
				<div
					v-if="c.show"
					class="mb-2 pt-1"
				>
					<SvgIcon
						size="40"
						:svgClass="c.selected ? 'fill-primary':'fill-secondary'"
						:icon="c.icon"
					/>
				</div>
				<span
					v-if="c.show && c.label==''"
					class="text-secondary input-text"
				>
					{{ $t('tt.'+c.title) }}
				</span>
				<span
					v-if="c.show && c.label!=''"
					class="text-secondary input-text"
					v-html="c.label"
				/>
				<div
					v-if="c.show && c.count"
					class="chip text-secondary text-end"
				>
					({{ c.count }})
				</div>
			</div>
		</div>
	</div>
</template>
<script>

let config = [{
	id: "forbidden",
	title: "forbidden",
	icon: "enterprise",
	count: 0,
	show: false,
	label: ""

}, {
	id: "occasional",
	title: "occasional",
	icon: "home-enterprise",
	count: 0,
	show: false,
	label: ""


}, {
	id: "full",
	title: "full",
	icon: "home",
	count: 0,
	show: false,
	label: ""


}, {
	id: "regular",
	title: "regular",
	icon: "home-enterprise",
	count: 0,
	show: false,
	label: ""


}]
import { Remote } from "@/utils/constants";
export default {
	data() {
		return {
			Remote,
			show: true,
			title: "Télétravail",
			selected: [],
			config: Object.assign([], config),
			nbr: 0
		}
	},
	props: {
		filter: {
			type: String,
			default: () => ""
		},
		modelValue: {
			type: Array,
			default: () => []
		},
		data: {
			type: Object,
			default: () => { }
		}
	},
	watch: {
		data: {
			handler() {
				this.show = false
				this.config = Object.assign([], config)
				if (this.data.length > 0) {
					this.config.forEach(el => {
						el.show = false
					})

					this.data[0].forEach(el => {
						this.config.map((x, index) => {
							if (x.id == el.id) {
								this.config[index].count = el.count;
								if (el.label) this.config[index].label = el.label
								else this.config[index].label = ""
								this.config[index].show = true
								this.show = true
							}
						})
					})
					this.$emit("hideHeader", !this.show)
				}

			},
			//immediate: true
		},
		modelValue: {
			handler() {
				if (!this.$utils.equals(this.selected, this.modelValue)) {
					this.selected = this.modelValue || []
					this.updateSelection()
				}
			},
			immediate: true
		},
		filter() {
			if (!this.filter) {
				this.showAll()
			} else {
				if (this.title.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0) return this.showAll()
				this.config = config.filter(c => c.title.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0)
				if (this.config.length == 0) { this.show = false; this.$emit("hideHeader", true) }
			}
		},

	},
	emits: ["update:modelValue", "add", "remove", "hideHeader"],
	methods: {
		showAll() {
			//this.show = true
			this.config = Object.assign([], config)
			if (config.length > 0) { this.show = true; this.$emit("hideHeader", false) }

		},
		updateUI() {
		},
		select(ce) {
			ce.selected = !ce.selected
			this.selected = this.config.filter(c => c == ce).map(c => c.id)
			this.$emit("update:modelValue", this.selected)
			if (ce.selected) {
				this.$emit("add", { id: ce.id, label: Remote[ce.title] })
			} else {
				this.$emit("remove", { id: ce.id, label: Remote[ce.title] })
			}
		},
		updateSelection() {
			this.config.forEach(c => c.selected = false)
			for (let s of this.selected) {
				let sc = this.config.find(c => c.id == s);
				if (sc) sc.selected = true
			}
		}
	},
	mounted() {
		this.updateUI()
	}
}
</script>
<style lang="scss" scoped>
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";

.block {
	border-radius: 10px;
	border: 1px solid $gray-500;

	&.checked {
		border: 3px solid var(--primary-color);

		& * {
			color: var(--primary-color) !important
		}
	}

	box-sizing: border-box;
}
</style>
  