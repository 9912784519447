<template lang="">
	<ul class="navbar-nav m-3 d-block ">
		<span v-if="$siteconf.accounts">
			<span
				v-if="isAuthenticated"
				class="d-flex flex-vertical-center"
			>
				<li class="nav-item d-flex flex-vertical-center ">
					<router-link
						class="body1 me-3 text-decoration-none"
						:to="{ name: ROUTES_NAME.USERSUIVI, params: { config : 'candidature'}, query: { type : 'Tous'} }"
					>
						<span class="d-flex flex-column">
							<span class="material-icons-outlined text-center">
								content_paste_search
							</span> <span>{{ $t('suivi') }}</span>
						</span>
					</router-link>
					<router-link
						class="body1  text-decoration-none"
						:to="{ name: ROUTES_NAME.userDetail, params: {config : 'personalInfo'}}"
					>
						<span class="d-flex flex-column">
							<span class="material-icons-outlined text-center">
								person_outline
							</span> <span>{{ $t('monCompte') }}</span>
						</span>
					</router-link>

					<router-link
						@click="deconnect()"
						class="body1 p-4 text-decoration-none"
						:to="{ name: ROUTES_NAME.HOME}"
					>
						<span class="material-icons-outlined">
							logout
						</span>
					</router-link>
				</li>
			</span>

			<span
				v-else
				class="d-flex justify-content-center"
			>
				<li class="nav-item">
					<router-link
						class="body1 me-5 under w-100 py-4  d-flex"
						:to="{ name: ROUTES_NAME.LOGIN ,params: { config: 'signin' } }"
					>
						{{ $t('connecterL') }}
					</router-link>
				</li>
				<li class="nav-item">
					<router-link
						class="body1 me-5 under w-100 py-4  d-flex"
						:to="{ name: ROUTES_NAME.LOGIN ,params: { config: 'signup' } }"
					>
						{{ $t('inscritL') }}
					</router-link>
				</li>
			</span>
			
		</span>
	</ul>
</template>
<script>
import { ROUTES_NAME } from "@/router";

export default {
	name: "HeaderComponent",
	components: {},
	props: {
		logo: {
			type: Boolean, default: () => false
		}
	},
	data() {
		return {
			ROUTES_NAME,
			scrollpx: 0
		};
	},
	computed: {
		isAuthenticated(){
			return this.$store.state.token;
		}
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	},
	unmounted() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		handleScroll() {
			this.scrollpx = window.scrollY;
		},
		
		async deconnect() {
			await this.$service.appservice.logout();
			this.$store.commit('unsetToken');
		}
	}
}
</script>
<style lang="scss" scoped>
header {
	height: 60px;

	@media (max-width: 991px) {
		background-color: transparent !important;
	}

	img {
		height: 50px;

		@media (max-width: 370px) {
			height: 40px;
		}
	}

	z-index: 1001;
}

.navbar-toggler {
	border: none !important;

	&:hover {
		border: none !important;
	}

	.navbar-toggler-icon {
		background-color: white !important;
	}
}
.material-icons-outlined{
  text-decoration: none !important;
}
</style>
