<template lang="">
	<div v-if="word">
		<div class="d-flex my-2">
			<MatButton
				class="pill me-1 search-option text-black"
				outlined
				@click="add()"
			>
				<div class="element-to-select d-flex flex-center">
					{{ word }}
				</div>
			</MatButton>
		</div>
	</div>
</template>
<script>


export default {
    data() {
        return {
            word: "",
            show: true,

            selected: [],
            nbr: 0
        }
    },
    props: {
        data: {
            type: Object,
            default: () => { }
        }
    },
    watch: {
        data: {
            handler() {
                this.word = this.data
            },
            //immediate: true
        },
    },
    emits: ["update:modelValue", "add", "remove", "hideHeader"],

    mounted() {

    },
    methods: {
        add() {
            if (this.word) {
                this.$emit("add", { id: this.word, label: this.word })
            }
        }
    }
}
</script>
<style lang="scss" scoped></style>
  