export const imageErrorHandling = {
    data() {
      return {
        errorImage: false,
      };
    },
    methods: {
      handleImageError(fallbackImageSrc) {
        // Handle the image error here
        this.errorImage = true;
        if( this.$refs?.Image?.src) this.$refs.Image.src = fallbackImageSrc;
      },
      preloadImage(imageSrc, fallbackImageSrc) {
        const imageElement = new Image();
        imageElement.src = imageSrc;
  
        imageElement.onload = () => {
          // Once the image is preloaded, check its dimensions
          if (
            imageElement.naturalWidth === 1 &&
            imageElement.naturalHeight === 1
          ) {
            this.handleImageError(fallbackImageSrc);
          }
        };
  
        imageElement.onerror = () => {
          // Handle the case where the image couldn't be preloaded
          this.handleImageError(fallbackImageSrc);
        };
      },
    },
  };