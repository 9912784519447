<template lang="">
	<div
		ref="matDialog"
		class="mdc-dialog"
		:class="{ 'mdc-dialog--fullscreen': fullscreen }"
	>
		<div class="mdc-dialog__container">
			<div
				class="mdc-dialog__surface"
				:class="bodyClass"
				aria-modal="true"
			>
				<!-- Title cannot contain leading whitespace due to mdc-typography-baseline-top() -->
				<slot name="header" />
				<div class="mdc-dialog__content">
					<slot />
				</div>
				<slot name="actions" />
			</div>
		</div>
		<div class="mdc-dialog__scrim" />
	</div>
</template>
<script>
export default {
	props: {
		fullscreen: {
			type: Boolean,
			default: () => false,
		},
		bodyClass: {
			type: [Array, Object],
			default: () => [],
		},
	},
	emits: ['close'],
	mounted() {
		this.dialog = window.mdc.dialog.MDCDialog.attachTo(this.$refs.matDialog);
		this.dialog.scrimClickAction = "";
		this.dialog.listen("MDCDialog:closed", this.dialogClosed);

		setTimeout(() => {
			document.querySelector("body").appendChild(this.$refs.matDialog);
		});
	},
	beforeUnmount() {
		this.dialog.unlisten("MDCDialog:closed", this.dialogClosed);
	},
	methods: {
		open() {
			this.dialog.open();
		},
		close(option) {
			this.dialog.close(option);
		},
		dialogClosed() {
			this.$emit("close");
		},
	},
};
</script>
<style lang="scss" scoped>
.mdc-dialog__surface {
	max-width: 1200px !important;
	min-width: 500px;
}
</style>
