<template lang="">
	<li
		v-bind="$attrs"
		class="mdc-list-item"
		:class="{
			'mdc-list-item--selected': selected, 
			'mdc-list-item--disabled': disabled
		}"
		:aria-selected="selected"
		:data-value="value"
		:aria-disabled="disabled"
	>
		<span class="mdc-list-item__ripple" />
		<span class="mdc-list-item__text"><template v-if="label">{{ label }}</template><slot v-else /></span>
	</li>
</template>
<script>
export default {
	props:{
		label: {
			type: String,
			default: null
		},
		value:{
			type:String,
			default: null
		},
		selected: Boolean,
		disabled: Boolean
	}
}
</script>
<style lang="scss" scoped>
.mdc-list-item{
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: var(--mdc-list-side-padding, 16px);
    padding-right: var(--mdc-list-side-padding, 16px);
    outline: none;
    height: var(--mdc-menu-item-height, 48px);
}
    
</style>