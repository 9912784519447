<template>
	<div>
		<div class="flex-center mb-3">
			<router-link
				:to="{ name: $route.name, params: { config: 'signin' } }"
				v-if="form != 'resetpassword'"
			>
				<TabButton
					class="tabs text-center"
					name="t1"
					:class="{ active: form == 'signin' }"
				>
					{{ $t('connecter') }}
				</TabButton>
			</router-link>
			<router-link
				:to="{ name: $route.name, params: { config: 'signup' } }"
				v-if="form != 'resetpassword'"
			>
				<TabButton
					class="tabs"
					:class="{ active: form == 'signup' }"
					name="t1"
				>
					{{ $t('inscrit') }}
				</TabButton>
			</router-link>
			<router-link
				:to="{ name: $route.name, params: { config: 'resetpassword' } }"
				v-if="form == 'resetpassword'"
			>
				<TabButton
					class="tabs"
					:class="{ active: form == 'resetpassword' }"
					name="t1"
				>
					{{ $t('forgetPw') }}
				</TabButton>
			</router-link>
		</div>
		<div class="mt-4 d-flex justify-content-between d-none">
			<button class="h6 mx-3 social linkedin">
				<i class="fa-brands fa-linkedin" />
				Linkedin
			</button>
			<button class="h6 mx-3 social facebook">
				<i class="fa-brands fa-facebook" />
				Facebook
			</button>
			<button class="h6 mx-3 social google">
				<i class="fa-brands fa-google" />
				Google
			</button>
		</div>
		<div class="divider d-none">
			OU
		</div>

		<form
			v-if="form == 'signup'"
			class="px-3 connect-form d-flex flex-column spaced-2"
			@submit="action($event, 'signup')"
		>
			<p
				v-if="signupError || signuperr"
				class="text-danger text-center"
			>
				{{ $t('signupError') }}
			</p>
			<MatInput
				dense
				:label="$t('addMail')"
				required
				name="email"
				v-model="info.email"
				type="email"
			/>
			<MatInput
				dense
				v-model="info.phone"
				:required="$siteconf.contact_choice == 'both' || $siteconf.contact_choice == 'phone'"
				:label="$t('mail-phone.phone')"
				type="tel"
				name="phone"
			/>
			<span>
				<MatInput
					dense
					:label="$t('password')"
					:type="showPass ? 'text' : 'password'"
					required
					password
					name="password"
					v-model="info.password"
					@toggle-visibility="toggleVisibility"
				/>
			</span>
			<span>
				<MatInput
					dense
					:label="$t('confpassword')"
					:type="showPass ? 'text' : 'password'"
					required
					password
					name="password"
					v-model="confirmPassword"
					@toggle-visibility="toggleVisibility"
				/>
			</span>
			<span>
				<altcha-widget
					ref="altchaSignup"
					challengeurl="/c/challenge"
					hidelogo
					hidefooter
					:strings="captchaStrings"
					style="--altcha-max-width:100%"
				/>
			</span>
			<span
				v-if="info.password && !isValidPassword"
				style="font-size: 10px;"
			>{{ $t('passwordErrorRegex.1') }}
				<ul>
					<li :class="hasUpperCase ? 'text-success' : 'text-danger'">{{ $t('passwordErrorRegex.2') }}</li>
					<li :class="hasLowerCase ? 'text-success' : 'text-danger'">{{ $t('passwordErrorRegex.6') }}</li>
					<li :class="digitRegex ? 'text-success' : 'text-danger'">{{ $t('passwordErrorRegex.3') }}</li>
					<li :class="specialCharRegex ? 'text-success' : 'text-danger'"> {{ $t('passwordErrorRegex.4') }}</li>
					<li :class="info.password.length >= 8 ? 'text-success' : 'text-danger'">{{ $t('passwordErrorRegex.5') }}
					</li>
				</ul>
			</span>
			<span
				v-if="(confirmPassword && info.password) && !arePasswordsEqual"
				class="text-danger"
			> {{
				$t('confpasswordError') }}</span>
			<div class="flex-vertical-center">
				<MatCheckbox
					@change="select"
					v-model="info.rgpd_consent"
				/>
				<div
					v-if="$siteconf.policy_link"
					class=""
				>
					{{ $t('accept') }} <a
						class="body1  under"
						:href="$siteconf.policy_link"
						target="_blank"
					>
						{{ $t('condition') }}
					</a>
				</div>
				<div
					v-else-if="$siteconf.policy"
					class=""
				>
					{{ $t('accept') }} <a
						class="body1 under"
						href="/privacy"
						target="_blank"
					>
						{{ $t('condition') }}
					</a>
				</div>
			</div>
			<div class="text-center">
				<MatButton
					:disabled="!accept || isDisabledsignup"
					raised
					:label="$t('inscrit')"
				/>
			</div>
		</form>

		<form
			v-if="form == 'signin'"
			class="px-3 connect-form d-flex flex-column spaced-2"
			@submit="action($event, 'signin')"
		>
			<p
				v-if="signinerr_429"
				class="text-danger text-center"
			>
				{{ $t('signinerr_429') }}
			</p>
			<p
				v-else-if="signinError || signinerr"
				class="text-danger text-center"
			>
				{{ $t('signinEror') }}
			</p>
			<MatInput
				dense
				:label="$t('addMail')"
				required
				name="email"
				v-model="logininfo.email"
				type="email"
			/>
			<MatInput
				dense
				:label="$t('password')"
				:type="showPass ? 'text' : 'password'"
				required
				password
				name="password"
				v-model="logininfo.password"
				@toggle-visibility="toggleVisibility"
			/>
			<div>
				<altcha-widget
					ref="altchaSignin"
					challengeurl="/c/challenge"
					hidelogo
					hidefooter
					:strings="captchaStrings"
					style="--altcha-max-width:100%"
				/>
			</div>
			<div class="d-flex">
				<div class="flex-max" />
				<router-link
					class="body1"
					:to="{ name: $route.name, params: { config: 'resetpassword' } }"
				>
					{{ $t(`forgetPw`) }}
				</router-link>
			</div>
			<div class="text-end d-none">
				<router-link
					to="/forget-password"
					class="text-primary border-bottom border-primary"
				>
					{{ $t(`forgetMp`) }}
				</router-link>
			</div>
			<div class="text-center">
				<MatButton
					:disabled="isDisabledsignin"
					raised
					:label="$t('connecter')"
				/>
			</div>
		</form>

		<form
			v-if="form == 'resetpassword'"
			class="px-3 connect-form d-flex flex-column spaced-2"
			@submit="resetpassword($event)"
		>
			<p
				v-if="signinerr_429"
				class="text-danger text-center"
			>
				{{ $t(`signinerr_429`) }}
			</p>
			<p
				v-else-if="resetpasswordError"
				class="text-danger text-center"
			>
				{{ $t(`resetpasswordError`) }}
			</p>
			<p
				v-else-if="resetPasswordSucces"
				class="text-success text-center"
			>
				{{ $t(`resetPasswordSucces`) }}
			</p>
			<MatInput
				dense
				:label="$t('addMail')"
				required
				name="email"
				v-model="resetpasswordMail"
				type="email"
			/>
			<div>
				<altcha-widget
					ref="altchaReset"
					challengeurl="/c/challenge"
					hidelogo
					hidefooter
					:strings="captchaStrings"
					style="--altcha-max-width:100%"
				/>
			</div>
			<div class="text-center">
				<MatButton
					:disabled="!verifresetpasswordMail || !captcha"
					raised
					:label="$t('forgetPwText')"
				/>
			</div>
		</form>
	</div>
</template>
<script>
import { ROUTES_NAME } from "@/router"
const verifphone = /^[+\d]\d{5,}$/
export default {
	data() {
		return {
			ROUTES_NAME,
			form: "signup",
			accept: false,
			info: { },
			logininfo: { },
			confirmPassword: "",
			signinerr: false,
			signinerr_429: false,
			signuperr: false,
			resetpasswordMail: "",
			resetpasswordError: false,
			resetPasswordSucces: false,
			showPass: false,
			showConPass: false,
			/* CAPTCHA */
			captcha: null,
			captchaErr: false,
			challenge: null,
			isAttached: false
		}
	},
	props: {
		onSignupClick: {
			type: Function,
			default: null
		},
		onSigninClick: {
			type: Function,
			default: null
		},
		data: {
			type: Object,
			default: () => { }
		},
		datalogin: {
			type: Object,
			default: () => { }
		},
		signupError: {
			type: Boolean,
			default: false
		},
		signinError: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		captchaStrings(){
			return JSON.stringify({
				label: this.$t('captcha_label'),
				error: this.$t('captcha_error'),
				expired: this.$t('captcha_expired'),
				verified: this.$t('captcha_verified'),
				verifying: this.$t('captcha_verifying'),
				waitAlert: this.$t('captcha_waitAlert'),
			});
		},
		verifresetpasswordMail() {
			return this.$utils.verifEmail(this.resetpasswordMail)
		},
		isDisabledsignup() {
			return (
				!this.captcha ||
				!this.info.email ||
				!this.info.password ||
				!this.confirmPassword ||
				this.info.password !== this.confirmPassword ||
				!this.$utils.verifEmail(this.info.email) ||
				!this.$utils.verifPasswordRegex(this.info.password) ||
				(this.info.phone && !verifphone.exec(this.info.phone))||
				((this.$siteconf.contact_choice == 'both' || this.$siteconf.contact_choice == 'phone') &&
					(!this.info.phone || !verifphone.exec(this.info.phone)))
			);
		},
		isDisabledsignin() {
			return (
				this.logininfo.email === '' ||
				this.logininfo.password === '' ||
				!this.$utils.verifEmail(this.logininfo.email)) ||
				!this.captcha;
		},
		arePasswordsEqual() {
			return this.info.password === this.confirmPassword;
		},
		isValidPassword() {
			if (this.form == 'signup') return this.$utils.verifPasswordRegex(this.info.password)
			else if (this.form == 'signin') return this.$utils.verifPasswordRegex(this.logininfo.password)
			else return false
		},
		hasUpperCase() {
			if (this.form == "signin") return this.$utils.hasUpperCase(this.logininfo.password)
			else if (this.form == 'signup') return this.$utils.hasUpperCase(this.info.password)
			else return false
		},
		hasLowerCase() {
			if (this.form == "signin") return this.$utils.hasLowerCase.test(this.logininfo.password)
			else if (this.form == 'signup') return this.$utils.hasLowerCase(this.info.password)
			else return false
		},
		digitRegex() {
			if (this.form == "signin") return this.$utils.digitRegex(this.logininfo.password)
			else if (this.form == 'signup') return this.$utils.digitRegex(this.info.password)
			else return false
		}
		,
		specialCharRegex() {
			if (this.form == "signin") return this.$utils.specialCharRegex(this.logininfo.password)
			else if (this.form == 'signup') return this.$utils.specialCharRegex(this.info.password)
			else return false
		}
	},
	watch: {
		$route() {
			this.form = this.$route.params.config
			this.showPass = false
			this.showConPass = false
			this.captcha = null;
			this.removeCaptchaEventListener();
		},
		data() {
			this.info = this.data
		},
		datalogin: {
			handler() {
				this.logininfo = this.data
			}, immediate: true
		},
		modelValue: {
			handler() {
				this.info = this.modelValue;
			},
		},
	},
	unmounted(){
		this.removeCaptchaEventListener();
	},
	updated(){
		if (!this.isAttached){
			let widget1 = this.$refs.altchaSignin;
			if (widget1)
				widget1.addEventListener('statechange', this.captchaStateChange);
			let widget2 = this.$refs.altchaSignup;
			if (widget2)
				widget2.addEventListener('statechange', this.captchaStateChange);
			let widget3 = this.$refs.altchaReset;
			if (widget3)
				widget3.addEventListener('statechange', this.captchaStateChange);
			this.isAttached = true;
		}
	},
	methods: {
		removeCaptchaEventListener(){
			this.isAttached = false;
			let widget1 = this.$refs.altchaSignin;
			if (widget1?.value)
				widget1.value.removeEventListener('statechange', this.captchaStateChange);
			let widget2 = this.$refs.altchaSignup;
			if (widget2?.value)
				widget2.value.removeEventListener('statechange', this.captchaStateChange);
			let widget3 = this.$refs.altchaReset;
			if (widget3?.value)
				widget3.value.removeEventListener('statechange', this.captchaStateChange);
		},
		captchaStateChange(e){
			if (e.detail.state == 'verified')
				this.captcha = e.detail.payload;
		},
		async action(e, action) {
			e.preventDefault();

			const isSignin = action === "signin";
			const isSignup = action === "signup";
			const onClickFunction = isSignin ? this.onSigninClick : this.onSignupClick;
			const authInfo = isSignin ? {...this.logininfo, captcha: this.captcha} : {...this.info, captcha: this.captcha};
			const email = isSignin ? this.logininfo.email : this.info.email;

			if (this.form !== "signin") {
				this.info.rgpd_consent = true;
			}

			if (typeof onClickFunction === "function") {
				return onClickFunction();
			}

			try {
				if (isSignin) {
					await this.$service.appservice.signin(authInfo);
				} else if (isSignup) {
					await this.$service.appservice.signup(authInfo);
				}
				this.$store.commit('setToken');
				this.$store.commit('setEmail', email);
				this.$userData({ "email": email, "phone": authInfo.phone ? authInfo.phone : "" });

				let user = await this.$service.user.getUser();
				if (user) {
					this.$utils.updateUserStorage(user)
				}

				this.$router.push({ name: ROUTES_NAME.HOME });
			} catch (error) {
				this.signinerr_429 = false;
				if(error.response.status === 429)
					this.signinerr_429 = true;
				else if (isSignin) {
					this.signinerr = true;
				} else if (isSignup) {
					this.signuperr = true;
				}
			}
		},
		select() {
			this.accept = !this.accept
		},
		async resetpassword(e) {
			e.preventDefault()
			try {
				await this.$service.appservice.reset(this.resetpasswordMail, this.captcha)
				this.resetPasswordSucces = true
				this.signinerr_429 = false;
				this.resetpasswordError = false;
				//	this.$router.push({ name: this.$route.name, params: { config: 'signin' } })
			} catch (error) {
				this.signinerr_429 = false;
				this.resetpasswordError = false;
				this.resetPasswordSucces = false;
				if(error && error.response.status === 429){
					this.signinerr_429 = true;
				}else this.resetpasswordError = true;
			}
		},
		toggleVisibility() {
			// e.preventDefault();
			this.showPass = !this.showPass;
		},
		// toggleVisibility(e){
		// 	e.preventDefault();
		// 	this.showPass = !this.showPass;
		// },
		showPassword(e) {
			e.preventDefault()
			this.showPass = !this.showPass

		},
		showConPassword(e) {
			e.preventDefault()
			this.showConPass = !this.showConPass

		}
	},
	mounted() {
		this.form = this.$route.params.config
		this.info = this.data || {
			email: "",
			password: "",
			rgpd_consent: false
		};
		this.logininfo = this.datalogin || {
			email: "",
			password: "",
		}
	}
}
</script>
<style lang="scss" scoped>
.tabs {
	width: 150px;
	padding: 12px;
}

.social {
	display: flex;
	align-items: center;
	flex: 1 1 0;
	justify-content: center;
	color: white;
	border-radius: 10px;


	i {
		padding-right: 5px;
		color: white;
	}

	&.linkedin {
		background: #004E94;
	}

	&.facebook {
		background: #1A6ED8;
	}

	&.google {
		background: #DB4437;
	}
}

a {
	border-bottom: none;
}

.xs {
	width: 20px !important;
	height: 20px !important;
	font-size: 18px !important;
}

.passIcon {
	// right: 175px;
	// margin-top: -4px;

}
</style>