<template lang="">
	<MatDialog
		ref="dialog"
		v-bind="$attrs"
		:body-class="[borderColor, 'border-top', 'border-5']"
	>
		<template #header>
			<span
				class="material-icons-outlined m-3 position-absolute end-0 top-0 pointer round-hover"
				style="z-index: 10000"
				data-mdc-dialog-action="close"
			>
				close
			</span>
			<div class="mdc-dialog__title text-primary-dark">
				{{ config.title }}
			</div>
		</template>
		<template #default>
			<div class="confirm-content">
				<slot>{{ config.text }}</slot>
			</div>
		</template>
		<template #actions>
			<div class="mdc-dialog__actions">
				<slot name="beforeActions" />
				<MatButton
					v-if="
						!config.hideClose && config.type && config.type == DialogType.alert
					"
					mat-style="raised"
					:label="config.closeText || $t('close')"
					class="mdc-dialog__button"
					data-mdc-dialog-action="ok"
				/>
				<MatButton
					v-if="
						(!config.hideCancel && !config.type) ||
							config.type == DialogType.confirm
					"
					:label="config.cancelText || $t('cancel')"
					class="mdc-dialog__button"
					data-mdc-dialog-action="cancel"
				/>
				<MatButton
					v-if="
						(!config.hideConfirm && !config.type) ||
							config.type == DialogType.confirm
					"
					mat-style="raised"
					:label="config.confirmText || $t('confirm')"
					:disabled="disabled"
					class="mdc-dialog__button"
					data-mdc-dialog-action="confirm"
				/>

				<slot name="afterActions" />
			</div>
		</template>
	</MatDialog>
</template>
<script>
import MatDialog from "@/components/material/mat-dialog";
import MatButton from "@/components/material/mat-button";
export const DialogType = Object.freeze({
	alert: "alert",
	confirm: "confirm",
});
export const AlertLevel = Object.freeze({
	info: 0,
	warning: 1,
	error: 2,
});
export default {
	components: { MatDialog, MatButton },
	props: {
		config: {
			type: Object,
			default: () => {},
		},
		disabled: {
			type: Boolean,
			default: () => false,
		},
	},
	emits: ["action"],
	data() {
		return {
			DialogType,
		};
	},
	computed: {
		borderColor() {
			switch (this.config.level) {
			case AlertLevel.warning:
				return "border-warning";
			case AlertLevel.error:
				return "border-danger";
			default:
				return "border-primary";
			}
		},
	},
	mounted() {
		this.$refs.dialog.dialog.listen("MDCDialog:closed", this.closedListener);
	},
	beforeUnmount() {
		this.$refs.dialog.dialog.unlisten("MDCDialog:closed", this.closedListener);
	},
	methods: {
		open() {
			this.$refs.dialog.open();
		},
		close() {
			this.$refs.dialog.close();
		},
		closedListener(evt) {
			if (this.config.text == "Désolé, cette action n'est pas autorisée")
				this.$router.go();
			this.$emit("action", evt.detail.action);
		},
	}
};
</script>
<style lang="scss" scoped>
.confirm-content {
	max-width: 500px;
}
// .mdc-dialog__title {
// 	border-top: 4px solid var(--primary-color);
// }
</style>
