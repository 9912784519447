<template lang="">
	<div
		v-if="title"
		class="headerHeight"
		:class="[{'bg-white ': (type=='nobg'&& title.company)||(type=='nobg'&&title=='Recherche') },
			{'bg-white': type=='nobg'&& !title.company && title!='Recherche'}]"
	>
		<div
			class="smTitle mt-1 top-0"
			:class="[{'center':title=='Recherche'}]"
		>
			<h1
				v-if="title=='Recherche'"
				class="h4"
				:class="[{'mt-3': !title.company}]"
			>
				{{ title.job_title || title }}
			</h1>
			<h1
				v-else
				style="padding:0"
				class="ecli text-break h4 mt-2"
				:class="{'titleblur':scrollpx<10}"
			>
				{{ title.job_title || title }}
			</h1>
			<div
				class="body2"
				:class="{'textShadowWhite':scrollpx<10}"
				v-if="title.company"
			>
				<span class="ecli text-break">
					{{ title.location }}</span>
			</div>
			<div v-if="title=='Recherche'" />
		</div>
		<span
			class="position-absolute  w-100"
			:class="{'bottom-0': (type!='nobg')}"
		>
			<div
				class="blur"
				v-if=" type!='nobg'"
			/>
			<slot /></span>
	</div>
</template>
  <script>
  import { ROUTES_NAME } from "@/router";
  import { Contract } from "@/utils/constants";
  export default {
      name: "HeaderContainerComponent",
      components:{},
      data() {
          return { ROUTES_NAME,scrollpx:0,Contract };
      },
      props:{
        title: {
          type: [String, Object] , default: ()=>("")
        },
        type:{
          type:String , default: ()=>""
        },
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	},
	unmounted() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods:{
		handleScroll() {
			this.scrollpx = window.scrollY;
		},
	}
  }
  </script>
  <style lang="scss" scoped>
.smTitle{
	text-align: center;
	padding-left: 60px;
	padding-right: 50px;
  @media (max-width: 370px) {
	//left: 60px;
	}
  &.center{
	top: 1px !important;
	left: 40% !important;
	@media (max-width: 320px) {
		top: 1px !important;
		left: 30% !important;
	};
	@media (max-width: 400px) {
		top: 1px !important;
		left: 33% !important;
	};
	@media (min-width: 500px) {
		top: 1px !important;
		left: 40% !important;
	};
	@media (min-width: 501px) {
		top: 1px !important;
		left: 43% !important;
	};
	
  }
}
.headerHeight{
  z-index: 1000;
  top: 0;
  max-height: 150px;
min-height: 70px;
}
.blur{
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  filter: blur(4px);
  margin-left: -1rem;
  bottom: -1px;
  border-radius: 20px;
}
.ecli{   
	width: 100%;
    white-space: break-spaces;
}
  </style>