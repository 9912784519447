<template lang="">
	<div
		:id="id"
		class="h-100"
	/>
</template>
<script>
export default {
	name: "MapComponent",
	props: {
		config: {
			type: Object, default: () => ({
				center: [46.649, 2.856],
				zoom: 5
			})},
		data:{type: Array, default: () => []}

	},
	data(){
		return{
			id: this.$utils.randomId(),
			markers: [],
			L: null
		}
	},
	watch:{
		markers(newMarkers, oldMarkers){
			if(!this.map) return;
			oldMarkers.forEach(m => this.map.removeLayer(m))
			newMarkers.forEach((marker, index) => {
				marker.addTo(this.map).on('click', (event) => {
					event.dataIndex = index
					this.$emit("markerClick", event)
				})
			})
		}
	},
	emits:['markerClick'],
	async mounted() {
    
		this.L = (await import("leaflet")).default
		this.$watch(
			(vm) => [vm.config, vm.data],
			this.update,
			{immediate: true}
        
		)
	},
	methods:{
		update(){
      
			if(this.$utils.isEmpty(this.config) || !this.config.center || !this.L) return;
			// this.deleteMarkers();
			if(this.map){
				this.map.setView(this.config.center, this.config.zoom)
			}else{
				this.map = this.L.map(this.id,this.config)
				this.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
			
			}
			this.markers = this.data.map(d => this.L.marker(
				d.position, 
				{icon: this.L.divIcon(this.$utils.mapUtils.createMarkerIcon(d.label, this.$utils.getRootColor()))}
			))
		},
    
	},
}
</script>
<style lang="">
  
</style>