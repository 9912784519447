<template lang="">
	<div
		
		class="graph-container"
		v-bind="$attrs"
	>
		<h3
			class="title h4"
			v-if="show && !$siteconf?.cooptation && !siteconf?.hideImages?.application"
		>
			{{ title }}
		</h3>
		<slot />
	</div>
</template>
<script>
export default {
	props:{
		title:{
			type: String,
			default: ""
		},
		show:{
			type: Boolean,
			default: false
		},
	}
}
</script>
<style lang="scss" scoped>
  .title{
    position: absolute;
    top: 50px;
    left: 100px;
  }
  .graph-container{
    position: relative;
    height: 100%;
    width: calc(100% - 36%);
  }
</style>