<template lang="">
	<div>
		<slot />
	</div>
</template>
<script>
export default {
	props:{
		modelValue:{
			type: Array,
			default: null
		},
		name:{
			type: String,
			default: null
		}
	},
	watch:{
		modelValue(){
			this.selectCheckboxes(this.modelValue);
		}
	},
	emits: ["update:modelValue"],
	methods:{
		updateModel(){
			this.$emit("update:modelValue", Array.from(this.checkboxes).filter(c => c.checked).map(c => c.value))
		},
		selectCheckboxes(value){
			if(!value) return;
			let checkboxes = Array.from(this.checkboxes).filter(r => value.indexOf(r.value) >= 0)
			checkboxes.forEach((checkbox)=>{
				if(!checkbox.checked) checkbox.checked = true
			})
				
		}
	},
	mounted(){
		this.checkboxes = this.$el.querySelectorAll("input[type='checkbox']")
        
		this.checkboxes.forEach((checkbox) => {
			checkbox.addEventListener("change", this.updateModel)
			if(this.name) checkbox.name = this.name
		})
		this.selectCheckboxes(this.modelValue)
	},
	unmounted(){
		this.checkboxes.forEach((checkbox) => {
			checkbox.removeEventListener("change", this.updateModel)
		})
	}
}
</script>
<style lang="">
    
</style>