<template>
	<div class="d-flex flex-column">
		<div
			class="entreprise-header overflow-hidden d-none d-lg-block"
			:class="[$siteconf?.banner ? 'banner' : 'bg-primary' ]"
		>
			<div
				class="blur-background"
				v-if="$siteconf?.banner"
			/>
			<div
				class="position-relative header-content container-xxl"
			>
				<div class="d-block d-lg-none position-absolute d-flex flex-column smTitle">
					<span class="text-white mb-3 titleblur">
						{{ $t('privacy') }}
					</span>
				</div>
				<div class="d-none d-lg-block">
					<h3 class="text-white text-center mb-3 titleblur">
						{{ $t('privacy') }}
					</h3>
				</div>
			</div>
		</div>

		<span class="d-block d-lg-none ">
			<div
				v-if="scrollpx<10"
				class="entreprise-header overflow-hidden"
				:class="[$siteconf?.banner ? 'banner' : 'bg-primary' ]"
			>
				<div
					class="blur-background"
					v-if="$siteconf?.banner"
				/>
				<div
					class="position-relative header-content container-xxl"
				>
					<div class="d-block d-lg-none position-absolute d-flex flex-column smTitle">
						<h6 class="text-white mb-3 titleblur">
							{{ $t('privacy') }}
						</h6>
					</div>
				</div>
			</div>
			<headerContainer
				title="Privacy"
				type=""
				v-if="scrollpx>10"
				class=" position-fixed w-100 "
			/>
		</span>
		<div class="d-flex flex-column justify-content-between ">
			<div class="p-2 p-lg-5 bg-white">
				<div
					class="d-flex flex-row container-xxl text-center text-lg-start"
					:class="{'justify-content-center':!$siteconf.policy || $siteconf.policy.length<100 }"
				>
					<div
						class="ps-0 ps-lg-3"
					>
						<div
							class="d-flex sect2 justify-content-center"
						>
							<span 
								class="me-3"
								:class="{'d-flex align-items-center':!$siteconf.policy }"
							>
								<h6
									class=" mt-3 col1"
									v-if="$siteconf.policy"
								>
									{{ $siteconf.policy }}
								</h6>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import headerContainer from "@/components/application/header-caintainer.vue"

export default {
	name: "PrivacyComponent",
	components:{ 
		headerContainer,
		
	},
	data() {
		return { scrollpx: 0 }
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
	},
	unmounted() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods:{
		handleScroll() {
			this.scrollpx = window.scrollY;
		},
	}
}
</script>
<style lang="scss" scoped>
.entreprise-header {
	position: relative;
	background-size: cover;
}
</style>
