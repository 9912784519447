<template lang="">
	<div
		ref="chipSet"
		class="mdc-chip-set mdc-chip-set--input"
		v-bind="$attrs"
	>
		<div
			v-for="(e, index) in list"
			:key="e"
			class="mdc-chip"
			v-bind="e.attributes"
		>
			<div class="mdc-chip__ripple" />
			<div class="mdc-chip__text flex-vertical-center">
				<span
					v-if="e.icon"
					class="material-icons-outlined me-2"
					style="font-size: 18px"
				>
					{{ e.icon }}
				</span> <span v-html="label(e)" />
			</div>
			<i
				class="material-icons mdc-chip__icon mdc-chip__icon--trailing"
				tabindex="1"
				role="button"
				@click="remove(index)"
			>cancel</i>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		modelValue: { type: Array, default: () => [] },
	},
	emits: ["update:modelValue", "remove"],
	data() {
		return {
			list: [],
		};
	},
	computed: {},
	watch: {
		list: {
			handler() {
				const chipSetElement = this.$refs.chipSet;
				if (chipSetElement) {
					setTimeout(() => {
						new this.MDCChipSet(chipSetElement);
					});
				} else {
					console.error('Chip set element not found');
				}
			},
			deep: true,
		},
		modelValue: {
			handler(newVal) {
				this.list = newVal ? newVal : [];
			},
			deep: true,
		},
	},
	mounted() {
		this.MDCChipSet = window.mdc.chips.MDCChipSet;
		this.chipSet = new this.MDCChipSet(this.$refs.chipSet);
		this.list = this.modelValue ? [...this.modelValue] : [];
	},
	methods: {
		remove(index) {
			this.list.splice(index, 1);
			this.$emit("update:modelValue", [...this.list]);
			this.$emit("remove", [...this.list]);
		},
		label(e) {
			return e.label || (typeof e === "string" && e) || "";
		}
	},
};
</script>
<style lang="scss" scoped>
.mdc-chip {
	background: var(--primary-color);

	* {
		color: white;
	}
}
</style>
