<template lang="">
	<li
		ref="item"
		class="mdc-list-item"
		aria-selected="false"
		:data-value="value"
		role="option"
		@click="onClick"
	>
		<span class="mdc-list-item__ripple" />
		<MatCheckbox
			v-if="multiple"
			ref="optionCheckbox"
			v-model="check"
			stop-propagation
		/>
		<span class="mdc-list-item__text">
			<slot />
		</span>
	</li>
</template>
<script>
import MatCheckbox from "@/components/material/mat-checkbox";
export default {
	components: { MatCheckbox },
	props: {
		value: {
			type: [String, Number],
			default: () => "",
		},
	},
	data() {
		return {
			multiple: false,
			check: false,
		};
	},
	computed: {
		selectValues() {
			return this.$parent.$parent.multipleValue;
		},
	},
	watch: {
		check() {
			this.updateParentValue();
		},
		selectValues: {
			handler(newVal) {
				if (!this.$refs.optionCheckbox) return;
				if (this.multiple && newVal && newVal.indexOf(this.value) >= 0) {
					this.$refs.optionCheckbox.setChecked(true);
				} else {
					this.$refs.optionCheckbox.setChecked(false);
				}
			},
		},
	},
	beforeMount() {
		this.multiple = this.$parent.$parent.$props.multiple;
	},
	methods: {
		onClick(e) {
			if (this.multiple) {
				e.stopPropagation();
				this.check = !this.check;
				this.updateParentValue();
			}
		},
		updateParentValue() {
			if (this.check) this.$parent.$parent.add(this.value);
			else {
				this.$parent.$parent.remove(this.value);
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.mdc-list-item {
	min-height: 48px;
	align-items: center;
}
</style>
