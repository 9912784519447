import * as moment from 'moment'

export default {
	format(date, format){
		if(date){
			return moment(date).format(format ? format : 'DD/MM/YYYY')
		}
	},
	newDate(date, options){
		if(!options) options = {}
		if(date){
			return moment().subtract(options.amount || 3, options.unit || "days").isBefore(date)
		}
		return false
	}
}