<template>
	<section class="mt-3">
		<div
			class="py-3 "
			style="border-radius: 20px; border: 1px solid var(--action-disabled, rgba(0, 0, 0, 0.26));"
		>
			<div
				v-if="view == 'personalInfo' || view == 'checkAccount'"
				class="d-flex flex-column mx-4"
			>
				<span
					class="d-flex justify-content-center "
					v-if="view == 'checkAccount'"
				>
					<!--<span class="mb-3 sectionTitle">Informations personelles</span>   -->
					<span
						class="text-success my-4"
						v-if="userData?.verified?.done"
					> {{ $t(`vCompte`) }}</span>
					<span class=" sectionTitle ">
						<MatButton
							v-if="userData?.verified?.retry || !alreadysend"
							class="conditionLink my-4"
							:label="$t(`vrCompte`)"
							outlined
							@click="verifyAccount"
						/>
						<MatButton
							v-else
							class="conditionLink my-4"
							:label="$t(`avCompte`)"
							outlined
							disabled
						/>
					</span>
				</span>

				<PersonalInfo
					v-if="view == 'personalInfo'"
					@update-user="updateuser($event)"
					:checkAccount="checkAccount"
				/>
			</div>
			<div
				v-if="view == 'cvs'"
				class="d-flex flex-column mx-4"
			>
				<span class="mb-3">Cv</span>

				<span class="d-flex flex-column">

					<span> Le cv principal est le cv principalement proposé lors du procedure de candidature et c’est le
						CV
						qui peuvent consulter les recruteurs si vous rendu votre profil visible dans la CVthèque</span>
					<div
						class="mt-3 me-3"
						style="border-radius: 20px;border: 1px solid var(--action-disabled, rgba(0, 0, 0, 0.26));"
					>

						<div class="pt-3 ps-3 d-flex flex-vertical-center">
							<MatCheckbox modelValue="true" />CV manuel remo
						</div>
						<div class="pb-3 ps-3 d-flex">Le cv sera prérempli à partir des informtions saisies dans votre
							profil</div>

					</div>
				</span>
			</div>
			<div
				v-if="view == 'experiences'"
				class="d-flex flex-column mx-4"
			>
				<span class="mb-3 sectionTitle">Expériences</span>
				<Experience
					:data="experience"
					@update-user="updateuser($event)"
				/>
			</div>
			<div
				v-if="view == 'formation'"
				class="d-flex flex-column mx-4"
			>
				<span class="mb-3 sectionTitle">Formation</span>
				<Education
					:data="education"
					@update-user="updateuser($event)"
				/>
			</div>
			<div
				v-if="view == 'skills'"
				class="d-flex flex-column mx-4"
			>
				<span class="mb-3 sectionTitle">Compétences</span>
				<Skills
					:data="skills"
					@update-user="updateuser($event)"
				/>
			</div>
			<div
				v-if="view == 'questionnaire' && form && form.fields && form.fields.length > 0"
				class="d-flex flex-column mx-4"
			>
				<span class="mb-3 sectionTitle">Questionnaire</span>
				<Dynamic
					:data="form"
					:formAns="form"
					@update-user="updateuser($event)"
				/>
			</div>
		</div>
	</section>
</template>
<script>
import { ROUTES_NAME } from "@/router"
import Experience, {
	defaultExperience
} from "@/components/application/forms/experience-form.vue";
import Education, {
	defaultEducation
} from "@/components/application/forms/education-form.vue";
import Skills from "@/components/application/forms/skills-form.vue";
import PersonalInfo from "@/components/application/forms/personal-info-form.vue";
import Dynamic from "@/components/application/forms/dynamic-form.vue";
export default {
	components: {
		Experience, Education, Skills, PersonalInfo, Dynamic
	},
	props: {
		userData: {
			type: Object,
			default: () => { }
		},
		questionnaire: {
			type: Array,
			default: () => []
		},
	},
	data() {
		return {
			ROUTES_NAME,
			view: "personalInfo",
			experience: [],
			education: [],
			date: null,
			alreadysend: false,
			skills: {
				selectedTechs: [],
				selectedLangs: [],
				selectedSkills: [],
			},
			personnalInfo: null,
			checkAccount: null,
			show: true,
			form: [],
			updatedData: {}
		}
	},
	watch: {
		$route: {
			handler() {
				this.view = this.$route.params.config
			}, immediate: true

		},
		userData: {
			handler(newVal, oldVal) {
				if (!this.$utils.equals(newVal, oldVal)) {
					//this.personnalInfo = this.userData?.personal ? this.userData?.personal : null
					this.checkAccount = this.userData?.verified ? this.userData?.verified : null
				}
			}, immediate: true
		},
		questionnaire: {
			handler() {
				if (this.questionnaire) this.form = this.questionnaire[0]
			}, immediate: true
		}
	},
	updated() {
		// console.log(this.userData)
		this.view = this.$route.params.config ? this.$route.params.config : "personalInfo";


		if (this.userData?.personal.birthday)
			this.date = new Date(this.userData.personal.birthday).toLocaleDateString("fr")


		this.experience = [JSON.parse(JSON.stringify(defaultExperience))]
		this.education = [JSON.parse(JSON.stringify(defaultEducation))]
		this.Skills = {
			selectedTechs: [],
			selectedLangs: [],
			selectedSkills: [],
		};
	},
	methods: {
		async verifyAccount() {
			try {
				await this.$service.appservice.retryAccount()
				this.alreadysend = true
			} catch (error) {
				console.log("error while sending the mail");
				//this.alreadysend=true
			}
		},
		async updateuser(data) {
			const key = Object.keys(data);
			try {
				if (key && key[0] == "answers" && key[1] == "id") await this.$service.user.postForm(data)
				else {
					await this.$service.user.updateUser(data)
				}

			} catch (error) {
				console.log("error when updating user");
			}
		}
	}
}
</script>
<style scoped lang="scss">
.tabTitle {
	font-weight: 500;
	line-height: 24px;
	letter-spacing: 0.4000000059604645px;
}

.active {
	color: var(--primary-color)
}

.sectionTitle {
	color: #000;
	font-family: Roboto;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 133.4%;
}
</style>