<template lang="">
	<div>
		<slot />
	</div>
</template>
<script>
export default {
	props:{
		modelValue:{
			type: String,
			default: null
		},
		name:{
			type: String,
			default: null
		}
	},
	watch:{
		modelValue(){
			this.selectRadio(this.modelValue);
		}
	},
	emits: ["update:modelValue"],
	methods:{
		updateModel(evt){
			this.$emit("update:modelValue", evt.target.value)
		},
		selectRadio(value){
			let radio = Array.from(this.radios).find(r => r.value == value)
			if(radio && !radio.checked)
				radio.checked = true
		}
	},
	mounted(){
		this.radios = this.$el.querySelectorAll("input[type='radio']")
        
		this.radios.forEach((radio) => {
			radio.addEventListener("change", this.updateModel)
			if(this.name) radio.name = this.name
		})
		this.selectRadio(this.modelValue)
	},
	unmounted(){
		this.radios.forEach((radio) => {
			radio.removeEventListener("change", this.updateModel)
		})
	}
}
</script>
<style lang="">
    
</style>