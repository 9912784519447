export const APP_EVENT = Object.freeze({
	SHOW_ALERT: "showAlert",
});

import emitter from "tiny-emitter/instance";

export default {
	$on: (...args) => emitter.on(...args),
	$once: (...args) => emitter.once(...args),
	$off: (...args) => emitter.off(...args),
	$emit: (...args) => emitter.emit(...args),
};
