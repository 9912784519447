<template lang="">
	<button
		ref="button"
		class="mdc-button"
		v-bind="$attrs"
		:class="{
			'mdc-button--outlined outlentedColor': outlined,
			'mdc-button--icon-leading': icon,
			'mdc-button--raised': raised,
			'ctaColor': cta
		}"
	>
		<span class="mdc-button__ripple" />
		<span class="mdc-button__focus-ring" />
		<i
			v-if="icon && iconPosition=='start'"
			class="material-icons mdc-button__icon"
			aria-hidden="true"
		>{{ icon }}</i>
		<span
			class="mdc-button__label"
			:class="{'outlentedColor':outlined}"
		><template v-if="label">{{ label }}</template><slot v-else /></span>
		<i
			v-if="icon && iconPosition=='end'"
			class="material-icons mdc-button__icon"
			aria-hidden="true"
		>{{ icon }}</i>
	</button>
</template>
<script>

export default {
	props:{
		outlined: Boolean,
		cta: Boolean,
		icon: {
			type: String,
			default: null
		},
		raised: Boolean,
		label: {
			type: String, 
			default: null
		},
		iconPosition:{
			type: String,
			default: "start"
		},
	},
	mounted() {
		new window.mdc.ripple.MDCRipple(this.$refs.button)
	}
}
</script>
<style lang="scss" scoped>
	.outlentedColor {
		color:var(--primary-25);
		border-color:var(--primary-25);
	}
	.ctaColor {
		background-color:var(--accent-color);
	}
</style>