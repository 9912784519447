<template>
	<!-- User card  -->
	<div
		style="border-radius: 20px;border: 1px solid var(--action-disabled, rgba(0, 0, 0, 0.26));"
		class="mt-3 d-flex w-100 flex-wrap"
	>
		<div class="w-30 d-flex m-auto justify-content-center flex-vertical-center flex-wrap">
			<div class="mx-2 logo avatar bg-white">
				<img
					class="h-90 w-90"
					src="http://localhost:8080/c/icon.png"
				>
			</div>
			<div
				class="flex-row text-break ms-4"
			>
				<p
					class="h6"
				>
					Imen Turki
				</p>
				<p
					class="my-1"
				>
					UX/UI Designer
				</p>
				<p
					class="ecli h6 m-0"
				>
					Data4job
				</p>
			</div>
		</div>

		<div class="w-60 w-100 my-3">
			<div class="d-flex flex-row w-100">
				<span class=" w-40">Recherche d'emploi : </span>
				<span class="w-60">à l'ecoute du marché </span>
			</div>
			<div class="d-flex flex-row w-100">
				<span class=" w-40">Postes recherchées : </span>
				<span class="w-60">UX/UI Designer </span>
			</div>
			<div class="d-flex flex-row w-100">
				<span class=" w-40">Lieux souhaités : </span>
				<span class="w-60">Paris </span>
			</div>
			<div class="d-flex flex-row w-100">
				<span class=" w-40">Type contract recherchées :</span>
				<span class="w-60">CDI-CDD </span>
			</div>
			<div class="d-flex flex-row w-100 mt-3">
				<MatFab
					outlined
					mini
					class="fa-border iconborder me-2 mb-2 fabxs"
					@click="share('fb')"
				>
					<i
						class="fa-brands fa-facebook-f text-primary xs "
						slot="icon"
					/>
				</MatFab>
				<MatFab
					outlined
					mini
					class="fa-border iconborder me-2 mb-2 fabxs"
					@click="share('instagram')"
				>
					<i
						class="fa-brands fa-instagram text-primary xs"
						slot="icon"
					/>
				</MatFab>
				<MatFab
					outlined
					mini
					class="fa-border iconborder me-2 mb-2 fabxs"
					@click="share('yt')"
				>
					<i
						class="fa-brands fa-youtube text-primary xs"
						slot="icon"
					/>
				</MatFab>
				<MatFab
					outlined
					mini
					class="fa-border iconborder me-2 fabxs"
					@click="share('linkedin')"
				>
					<i
						class="fa-brands fa-linkedin-in text-primary xs"
						slot="icon"
					/>
				</MatFab>
				<MatFab
					outlined
					mini
					class="fa-border iconborder me-2 fabxs"
					@click="share('twitter')"
				>
					<i
						class="fa-brands fa-x-twitter text-primary xs"
						slot="icon"
					/>
				</MatFab>
			</div>
		</div>
	</div>
</template>
<script>
import { ROUTES_NAME } from "@/router"
import '@/components/circle-progress'

export default {
	components: {
	},
	data() {
		return {
			ROUTES_NAME,

		}
	},
	watch: {
		$route() {
			//this.view = this.$route.params.config
		},
	},
	mounted() {
		
	},
}
</script>
<style scoped lang="scss">

.xs {
	width: 20px !important;
	height: 20px !important;
	font-size: 18px !important;
}

.fabxs {
	width: 28px;
	height: 28px;
}

.logo {
	width: 100px;
	height: 100px;
	min-width: 80px;
}
</style>